import fetchWrapper from './fetchWrapper'

const API_URI = process.env.REACT_APP_API_URI

const fetcher = async (url, token, method = 'GET', params = {}) => {
  const resource =
    method === 'GET' ? `${url}?${new URLSearchParams(params)}` : url
  const init = ['POST', 'PUT', 'DELETE'].includes(method)
    ? {
        body: JSON.stringify(params),
        headers: {},
      }
    : { headers: {} }
  init.method = method
  init.headers['Content-Type'] = 'application/json'
  init.headers['x-access-token'] = token
  try {
    const res = await fetch(API_URI + resource, init)
    const data = await res.json()
    return data
  } catch (err) {
    return null
  }
}

class BackendApis {
  async getZeroWonStoreItemList(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/zero-won-store/get-zero-won-store-item-list',
      token,
      method,
      params,
    )
    return result
  }

  async getZeroWonStoreCurrentSuccessReference(
    token,
    method = 'GET',
    params = {},
  ) {
    const result = fetcher(
      '/viralEngines/zero-won-store/get-zero-won-store-current-success-reference',
      token,
      method,
      params,
    )
    return result
  }

  async getAddressInfo(token, method = 'GET', params = {}) {
    const result = fetcher('/users/addresses/default', token, method, params)
    return result
  }

  async getAlFarmUserData(token, method = 'GET', params = {}) {
    const result = fetcher('/games/get-alfarm-userdata', token, method, params)
    return result
  }

  async getEventPageMappingUrl(token, method = 'GET', params = {}) {
    const result = fetcher('/event-page-mapping', token, method, params)
    return result
  }

  async createZeroWonStoreTeamInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/zero-won-store/create-zero-won-store-team',
      token,
      method,
      params,
    )
    return result
  }

  async getZeroWonStoreFriendTeamInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      `/viralEngines/zero-won-store/get-zero-won-store-friend-team-info`,
      token,
      method,
      params,
    )
    return result
  }

  async updateZeroWonStoreTeamInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/zero-won-store/update-zero-won-store-team-info',
      token,
      method,
      params,
    )
    return result
  }

  async getZeroWonStoreTeamInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/zero-won-store/get-zero-won-store-team-info',
      token,
      method,
      params,
    )
    return result
  }

  async checkTodayCheckInHistory(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/zero-won-store/check-today-checkin-history',
      token,
      method,
      params,
    )
    return result
  }

  async setTodayCheckInReward(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/zero-won-store/set-today-checkin-reward',
      token,
      method,
      params,
    )
    return result
  }

  async checkTodayAlfarmHistory(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/zero-won-store/check-today-alfarm-history',
      token,
      method,
      params,
    )
    return result
  }

  async setTodayAlfarmReward(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/zero-won-store/set-today-alfarm-reward',
      token,
      method,
      params,
    )
    return result
  }

  async checkTodayAlfarmLiveReviewHistory(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/zero-won-store/check-today-alfarm-live-review-history',
      token,
      method,
      params,
    )
    return result
  }

  async setTodayAlfarmLiveReviewReward(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/zero-won-store/set-today-alfarm-live-review-reward',
      token,
      method,
      params,
    )
    return result
  }

  async checkTodayAlfarmGganbuHistory(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/zero-won-store/check-today-alfarm-gganbu-history',
      token,
      method,
      params,
    )
    return result
  }

  async setTodayAlfarmGganbuReward(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/zero-won-store/set-today-alfarm-gganbu-reward',
      token,
      method,
      params,
    )
    return result
  }

  async checkTodayPurchaseHistory(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/zero-won-store/check-today-purchase-history',
      token,
      method,
      params,
    )
    return result
  }

  async setTodayPurchaseReward(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/zero-won-store/set-today-purchase-reward',
      token,
      method,
      params,
    )
    return result
  }

  async getUserBasicInfoWithAuthentication(token, method = 'GET', params = {}) {
    const result = fetcher('/users/get-users-basic-info', token, method, params)
    return result
  }

  async getLureDealItems(token, method = 'GET', params = {}) {
    const result = fetcher('/items/luredeal-items', token, method, params)
    return result
  }

  async getCurrentToggles(token, method = 'GET', params = {}) {
    const result = fetcher('/users/toggles', token, method, params)
    return result
  }

  async getZeroWonStoreUserInvitedInfos(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/zero-won-store/get-user-invited-infos',
      token,
      method,
      params,
    )
    return result
  }

  async checkIsNewUser(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/zero-won-store/check-is-new-user',
      token,
      method,
      params,
    )
    return result
  }

  async getLuckyBoxTeamInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/lucky-box/get-lucky-box-team-info',
      token,
      method,
      params,
    )
    return result
  }

  async getReceivedLuckyBoxInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/lucky-box/get-received-lucky-box-info',
      token,
      method,
      params,
    )
    return result
  }

  async getReceivedLuckyBoxInfoByUserId(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/lucky-box/get-received-lucky-box-info-by-user-id',
      token,
      method,
      params,
    )
    return result
  }

  async openReceivedLuckyBox(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/lucky-box/open-received-lucky-box',
      token,
      method,
      params,
    )
    return result
  }

  async updateSelfLuckyBox(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/lucky-box/update-self-lucky-box',
      token,
      method,
      params,
    )
    return result
  }

  async openSelfLuckyBox(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/lucky-box/open-self-lucky-box',
      token,
      method,
      params,
    )
    return result
  }

  async getFriendsList(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/lucky-box/contacts',
      token,
      method,
      params,
    )
    return result
  }

  async getLuckyBoxItemTotalListInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/lucky-box/get-lucky-box-item-total-list',
      token,
      method,
      params,
    )
    return result
  }

  async createLuckyBoxTeamInfo(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/viralEngines/lucky-box/create-lucky-box-team',
      token,
      method,
      params,
    )
    return result
  }

  async updateLuckyBoxSendInfo(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/viralEngines/lucky-box/send-info',
      token,
      method,
      params,
    )
    return result
  }

  async getSupportFundTeamInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/support-fund/get-support-fund-team-info',
      token,
      method,
      params,
    )
    return result
  }

  async getSupportFundInvitorTeamInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/support-fund/get-support-fund-invitor-team-info',
      token,
      method,
      params,
    )
    return result
  }

  async getSupportFundUserInvitedInfos(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/support-fund/get-user-invited-infos',
      token,
      method,
      params,
    )
    return result
  }

  async createSupportFundTeam(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/viralEngines/support-fund/create-support-fund-team',
      token,
      method,
      params,
    )
    return result
  }

  async updateSupportFundTeamInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/support-fund/update-support-fund-team-info',
      token,
      method,
      params,
    )
    return result
  }

  async stopSupportFundTeam(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/viralEngines/support-fund/stop-support-fund-team',
      token,
      method,
      params,
    )
    return result
  }

  async setHourAlfarmMission(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/viralEngines/support-fund/set-hour-alfarm-mission',
      token,
      method,
      params,
    )
    return result
  }

  async setThrowMission(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/viralEngines/support-fund/set-throw-mission',
      token,
      method,
      params,
    )
    return result
  }

  async getBankList(token, method = 'GET', params = {}) {
    const result = fetcher('/sellers/banks/total', token, method, params)
    return result
  }

  async requestSupportFundWithdraw(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/viralEngines/support-fund/request-support-fund-withdraw',
      token,
      method,
      params,
    )
    return result
  }

  async getCoffeeGiftReceiverInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/coffee-gift/get-coffee-gift-receiver-info',
      token,
      method,
      params,
    )
    return result
  }

  async setCoffeeGiftAlfarmMission(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/coffee-gift/set-coffee-gift-alfarm-mission',
      token,
      method,
      params,
    )
    return result
  }

  async updateCoffeeGiftSenderFertSet(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/coffee-gift/update-coffee-gift-sender-fert-set',
      token,
      method,
      params,
    )
    return result
  }

  async receiveCoffeeGiftLink(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/coffee-gift/set-coffee-gift-last-receive-link-mark',
      token,
      method,
      params,
    )
    return result
  }

  // 올팜 초코선물 시작

  async getChocoGiftReceiverInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/choco-gift/get-choco-gift-receiver-info',
      token,
      method,
      params,
    )
    return result
  }

  async setChocoGiftAlfarmMission(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/choco-gift/set-choco-gift-alfarm-mission',
      token,
      method,
      params,
    )
    return result
  }

  async updateChocoGiftSenderFertSet(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/choco-gift/update-choco-gift-sender-fert-set',
      token,
      method,
      params,
    )
    return result
  }

  async receiveChocoGiftLink(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/choco-gift/set-choco-gift-last-receive-link-mark',
      token,
      method,
      params,
    )
    return result
  }

  // 올팜 초코선물 끝

  // 원쁠원 시작

  async getOnePlusOneItemList(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/one-plus-one/get-one-plus-one-item-list',
      token,
      method,
      params,
    )
    return result
  }

  async getOnePlusOneTeamInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/one-plus-one/get-one-plus-one-team-info',
      token,
      method,
      params,
    )
    return result
  }

  async initOnePlusOneTeam(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/viralEngines/one-plus-one/init-one-plus-one-team',
      token,
      method,
      params,
    )
    return result
  }

  async setOnePlusOneCheckInMission(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/viralEngines/one-plus-one/set-one-plus-one-check-in-mission',
      token,
      method,
      params,
    )
    return result
  }

  async updateViralEngineInfo(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/viralEngines/one-plus-one/update-one-plus-one-sender-received-mark',
      token,
      method,
      params,
    )
    return result
  }

  // 원쁠원 끝

  // 세뱃돈 시작

  async getNewYearsMoneyTeamInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/new-years-money/get-new-years-money-team-info',
      token,
      method,
      params,
    )
    return result
  }

  async getInvitorInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/new-years-money/get-new-years-money-invitor-info',
      token,
      method,
      params,
    )
    return result
  }

  async initNewYearsMoneyTeam(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/viralEngines/new-years-money/init-new-years-money-team',
      token,
      method,
      params,
    )
    return result
  }

  async requestNewYearsMoneyWithdraw(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/viralEngines/new-years-money/request-new-years-money-withdraw',
      token,
      method,
      params,
    )
    return result
  }

  // 세뱃돈 끝

  // 깐부치킨 시작

  async getGganbuChickenItemList(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/gganbu-chicken/get-gganbu-chicken-item-list',
      token,
      method,
      params,
    )
    return result
  }

  async getGganbuChickenTeamInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/viralEngines/gganbu-chicken/get-gganbu-chicken-team-info',
      token,
      method,
      params,
    )
    return result
  }

  async initGganbuChickenTeam(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/viralEngines/gganbu-chicken/init-gganbu-chicken-team',
      token,
      method,
      params,
    )
    return result
  }

  // async setGganbuChickenGganbuMission(token, method = 'PUT', params = {}) {
  //   const result = fetcher(
  //     '/viralEngines/gganbu-chicken/set-gganbu-chicken-gganbu-mission',
  //     token,
  //     method,
  //     params,
  //   )
  //   return result
  // }

  async updateGganbuViralEngineInfo(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/viralEngines/gganbu-chicken/update-gganbu-chicken-sender-received-mark',
      token,
      method,
      params,
    )
    return result
  }

  // 깐부치킨 끝
}

export default new BackendApis()
