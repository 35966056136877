import React, { useState } from 'react'
import backendApis from '../utils/backendApis'

const ChocoGiftCloseNoticeModal = ({
  setShowChocoGiftCloseNoticeModal,
  alfarmData,
  token,
}) => (
  <>
    <div
      style={{
        flex: 1,
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 3,
        backgroundColor: 'rgba(0,0,0,0.8)',
        width: '100%',
        height: '100%',
        fontFamily: 'Maplestory',
      }}
    >
      <button
        type='button'
        style={{
          width: 100,
          height: 100,
          // backgroundColor: 'red',
          background: 'none',
          border: 'none',
          top: '18%',
          right: '1%',
          position: 'absolute',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          window.location.href = `#navigate.${JSON.stringify({
            screen: 'MainStackAMainTabNavigator',
          })}`
        }}
      >
        <img
          style={{
            zIndex: 101,
            width: '36%',
            height: '36%',
            position: 'absolute',
          }}
          src='/images/closeIcon.png'
          alt=''
        />
      </button>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          height: '100%',
          padding: 24,
          boxSizing: 'border-box',
        }}
      >
        <div
          style={{
            backgroundColor: '#FFF8F1',
            zIndex: 4,
            textAlign: 'center',
            paddingTop: 20,
            borderRadius: 20,
            width: '90vw',
            height: '36vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              fontWeight: 900,
              fontSize: 18,
              color: '#793B10',
              lineHeight: 1.4,
              marginTop: 8,
              marginBottom: 12,
            }}
          >
            이벤트가 종료되었어요
          </div>
          <div
            style={{
              fontWeight: 500,
              color: '#793B10',
              lineHeight: 1.4,
              fontSize: 16,
            }}
          >
            무료 초콜릿 선물 이벤트 기간이
          </div>
          <div
            style={{
              fontWeight: 500,
              color: '#793B10',
              lineHeight: 1.4,
              marginBottom: 12,
            }}
          >
            이미 종료되었어요.
          </div>
          <div
            style={{
              fontWeight: 500,
              color: '#793B10',
              lineHeight: 1.4,
            }}
          >
            그동안 보내주신 관심에 감사드리며
          </div>
          <div
            style={{
              fontWeight: 500,
              color: '#793B10',
              lineHeight: 1.4,
              marginBottom: 12,
            }}
          >
            더 재밌는 이벤트로 다시 찾아뵐게요.
          </div>
          <div
            style={{
              fontWeight: 500,
              color: '#98896C',
              lineHeight: 1.4,
            }}
          >
            더 자세한 안내가 필요하신 경우
          </div>
          <div
            style={{
              fontWeight: 500,
              color: '#98896C',
              lineHeight: 1.4,
            }}
          >
            고객센터를 이용해주세요.
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <button
              type='button'
              style={{
                bottom: '0',
                right: '0',
                fontSize: 18,
                borderBottomLeftRadius: 20,
                borderBottomRightRadius: 20,
                height: 44,
                borderWidth: 0,
                background: '#793B10',
                width: '100%',
                paddingTop: 20,
                paddingBottom: 40,
                marginTop: 28,
              }}
              onClick={() => {
                window.location.href = `#navigate.${JSON.stringify({
                  screen: 'MainStackAMainTabNavigator',
                })}`
              }}
            >
              <div
                style={{
                  color: 'white',
                  fontSize: 20,
                  fontWeight: 'bold',
                  paddingLeft: 8,
                  paddingRigtt: 8,
                  fontFamily: 'Maplestory',
                }}
              >
                쇼핑하러 가기
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default ChocoGiftCloseNoticeModal
