import React, { useState, useEffect } from 'react'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import Layout from '../components/layout'
import Header from '../components/header'
import Footer from '../components/footer'
import { useAlert } from 'react-alert'

function luckyBoxEventGetPhoneBook() {
  const alert = useAlert()
  const token = useSearchParam('token')
  const [randomString, setRandomString] = useState(
    Math.random().toString(36).substr(2, 8),
  )
  const [ongoingLuckyBoxTeamInfo, setOngoingLuckyBoxTeamInfo] = useState([])
  const [backgroundColor, setBackgroundColor] = useState('#BEC0C2')
  const [checkBox, setCheckBox] = useState(false)

  const initializeLuckyBoxTeamInfo = async () => {
    const result = await backendApis.getLuckyBoxTeamInfo(token, 'GET')
    setOngoingLuckyBoxTeamInfo(result?.data?.itemList)
    // if (!result?.data) {
    //   window.location.href = `/LuckyBoxEventIntro/?token=${token}&randomString=${Math.random()
    //     .toString(36)
    //     .substr(2, 8)}`
    // }
  }

  useEffect(() => {
    initializeLuckyBoxTeamInfo()
  }, [])

  useEffect(() => {}, [ongoingLuckyBoxTeamInfo])

  const ItemComponent = ({ itemId, itemTitle, mainImageUri, tier }) => (
    <>
      <div
        style={{
          width: '32%',
          height: '56%',
        }}
      >
        <button
          style={{ background: 'none', border: 'none' }}
          onClick={() => {}}
        >
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              height: '70%',
              marginTop: 8,
            }}
          >
            <img src={mainImageUri} alt='' style={{ height: 84, width: 84 }} />
          </div>
          <div
            style={{
              textAlign: 'center',
              fontFamily: 'GmarketSans',
              fontWeight: 300,
              fontSize: 12,
              color: 'black',
            }}
          >
            {itemTitle}
          </div>
        </button>
      </div>
    </>
  )

  const LuckyBoxComponent = ({ luckyBoxItemList }) => (
    <>
      <div
        style={{
          backgroundColor: 'white',
          width: '80vw',
          height: '50vh',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 8,
          margin: 8,
          padding: 2,
        }}
      >
        <div
          style={{
            textAlign: 'center',
            borderWidth: 1.5,
            fontFamily: 'GmarketSans',
            fontWeight: 700,
            marginTop: 8,
          }}
        >
          <div style={{ zIndex: 2 }}>
            <img
              src='/images/LuckyBox.png'
              alt=''
              style={{ height: 72, width: 72, marginTop: -48, marginBottom: 8 }}
            />
          </div>
          <span style={{ color: '#E63423', paddingRight: 4 }}>
            100% 당첨되는
          </span>
          행운상자
        </div>
        <div
          style={{
            borderBottom: '#ebebeb solid',
            borderBottomWidth: 1.5,
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 4,
            marginBottom: 12,
            boxSizing: 'border-box',
            padding: 8,
          }}
        />
        <div
          style={{
            width: '100%',
            height: '64%',
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          {luckyBoxItemList?.map((each) => (
            <ItemComponent
              itemId={each?._id}
              itemTitle={each?.itemTitle}
              mainImageUri={each?.mainImageUri[0]}
              tier={each?.tier}
            />
          ))}
        </div>
      </div>
    </>
  )

  return (
    <Layout>
      <Header title='행운상자 선물하기' />
      <div style={{ height: '93%' }}>
        <div
          style={{
            display: 'flex',
            height: '150vh',
            width: '100vw',
            background:
              'linear-gradient(180deg, #f67815 0%, #f98611 3.65%, #E63323 94.79%, #e83b21 100%)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ height: 120, marginTop: 28 }}>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 900,
                fontSize: 32,
                color: 'white',
                textAlign: 'center',
              }}
            >
              친구목록 불러오기
            </div>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 500,
                fontSize: 14,
                color: 'white',
                textAlign: 'center',
                paddingTop: 4,
                paddingBottom: 2,
              }}
            >
              <span
                style={{
                  fontWeight: 900,
                  marginLeft: 2,
                  paddingLeft: 2,
                  paddingRight: 2,
                  backgroundColor: '#ED531C',
                }}
              >
                연락처로 친구에게 선물
              </span>
              하려면
            </div>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 500,
                fontSize: 14,
                color: 'white',
                textAlign: 'center',
              }}
            >
              개인정보 수집 및 이용이 필요합니다.
            </div>
          </div>
          <div />
          <LuckyBoxComponent luckyBoxItemList={ongoingLuckyBoxTeamInfo} />
          <div>
            <button
              style={{
                background: 'none',
                border: 'none',
                fontFamily: 'GmarketSans',
                fontWeight: 500,
                fontSize: 14,
                color: 'white',
                textAlign: 'center',
                marginTop: 24,
                display: 'flex',
                justifyContent: 'center',
              }}
              onClick={() => {
                if (!checkBox) {
                  setBackgroundColor('#FFA13D')
                } else {
                  setBackgroundColor('#BEC0C2')
                }
                setCheckBox(!checkBox)
              }}
            >
              {!checkBox ? (
                <img
                  src='/images/uncheckedIcon.png'
                  alt=''
                  style={{ height: 16, width: 16, paddingRight: 4 }}
                />
              ) : (
                <img
                  src='/images/checkedIcon.png'
                  alt=''
                  style={{ height: 16, width: 16, paddingRight: 4 }}
                />
              )}
              개인정보 수집 및 이용이 필요합니다.
            </button>
          </div>
        </div>
      </div>

      <Footer
        title='선물하기'
        // clickable
        backgroundColor={`${backgroundColor}`}
        onClick={async () => {
          if (checkBox) {
            console.log(`전화번호 불러와!`)
            window.location.href = `#uploadContacs`
            // window.location.href = `/luckyBoxEvent/?token=${token}&randomString=${Math.random()
            //   .toString(36)
            //   .substr(2, 8)}`
          } else {
            alert.show('개인정보 수집에 동의해야 행운상자를 선물할 수 있어요', {
              closeCopy: '확인',
            })
          }
        }}
      />
    </Layout>
  )
}
export default luckyBoxEventGetPhoneBook
