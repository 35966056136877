import React, { useState } from 'react'
import commaNumber from 'comma-number'
import { useAlert } from 'react-alert'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'

const GganbuChickenGganbuExplainModal = ({
  setShowGganbuChickenGganbuExplainModal,
}) => (
  <>
    <div
      style={{
        flex: 1,
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 3,
        backgroundColor: 'rgba(0,0,0,0.6)',
        width: '100%',
        height: '100%',
        fontFamily: 'GmarketSans',
      }}
    >
      <button
        type='button'
        style={{
          width: 100,
          height: 100,
          background: 'none',
          border: 'none',
          top: '0%',
          right: '-1%',
          position: 'absolute',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          setShowGganbuChickenGganbuExplainModal(false)
        }}
      >
        <img
          style={{
            zIndex: 101,
            width: '36%',
            height: '36%',
            position: 'absolute',
          }}
          src='/images/closeIcon.png'
          alt=''
        />
      </button>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          height: '100%',
          padding: 24,
          boxSizing: 'border-box',
        }}
      >
        <img
          style={{
            width: '105%',
          }}
          alt=''
          src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/f7d1c1af-804c-4a28-8a02-a02232e962ed.png'
        />
      </div>
    </div>
  </>
)

export default GganbuChickenGganbuExplainModal
