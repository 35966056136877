import React, { useState, useEffect } from 'react'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import Layout from '../components/layout'
import Header from '../components/header'
import Notice from '../components/Notice'
import BlackTextTimer from '../components/BlackTextTimer'
import commaNumber from 'comma-number'
import { useAlert } from 'react-alert'
import useDebounce from '../components/useDebounce'
import ChocoGiftOldUserOnboardingModal from '../modals/ChocoGiftOldUserOnboardingModal'
import ChocoGiftSendNudgingModal from '../modals/ChocoGiftSendNudgingModal'
import ChocoGiftCloseNoticeModal from 'src/modals/ChocoGiftCloseNoticeModal'

let isButtonDisable = false
let hidden
let visibilityChange
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

function AlfarmChocoGift() {
  const alert = useAlert()
  const token = useSearchParam('token')
  const isFrom = useSearchParam('isFrom')
  const alwayzInvitorId = useSearchParam('alwayzInvitorId')
  const code = useSearchParam('code')
  const [isAlreadyExist, setIsAlreadyExist] = useState()
  const [chocoGiftReceiverInfo, setChocoGiftReceiverInfo] = useState()
  const [
    showChocoGiftOldUserOnboardingModal,
    setShowChocoGiftOldUserOnboardingModal,
  ] = useState(false)
  const [showChocoGiftSendNudgingModal, setShowChocoGiftSendNudgingModal] =
    useState(false)
  const [showChocoGiftCloseNoticeModal, setShowChocoGiftCloseNoticeModal] =
    useState(true)
  const [alfarmData, setAlfarmData] = useState()

  const getChocoGiftReceiverInfo = async () => {
    const userInfo = await backendApis.getUserBasicInfoWithAuthentication(token)

    if (isFrom) {
      const ongoingAlfarmData = await backendApis.getAlFarmUserData(token)
      if (ongoingAlfarmData?.data?._id) {
        setAlfarmData(ongoingAlfarmData?.data)
        setIsAlreadyExist(true)
        setShowChocoGiftSendNudgingModal(true)
      } else {
        setAlfarmData(ongoingAlfarmData?.data)
        setIsAlreadyExist(false)
        setShowChocoGiftSendNudgingModal(true)
      }
    }

    const result = await backendApis.getChocoGiftReceiverInfo(token)
    if (result?.status === 200 && result?.data?._id) {
      setChocoGiftReceiverInfo(result?.data)
    } else if (
      userInfo?.status !== 200 ||
      userInfo?.data?.phoneNumber === '' ||
      userInfo?.data?.userName === ''
    ) {
      console.log(44)
      window.location.href = '#openLoginModal.'
    } else {
      console.log(55)
    }
  }
  useEffect(() => {
    getChocoGiftReceiverInfo()
  }, [])

  useEffect(() => {
    document.addEventListener(
      visibilityChange,
      (visibility) => {
        const now = Date.now()
        if (document.visibilityState === 'visible') {
          getChocoGiftReceiverInfo()
        }
      },
      false,
    )
  }, [token])

  const alfarmMissionButton = async () => {
    const userInfo = await backendApis.getUserBasicInfoWithAuthentication(token)
    // 로그인 안되어 있는 경우
    if (
      userInfo?.status !== 200 ||
      userInfo?.data?.phoneNumber === '' ||
      userInfo?.data?.userName === ''
    ) {
      window.location.href = '#openLoginModal.'
      return
    }
    const ongoingAlfarmData = await backendApis.getAlFarmUserData(token)
    if (ongoingAlfarmData?.data?._id) {
      setAlfarmData(ongoingAlfarmData?.data)
      setIsAlreadyExist(true)
    } else {
      setIsAlreadyExist(false)
    }
    if (chocoGiftReceiverInfo?._id) {
      if (chocoGiftReceiverInfo?.alfarmMissionDone === true) {
        alert.show('이미 미션을 완료하였어요. 무료 초콜릿을 받아보세요!')
      } else {
        if (ongoingAlfarmData?.status !== 200) {
          isButtonDisable = false
          return
        }
        window.location.href = `#navigate.${JSON.stringify({
          screen: 'gameTest',
          prop: {
            origin: 'alfarmChocoGift',
            isAlreadyExist: !!ongoingAlfarmData?.data?._id,
            itemType: ongoingAlfarmData?.data?.itemType,
          },
        })}`
        await backendApis.setChocoGiftAlfarmMission(token)
      }
    } else {
      setShowChocoGiftOldUserOnboardingModal(true)
    }
  }

  const chocoReceiveButton = async () => {
    const userInfo = await backendApis.getUserBasicInfoWithAuthentication(token)
    // 로그인 안되어 있는 경우
    if (
      userInfo?.status !== 200 ||
      userInfo?.data?.phoneNumber === '' ||
      userInfo?.data?.userName === ''
    ) {
      window.location.href = '#openLoginModal.'
      return
    }
    const ongoingAlfarmData = await backendApis.getAlFarmUserData(token)
    if (ongoingAlfarmData?.data?._id) {
      setAlfarmData(ongoingAlfarmData?.data)
      setIsAlreadyExist(true)
    } else {
      setIsAlreadyExist(false)
    }
    if (chocoGiftReceiverInfo?._id) {
      if (chocoGiftReceiverInfo?.isItemReceived === true) {
        alert.show(
          '이미 초콜릿 주문을 완료하였어요. 문제가 발생한 경우 고객센터로 문의를 주세요.',
        )
      } else if (chocoGiftReceiverInfo?.alfarmMissionDone === true) {
        await backendApis.updateChocoGiftSenderFertSet(token, 'PUT', {
          invitorId: alwayzInvitorId,
        })
        window.location.href = `#toOrderMain.${JSON.stringify({
          props: {
            // dev 에서는 60edc235ffc0a087c1716690 production에서는 63e4eef456fa9d0a577f0d46
            itemId: '63e4eef456fa9d0a577f0d46',
            enteringComponent: 'chocoGift',
            type: 'chocoGift',
            invitorId: null,
            eventPageMapping: 'alfarmChocoGift',
          },
        })}`
      } else {
        if (ongoingAlfarmData?.status !== 200) {
          isButtonDisable = false
          return
        }
        window.location.href = `#navigate.${JSON.stringify({
          screen: 'gameTest',
          prop: {
            origin: 'alfarmChocoGift',
            isAlreadyExist: !!ongoingAlfarmData?.data?._id,
            itemType: ongoingAlfarmData?.data?.itemType,
          },
        })}`
        await backendApis.setChocoGiftAlfarmMission(token)
      }
    } else {
      setShowChocoGiftOldUserOnboardingModal(true)
    }
  }

  return (
    <>
      <Layout>
        <Header title='초콜릿 선물 이벤트' />
        <div
          style={{
            width: '100%',
            height: '100vh',
            backgroundColor: '#FFF8F1',
            marginBottom: 10,
            fontFamily: 'Maplestory',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ fontSize: 32, marginTop: 68, color: '#793B10' }}>
            5초 미션을 수행하면
          </div>
          <div
            style={{
              fontSize: 32,
              marginBottom: 32,
              fontWeight: 'bold',
              color: '#793B10',
            }}
          >
            초콜릿을 받을 수 있어요
          </div>
          <div
            style={{
              backgroundImage: "url('/images/chocoGiftCenterImage.png')",
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '60%',
              height: 128,
              marginTop: 8,
            }}
          />
          <button
            type='button'
            style={{
              width: '50%',
              border: 'none',
              background: 'none',
              display: 'flex',
              justifyContent: 'center',
              marginTop: 16,
            }}
            onClick={() => {
              alfarmMissionButton()
            }}
          >
            {chocoGiftReceiverInfo?.alfarmMissionDone === true ? (
              <>
                <div
                  style={{
                    backgroundImage: "url('/images/chocoGiftClearMission.png')",
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: 200,
                  }}
                />
              </>
            ) : (
              <>
                <div
                  style={{
                    backgroundImage: "url('/images/chocoGiftPreMission.png')",
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: 200,
                  }}
                />
              </>
            )}
          </button>
          <div style={{ marginTop: 16, color: '#98896C' }}>
            *올웨이즈 신규 고객만 공짜 초콜릿을 받을 수 있어요
          </div>
          <button
            type='button'
            style={{
              width: '100%',
              border: 'none',
              background: 'none',
              display: 'flex',
              justifyContent: 'center',
              marginTop: 12,
            }}
            onClick={() => {
              chocoReceiveButton()
            }}
          >
            {chocoGiftReceiverInfo?.alfarmMissionDone === true ? (
              <>
                <div
                  style={{
                    backgroundImage:
                      "url('/images/chocoGiftClearMissionButton.png')",
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    width: '90%',
                    height: 120,
                  }}
                />
              </>
            ) : (
              <>
                <div
                  style={{
                    backgroundImage:
                      "url('/images/chocoGiftPreMissionButton.png')",
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    width: '90%',
                    height: 120,
                  }}
                />
              </>
            )}
          </button>
        </div>
        {showChocoGiftOldUserOnboardingModal && (
          <ChocoGiftOldUserOnboardingModal
            setShowChocoGiftOldUserOnboardingModal={
              setShowChocoGiftOldUserOnboardingModal
            }
            alfarmData={alfarmData}
            token={token}
          />
        )}
        {showChocoGiftCloseNoticeModal && (
          <ChocoGiftCloseNoticeModal
            setShowChocoGiftCloseNoticeModal={setShowChocoGiftCloseNoticeModal}
            alfarmData={alfarmData}
            token={token}
          />
        )}
        {showChocoGiftSendNudgingModal && (
          <ChocoGiftSendNudgingModal
            setShowChocoGiftSendNudgingModal={setShowChocoGiftSendNudgingModal}
            alfarmData={alfarmData}
            token={token}
          />
        )}
      </Layout>
    </>
  )
}
export default AlfarmChocoGift
