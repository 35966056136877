import React, { useState, useEffect } from 'react'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import Layout from '../components/layout'
import Header from '../components/header'
import Footer from '../components/footer'
import { useAlert } from 'react-alert'
import LuckyBoxNudgingModal from '../modals/LuckyBoxNudgingModal'
import LuckyBoxResultModal from '../modals/LuckyBoxResultModal'
import LuckyBoxOrderModal from '../modals/LuckyBoxOrderModal'
import LuckyBoxNotAllowModal from '../modals/LuckyBoxNotAllowModal'
import LuckyBoxCloseNoticeModal from '../modals/LuckyBoxCloseNoticeModal'

let hidden
let visibilityChange
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

let isButtonDisable = false
function luckyBoxEventInvitation() {
  const alert = useAlert()
  const token = useSearchParam('token')
  const alwayzInvitorId = useSearchParam('alwayzInvitorId')
  const documentId = useSearchParam('documentId')
  // const userId = useSearchParam('userId')
  const [userId, setUserId] = useState()
  const [backgroundColor, setBackgroundColor] = useState('#BEC0C2')
  const [randomString, setRandomString] = useState(
    Math.random().toString(36).substr(2, 8),
  )
  const [showLuckyBoxNudgingModal, setShowLuckyBoxNudgingModal] =
    useState(false)
  const [showLuckyBoxResultModal, setShowLuckyBoxResultModal] = useState(false)
  const [showLuckyBoxOrderModal, setShowLuckyBoxOrderModal] = useState(false)
  const [showLuckyBoxNotAllowModal, setShowLuckyBoxNotAllowModal] =
    useState(false)
  const [receivedLuckyBoxInfo, setReceivedLuckyBoxInfo] = useState([])
  const [receiverInfo, setReceiverInfo] = useState()
  const [userName, setUserName] = useState()
  const [drawIndex, setDrawIndex] = useState()
  const [drawResultIndex, setDrawResultIndex] = useState()
  const [ongoingLuckyBoxTeamInfo, setOngoingLuckyBoxTeamInfo] = useState([])
  const [tierIndex, setTierIndex] = useState(1)
  const [luckyItems, setLuckyItems] = useState([])
  const [showLuckyBoxCloseNoticeModal, setShowLuckyBoxCloseNoticeModal] =
    useState(false)

  const initializeLuckyBoxTeamInfo = async () => {
    const userInfo = await backendApis.getUserBasicInfoWithAuthentication(token)
    if (userInfo) {
      setBackgroundColor('#FFA13D')
      setUserId(userInfo?.data?._id)
      setUserName(userInfo?.data?.userName)
    }

    // 행운상자 전체 상품 리스트 불러오기
    const result = await backendApis.getLuckyBoxItemTotalListInfo(token, 'GET')
    if (result?.status === 200 && result?.data?.length) {
      setLuckyItems(result?.data)
    }

    // lucky_box_receiver 무조건 불러오기
    const receiverInfoResult = await backendApis.getReceivedLuckyBoxInfo(
      token,
      'GET',
      {
        documentId,
      },
    )
    // lucky_box_info 무조건 불러오기
    const ongoingLuckyBoxTeamInfoResult = await backendApis.getLuckyBoxTeamInfo(
      token,
      'GET',
    )
    if (receiverInfoResult?.status === 200 && receiverInfoResult?.data?._id) {
      setReceiverInfo(receiverInfoResult?.data)
      setReceivedLuckyBoxInfo(receiverInfoResult?.data?.itemList)
    } else {
      // 링크가 없는 경우 userId 기반으로 불러오기
      const receiverInfoByUserIdResult =
        await backendApis.getReceivedLuckyBoxInfoByUserId(token, 'GET')
      if (receiverInfoByUserIdResult?.status === 200) {
        setReceiverInfo(receiverInfoByUserIdResult?.data)
        setReceivedLuckyBoxInfo(receiverInfoByUserIdResult?.data?.itemList)
      } else {
        setReceivedLuckyBoxInfo(ongoingLuckyBoxTeamInfoResult?.data?.itemList)
      }
    }

    // 이미 다른 사람에게 행운 상자를 받은 이력이 있는 경우
    if (
      receiverInfoResult?.status === 200 &&
      receiverInfoResult?.data === 'already opened'
    ) {
      setShowLuckyBoxNotAllowModal(true)
      return
    }

    if (ongoingLuckyBoxTeamInfoResult?.status === 200) {
      setOngoingLuckyBoxTeamInfo(ongoingLuckyBoxTeamInfoResult?.data)
    }

    // 배너로 진입한 기존 고객이 5명에게 선물하고 행운 상자를 골라서 오픈하러 넘어온 경우
    if (userInfo?.data?._id === documentId) {
      setReceivedLuckyBoxInfo(ongoingLuckyBoxTeamInfoResult?.data?.itemList)
    }

    // 배너로 진입한 기존 고객이 행운 상자를 오픈했는데 상품을 받기 이전인 경우
    if (
      ongoingLuckyBoxTeamInfoResult?.openCount ===
        ongoingLuckyBoxTeamInfoResult?.itemReceiveCount + 1 &&
      !documentId
    ) {
      const tempReceiverInfo =
        ongoingLuckyBoxTeamInfoResult?.data?.winningItemInfo.slice(-1)[0]
      setReceiverInfo(tempReceiverInfo)
      setShowLuckyBoxOrderModal(true)
      return
    }

    // 배너로 진입한 신규 고객이 행운 상자를 오픈했는데 상품을 받기 이전인 경우
    // lucky_box_receiver 불러오기(배너 진입 시, documentId가 없음)
    const receiverInfoByUserIdResult =
      await backendApis.getReceivedLuckyBoxInfoByUserId(token, 'GET', {
        documentId,
      })
    if (
      receiverInfoByUserIdResult?.data?.isReceived &&
      !receiverInfoByUserIdResult?.data?.isItemReceived &&
      !documentId
    ) {
      setReceiverInfo(receiverInfoByUserIdResult?.data)
      setShowLuckyBoxOrderModal(true)
    }
  }

  useEffect(() => {
    initializeLuckyBoxTeamInfo()
  }, [])

  useEffect(() => {
    document.addEventListener(
      visibilityChange,
      (visibility) => {
        const now = Date.now()
        if (document.visibilityState === 'visible') {
          initializeLuckyBoxTeamInfo()
        }
      },
      false,
    )
  }, [token])

  useEffect(() => {
    if (drawIndex < drawResultIndex) {
      setTimeout(() => {
        setDrawIndex(drawIndex + 1)
      }, drawIndex * drawResultIndex + 1)
    } else if (drawIndex && drawResultIndex) {
      //  룰렛 끝
      setTimeout(() => {
        if (receiverInfo?.isReopened || documentId === userId) {
          setShowLuckyBoxOrderModal(true)
        } else {
          setShowLuckyBoxResultModal(true)
        }
      }, 50)
    }
  }, [drawIndex, drawResultIndex])

  const ItemComponent = ({ item, index, drawIndex }) => {
    if (!item) return null
    const { itemTitle, mainImageUri, tier } = item
    const color = index === (drawIndex - 1) % 5
    const subColor = index === ((drawIndex - 1) % 5) - 1
    let border = '4px'
    let size = 84
    if (color) {
      border = '4px solid #FFA13D'
      size = 76
    } else if (subColor) {
      border = '4px solid #FFEFDD'
      size = 76
    }
    return (
      <>
        <div
          style={{
            width: '32%',
            height: '56%',
          }}
        >
          <button
            type='button'
            style={{ background: 'none', border: 'none' }}
            onClick={() => {}}
          >
            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
                height: '70%',
                marginTop: 8,
                border,
                borderRadius: 200,
              }}
            >
              <img
                src={mainImageUri}
                alt=''
                style={{ height: size, width: size }}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                fontFamily: 'GmarketSans',
                fontWeight: 300,
                fontSize: 12,
                marginTop: 8,
                color: 'black',
              }}
            >
              {itemTitle}
            </div>
          </button>
        </div>
      </>
    )
  }

  const LuckyBoxComponent = ({ luckyBoxItemList, drawIndex }) => (
    <>
      <div
        style={{
          backgroundColor: 'white',
          width: '80vw',
          height: '50vh',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 8,
          margin: 8,
          marginTop: 56,
          padding: 2,
        }}
      >
        <div
          style={{
            textAlign: 'center',
            borderWidth: 1.5,
            fontFamily: 'GmarketSans',
            fontWeight: 700,
            marginTop: 8,
          }}
        >
          <div style={{ zIndex: 2 }}>
            <img
              src='/images/LuckyBox.png'
              alt=''
              style={{ height: 72, width: 72, marginTop: -48, marginBottom: 8 }}
            />
          </div>
          {documentId === userId ? '내' : '친구'}가 보낸
          <span style={{ color: '#E63423', paddingRight: 4 }}> 행운상자 </span>
        </div>
        <div
          style={{
            borderBottom: '#ebebeb solid',
            borderBottomWidth: 1.5,
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 4,
            marginBottom: 12,
            boxSizing: 'border-box',
            padding: 8,
          }}
        />
        <div
          style={{
            width: '100%',
            height: '64%',
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          {luckyBoxItemList?.map((each, index) => (
            <ItemComponent item={each} index={index} drawIndex={drawIndex} />
          ))}
        </div>
      </div>
    </>
  )

  const ItemListComponent = ({ itemTitle, mainImageUri }) => (
    <>
      <div
        style={{
          width: '30%',
          height: '100%',
        }}
      >
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            height: '70%',
            marginTop: 8,
          }}
        >
          <img src={mainImageUri} alt='' style={{ height: 72, width: 72 }} />
        </div>
        <div
          style={{
            textAlign: 'center',
            fontFamily: 'GmarketSans',
            fontWeight: 300,
            fontSize: 8,
            color: 'black',
            marginTop: 8,
          }}
        >
          {itemTitle}
        </div>
      </div>
    </>
  )

  const LuckyBoxListComponent = ({ luckyItems }) => (
    <>
      <div
        style={{
          backgroundColor: 'white',
          width: '72vw',
          height: '28vh',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 8,
          padding: 2,
        }}
      >
        <div
          style={{
            textAlign: 'center',
            borderWidth: 1.5,
            fontFamily: 'GmarketSans',
            fontWeight: 700,
            marginTop: 12,
            marginBottom: 4,
          }}
        >
          <span style={{ color: '#E63423', paddingRight: 4 }}>
            100% 당첨되는
          </span>
          행운상자
        </div>
        <div
          style={{
            textAlign: 'center',
            borderWidth: 1.5,
            fontFamily: 'GmarketSans',
            fontWeight: 700,
          }}
        >
          <span style={{ color: '#E63423', paddingRight: 4 }}>
            {tierIndex}순위
          </span>
          상품
        </div>

        <div
          style={{
            borderBottom: '#ebebeb solid',
            borderBottomWidth: 1.5,
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 4,
            boxSizing: 'border-box',
            padding: 4,
          }}
        />
        <div
          style={{
            width: '100%',
            height: '50%',

            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {luckyItems?.map((each) => (
            <ItemListComponent
              itemTitle={each?.itemTitle}
              mainImageUri={each?.mainImageUri[0]}
            />
          ))}
        </div>
      </div>
    </>
  )

  return (
    <Layout>
      <Header title='행운상자 선물하기' />
      <div style={{ overflowY: 'scroll', height: '93%' }}>
        <div
          style={{
            backgroundColor: 'rgba(0,0,0,0.8)',
            padding: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button
            type='button'
            style={{
              textAlign: 'center',
              color: 'white',
              fontFamily: 'GmarketSans',
              fontWeight: 700,
              backgroundColor: '#E63323',
              padding: 8,
              paddingLeft: 16,
              paddingRight: 16,
              border: 'none',
              borderRadius: 4,
              fontSize: 16,
            }}
            onClick={() => setShowLuckyBoxCloseNoticeModal(true)}
          >
            행운상자 종료 안내 (12월 16일 종료)
          </button>
        </div>
        <div
          style={{
            display: 'flex',
            height: '132vh',
            width: '100vw',
            background:
              'linear-gradient(180deg, #f67815 0%, #f98611 3.65%, #E63323 94.79%, #e83b21 100%)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ height: 120, marginTop: 28 }}>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 500,
                fontSize: 32,
                color: 'white',
                textAlign: 'center',
              }}
            >
              {documentId === userId ? '내' : '친구'}가 보낸
            </div>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 500,
                fontSize: 32,
                color: 'white',
                textAlign: 'center',
              }}
            >
              <span style={{ fontWeight: 900 }}>행운상자</span>가 왔어요
            </div>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 500,
                fontSize: 14,
                color: 'white',
                textAlign: 'center',
                paddingTop: 4,
                paddingBottom: 2,
                marginTop: 8,
              }}
            >
              {documentId === userId ? '내' : '친구'}가 행운 상자를 선물했어요
            </div>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 500,
                fontSize: 14,
                color: 'white',
                textAlign: 'center',
              }}
            >
              아래의 상품 중 1개를{' '}
              <span
                style={{
                  fontWeight: 900,
                  marginLeft: 2,
                  paddingLeft: 2,
                  paddingRight: 2,
                  backgroundColor: '#ED531C',
                }}
              >
                100% 확률
              </span>
              로 드려요
            </div>
          </div>
          <div />
          <LuckyBoxComponent
            luckyBoxItemList={receivedLuckyBoxInfo}
            drawIndex={drawIndex}
            setDrawIndex={setDrawIndex}
          />
          <div
            style={{
              fontFamily: 'GmarketSans',
              fontWeight: 500,
              fontSize: 14,
              color: 'white',
              textAlign: 'center',
              paddingTop: 4,
              paddingBottom: 2,
              marginTop: 8,
            }}
          >
            친구가 아래의 상품 리스트에서
          </div>
          <div
            style={{
              fontFamily: 'GmarketSans',
              fontWeight: 500,
              fontSize: 14,
              color: 'white',
              textAlign: 'center',
              paddingTop: 4,
              paddingBottom: 2,
              marginBottom: 12,
            }}
          >
            정성스레 행운 상자를 담았어요
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'center',
              alignItems: 'center',
            }}
          >
            <button
              type='button'
              style={{
                width: 50,
                height: 50,
                background: 'none',
                border: 'none',
              }}
              onClick={() => {
                if (tierIndex < 2) {
                  setTierIndex(5)
                } else {
                  setTierIndex(tierIndex - 1)
                }
              }}
            >
              <img
                src='/images/leftArrow.png'
                alt=''
                style={{ height: 28, width: 28 }}
              />
            </button>
            <LuckyBoxListComponent
              luckyItems={luckyItems?.filter(
                (each) => each?.tier === tierIndex,
              )}
            />
            <button
              type='button'
              style={{
                width: 50,
                height: 50,
                background: 'none',
                border: 'none',
              }}
              onClick={() => {
                if (tierIndex < 5) {
                  setTierIndex(tierIndex + 1)
                } else {
                  setTierIndex(1)
                }
              }}
            >
              <img
                src='/images/rightArrow.png'
                alt=''
                style={{ height: 28, width: 28 }}
              />
            </button>
          </div>
        </div>
      </div>

      <Footer
        title={
          backgroundColor === '#BEC0C2'
            ? '로그인하고 행운 상자 열기'
            : '행운 상자 열기'
        }
        // clickable
        backgroundColor={`${backgroundColor}`}
        onClick={async () => {
          // 5명 채워서 여는 경우 selectModal에서 documentId와 userId를 같게 만들어서 보냄
          if (
            documentId === userId ||
            (documentId === 'null' && ongoingLuckyBoxTeamInfo?._id)
          ) {
            // 아직 열어보지 않은 경우에만 행운상자를 개봉
            if (
              ongoingLuckyBoxTeamInfo?.openCount ===
              ongoingLuckyBoxTeamInfo?.itemReceiveCount
            ) {
              const result = await backendApis.openSelfLuckyBox(token, 'PUT', {
                selectedItemList: ongoingLuckyBoxTeamInfo?.itemList,
              })
              if (
                result?.status === 200 &&
                result?.data?.itemReceiveCount + 1 === result?.data?.openCount
              ) {
                const tempReceiverInfo = result?.data?.winningItemInfo
                setReceiverInfo(tempReceiverInfo)
                setDrawIndex(0)
                setDrawResultIndex(20 + result?.data?.drawIndex)
              }
            } else {
              // 이미 열어본 경우에는 orderModal을 보여줌
              const tempReceiverInfo =
                ongoingLuckyBoxTeamInfo?.winningItemLog?.slice(-1)?.[0]
              setReceiverInfo(tempReceiverInfo)
              setShowLuckyBoxOrderModal(true)
            }
          } else {
            // 토큰의 user document가 가입이 안되어 있는 경우
            const userInfo =
              await backendApis.getUserBasicInfoWithAuthentication(token)
            // 로그인 안되어 있는 경우
            if (
              userInfo?.status !== 200 ||
              userInfo?.data?.phoneNumber === '' ||
              userInfo?.data?.userName === ''
            ) {
              window.location.href = '#openLoginModal.'
              isButtonDisable = false
              return
            }
            // 본인 인증 안되어 있는 경우
            if (
              userInfo?.status !== 200 ||
              (!userInfo?.data?.selfAuthenticationInfo &&
                !userInfo?.data?.selfAuthenticationInfoLogs &&
                new Date(userInfo?.data?.createdAt) >
                  new Date('2022-12-02T04:00:00'))
            ) {
              window.location.href = '#openAuthenticationModal.'
              isButtonDisable = false
              return
            }

            // 자신이 생성한 링크로 참여하거나 자신에게 보내진 선물이 아닌 링크로 참여했을 때 넛지 모달 띄우기
            if (
              ((userInfo?.data?.phoneNumber !==
                receiverInfo?.receiverPhoneNumber &&
                receiverInfo) ||
                userId === alwayzInvitorId) &&
              userId !== documentId &&
              ongoingLuckyBoxTeamInfo?.openCount ===
                ongoingLuckyBoxTeamInfo?.itemReceiveCount
            ) {
              setShowLuckyBoxNudgingModal(true)
              return
            }

            // 오픈 이력 있고 리텐션 엔진 2개 완료 안했으면 리텐션 엔진 유도 모달(resultModal) 띄우기
            if (
              receiverInfo?.isReceived &&
              (receiverInfo?.missionStatus?.isCheckInStatus !==
                'clearMission' ||
                receiverInfo?.missionStatus?.isAlfarmStatus !== 'clearMission')
            ) {
              setShowLuckyBoxResultModal(true)
              return
            }

            // 이미 상품을 받았으면 상품 못 받는다는 모달(NotAllowModal) 띄우기
            if (
              receiverInfo?.isItemReceived &&
              receiverInfo?.receiverId === userId
            ) {
              setShowLuckyBoxNotAllowModal(true)
              return
            }

            // 오픈 이력 있고 재오픈 이력도 있으면 주문 모달(OrderModal) 띄우기
            if (
              receiverInfo?.isReceived &&
              receiverInfo?.isReopened &&
              receiverInfo?.receiverId === userId
            ) {
              setShowLuckyBoxOrderModal(true)
              return
            }

            // 오픈 이력이 없으면 무조건 오픈 || 오픈 이력이 있지만 리텐션 엔진 2개를 완료하고 재오픈을 안한 상태면 오픈
            if (
              !receiverInfo?.isReceived ||
              (receiverInfo?.isReceived &&
                receiverInfo?.missionStatus?.isCheckInStatus ===
                  'clearMission' &&
                receiverInfo?.missionStatus?.isAlfarmStatus ===
                  'clearMission' &&
                !receiverInfo?.isReopened)
            ) {
              const result = await backendApis.openReceivedLuckyBox(
                token,
                'PUT',
                {
                  documentId,
                },
              )
              if (result?.status === 200) {
                setReceiverInfo(result?.data)
                setDrawIndex(0)
                setDrawResultIndex(20 + result?.data?.drawIndex)
              } else if (result?.data?.msg === 'invitee is under age') {
                alert.show('본 이벤트는 20세 이상 고객만 참여 가능해요', {
                  closeCopy: '확인',
                })
              } else if (result?.data?.msg === 'left service in 24 hours') {
                alert.show(
                  '24시간 이내 탈퇴한 이력이 있는 고객은 참여 할 수 없어요',
                  {
                    closeCopy: '확인',
                  },
                )
              } else if (result?.data?.msg === 'invitee is not new user') {
                alert.show(
                  '이미 행운상자를 받았거나 행운상자 이벤트 대상자가 아니에요',
                  {
                    closeCopy: '확인',
                  },
                )
              } else {
                alert.show(`알 수 없는 오류가 발생했어요.\n다시 시도해주세요`, {
                  closeCopy: '확인',
                })
              }
            } else {
              alert.show(`알 수 없는 오류가 발생했어요.\n다시 시도해주세요`, {
                closeCopy: '확인',
              })
            }
          }
        }}
      />

      {showLuckyBoxNudgingModal && (
        <LuckyBoxNudgingModal
          setShowLuckyBoxNudgingModal={setShowLuckyBoxNudgingModal}
          userName={userName}
          token={token}
          randomString={randomString}
          isSelfInvitation={userId === alwayzInvitorId}
        />
      )}
      {showLuckyBoxResultModal && (
        <LuckyBoxResultModal
          setShowLuckyBoxResultModal={setShowLuckyBoxResultModal}
          token={token}
          randomString={randomString}
          invitorId={alwayzInvitorId}
          winningItemInfo={receiverInfo?.winningItemInfo}
          // receiverInfo={receiverInfo}
          checkInMissionStatus={receiverInfo?.missionStatus?.isCheckInStatus}
          alfarmMissionStatus={receiverInfo?.missionStatus?.isAlfarmStatus}
          reopenStatus={receiverInfo?.isReopened}
          // winningItem={}
        />
      )}
      {showLuckyBoxOrderModal && (
        <LuckyBoxOrderModal
          setShowLuckyBoxOrderModal={setShowLuckyBoxOrderModal}
          token={token}
          randomString={randomString}
          invitorId={alwayzInvitorId}
          userId={userId}
          winningItemInfo={receiverInfo?.winningItemInfo}
        />
      )}
      {showLuckyBoxNotAllowModal && (
        <LuckyBoxNotAllowModal
          setShowLuckyBoxNotAllowModal={setShowLuckyBoxNotAllowModal}
          userName={userName}
          token={token}
          randomString={randomString}
          isSelfInvitation={userId === alwayzInvitorId}
        />
      )}
      {showLuckyBoxCloseNoticeModal && (
        <LuckyBoxCloseNoticeModal
          setShowLuckyBoxCloseNoticeModal={setShowLuckyBoxCloseNoticeModal}
          token={token}
        />
      )}
    </Layout>
  )
}
export default luckyBoxEventInvitation
