import React, { useEffect, useState } from 'react'
import backendApis from '../utils/backendApis'

const LuckyBoxNewUserNudgingModal = ({
  setShowLuckyBoxNewUserNudgingModal,
  token,
}) => (
  <>
    <div
      style={{
        flex: 1,
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 3,
        backgroundColor: 'rgba(0,0,0,0.8)',
        width: '100%',
        height: '100%',
      }}
    >
      <button
        type='button'
        style={{
          width: 100,
          height: 100,
          backgroundColor: 'red',
          background: 'none',
          border: 'none',
          top: '12%',
          right: '-2%',
          position: 'absolute',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={async () => {
          const result = await backendApis.getEventPageMappingUrl(
            token,
            'GET',
            {
              eventPageName: 'luckyBoxEvent',
            },
          )
          setShowLuckyBoxNewUserNudgingModal(false)
          window.location.href = `#pop.${JSON.stringify({
            number: 1,
          })}`
          window.location.href = `#push.${JSON.stringify({
            screen: 'WebEventPage',
            prop: {
              eventPageMapping: {
                redirectUrl: `${result.data?.redirectUrl}/?token=${token}`,
              },
            },
          })}`
        }}
      >
        <img
          style={{
            zIndex: 101,
            width: '36%',
            height: '36%',
            position: 'absolute',
          }}
          src='/images/closeIcon.png'
          alt=''
        />
      </button>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          height: '100%',
          padding: 24,
          boxSizing: 'border-box',
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            zIndex: 4,
            // top: '24%',
            textAlign: 'center',
            paddingTop: 24,
            // paddingBottom: 18,
            borderRadius: 20,
            width: '90vw',
            height: '52vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ zIndex: 5 }}>
            <img
              src='/images/LuckyBox.png'
              alt=''
              style={{
                height: 100,
                width: 100,
                marginTop: -64,
                marginBottom: 8,
              }}
            />
          </div>
          <div
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <>
              <div
                style={{
                  fontFamily: 'GmarketSans',
                  fontWeight: 700,
                  color: 'black',
                  lineHeight: 1.4,
                  marginTop: 8,
                  fontSize: 20,
                }}
              >
                <div>주문이 완료되었어요!</div>
                <span style={{ color: '#EB4F47' }}>행운상자를 1개 더</span> 받는
                방법은?
              </div>
              <div
                style={{
                  marginTop: 8,
                  fontFamily: 'GmarketSans',
                  fontWeight: 500,
                  color: 'black',
                  lineHeight: 1.4,
                }}
              >
                행운상자를 친구 5명에게 보내면
              </div>
              <div
                style={{
                  fontFamily: 'GmarketSans',
                  fontWeight: 500,
                  color: 'black',
                  lineHeight: 1.4,
                }}
              >
                1개를 더 받을 수 있어요
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <img
                  src='/images/nudgingLuckyBox.png'
                  alt=''
                  style={{
                    height: 112,
                    // width: 144,
                    marginTop: 20,
                  }}
                />
              </div>
            </>
            <div
              style={{
                fontSize: 12,
                marginTop: 16,
                marginBottom: 12,
                fontFamily: 'GmarketSans',
                fontWeight: 500,
                color: '#8C8D8F',
                lineHeight: 1.4,
              }}
            >
              <div>연락처를 통해 친구에게 선물하려면</div>
              <div>개인정보 수집 및 이용에 동의가 필요해요</div>
            </div>
          </div>

          <button
            type='button'
            style={{
              bottom: '0',
              right: '0',
              fontSize: 18,
              borderBottomLeftRadius: 20,
              borderBottomRightRadius: 20,
              height: 48,
              borderWidth: 0,
              background: '#FF8808',
              width: '100%',
              paddingTop: 20,
              paddingBottom: 40,
            }}
            onClick={() => {
              setShowLuckyBoxNewUserNudgingModal(false)
              window.location.href = `#pop.${JSON.stringify({
                number: 1,
              })}`
              window.location.href = `#uploadContacs`
            }}
          >
            <div
              style={{
                color: 'white',
                fontSize: 20,
                fontWeight: 'bold',
                paddingLeft: 8,
                paddingRigtt: 8,
              }}
            >
              동의하고 선물하러가기
            </div>
          </button>
        </div>
      </div>
    </div>
  </>
)

export default LuckyBoxNewUserNudgingModal
