import React from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../utils/backendApis'

const ZeroWonStoreSelectConfirmModal = observer(
  ({ setShowSelectConfirmModal, token, itemId, randomString }) => (
    <>
      <div
        style={{
          flex: 1,
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          zIndex: 100,
          backgroundColor: 'rgba(0,0,0,0.8)',
          width: '100%',
          height: '100%',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: 24,
            boxSizing: 'border-box',
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              zIndex: 2,
              flex: 1,
              top: '24%',
              textAlign: 'center',
              paddingTop: 24,
              paddingBottom: 18,
              borderRadius: 18,
            }}
          >
            <div
              style={{
                marginBottom: 12,
                fontSize: 18,
                fontWeight: '600',
              }}
            >
              안내사항
            </div>
            {/* <div
              style={{
                marginBottom: 12,
                fontSize: 36,
                fontWeight: '600',
              }}
            >
              🥳
            </div> */}
            <div
              style={{
                marginBottom: 6,
                fontSize: 16,
              }}
            >
              0원상점 이벤트가 종료되어
            </div>
            <div
              style={{
                marginBottom: 16,
                fontSize: 16,
              }}
            >
              새로운 0원상점 생성이 불가능해요
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              {/* <button
                type='button'
                style={{
                  bottom: '0',
                  right: '0',
                  fontSize: 18,
                  borderRadius: 30,
                  height: 48,
                  borderWidth: 2,
                  background: 'white',
                  width: '30%',
                  margin: 4,
                  borderColor: '#ff3e3e',
                }}
                onClick={() => {
                  setShowSelectConfirmModal(false)
                }}
              >
                <div
                  style={{
                    color: '#ff3e3e',
                    fontSize: 18,
                    fontWeight: 'bold',
                  }}
                >
                  취소
                </div>
              </button>{' '} */}
              <button
                type='button'
                style={{
                  bottom: '0',
                  right: '0',
                  fontSize: 18,
                  borderRadius: 30,
                  height: 48,
                  borderWidth: 0,
                  background: '#ff3e3e',
                  width: '50%',
                  margin: 4,
                }}
                onClick={async () => {
                  setShowSelectConfirmModal(false)
                  // const result = await backendApis.getEventPageMappingUrl(
                  //   token,
                  //   'GET',
                  //   {
                  //     eventPageName: 'zeroWonStore',
                  //   },
                  // )
                  // if (result?.status === 200) {
                  //   const a = await backendApis.createZeroWonStoreTeamInfo(
                  //     token,
                  //     'POST',
                  //     {
                  //       itemId,
                  //     },
                  //   )
                  //   window.location.href = `#pop.${JSON.stringify({
                  //     number: 2,
                  //   })}`
                  //   window.location.href = `#push.${JSON.stringify({
                  //     screen: 'WebEventPage',
                  //     prop: {
                  //       eventPageMapping: {
                  //         redirectUrl: `${result.data?.redirectUrl}/zeroWonStoreItemPage/?token=${token}&randomString=${randomString}`,
                  //       },
                  //     },
                  //   })}`
                  // } else {
                  // }
                }}
              >
                <div
                  style={{
                    color: 'white',
                    fontSize: 18,
                    fontWeight: 'bold',
                  }}
                >
                  닫기
                </div>
              </button>
            </div>

            <div
              style={{
                marginTop: 16,
                fontSize: 16,
              }}
            />
          </div>
        </div>
      </div>
    </>
  ),
)
export default ZeroWonStoreSelectConfirmModal
