import React, { useEffect } from 'react'
import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ReactModal from 'react-modal'
import TestPage from './pages/TestPage'
import UserStore from './stores/UserStore'
import AuthStore from './stores/AuthStore'
import ZeroWonStorePage from './pages/ZeroWonStorePage'
import ZeroWonStoreItemPage from './pages/ZeroWonStoreItemPage'
import ZeroWonStoreFriendItemPage from './pages/ZeroWonStoreFriendItemPage'
import AddressInfoPage from './pages/AddressInfoPage'
import LuckyBoxEvent from './pages/LuckyBoxEvent'
import LuckyBoxEventIntro from './pages/LuckyBoxEventIntro'
import LuckyBoxEventSelectItem from './pages/LuckyBoxEventSelectItem'
import LuckyBoxEventGetPhoneBook from './pages/LuckyBoxEventGetPhoneBook'
import LuckyBoxEventInvitation from './pages/LuckyBoxEventInvitation'
import LuckyBoxSplashPage from './pages/LuckyBoxSplashPage'
import SupportFundEvent from './pages/SupportFundEvent'
import SupportFundInputPage from './pages/SupportFundInputPage'
import AlfarmCoffeeGift from './pages/AlfarmCoffeeGift'
import AlfarmChocoGift from './pages/AlfarmChocoGift'
import OnePlusOne from './pages/OnePlusOne'
import OnePlusOneInvitation from './pages/OnePlusOneInvitation'
import NewYearsMoney from './pages/NewYearsMoney'
import NewYearsMoneyInvitation from './pages/NewYearsMoneyInvitation'
import NewYearsMoneyInputPage from './pages/NewYearsMoneyInputPage'
import GganbuChickenEvent from './pages/GganbuChickenEvent'
import GganbuChickenInvitation from './pages/GganbuChickenInvitation'

ReactModal.setAppElement('#root')

function App() {
  const onMessageHandler = (event) => {
    if (event?.data) {
      const message = JSON.parse(event?.data)
      if (message?.type === 'userInitialSetting') {
        // setCodepushVersion(message?.data?.codepushVersion)
        // setAppVersion(message?.data?.appVersion)
        UserStore.setUserInfo({
          _id: message?.data?._id,
          codepushVersion: message?.data?.codepushVersion,
          appVersion: message?.data?.appVersion,
        })
        AuthStore.setToken(message?.data?.token)
      }
    }
  }
  useEffect(() => {
    window.addEventListener('message', onMessageHandler)
    document.addEventListener('message', onMessageHandler)

    // window.ReactNativeWebView.postMessage(
    //   JSON.stringify({
    //     type: 'ready',
    //   }),
    // )

    return () => {
      window.removeEventListener('message', onMessageHandler)
      document.removeEventListener('message', onMessageHandler)
    }
  }, [])
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/error' element={<div>Error</div>} />
        <Route
          exact
          path='/zeroWonStore/zeroWonStorePage'
          element={<ZeroWonStorePage />}
        />
        <Route
          exact
          path='/zeroWonStore/addressInfo'
          element={<AddressInfoPage />}
        />
        <Route
          exact
          path='/zeroWonStore/zeroWonStoreItemPage'
          element={<ZeroWonStoreItemPage />}
        />
        <Route
          exact
          path='/zeroWonStore/zeroWonStoreFriendItemPage'
          element={<ZeroWonStoreFriendItemPage />}
        />
        <Route exact path='/zeroWonStore' element={<ZeroWonStorePage />} />
        <Route exact path='/luckyBoxEvent' element={<LuckyBoxEvent />} />
        <Route
          exact
          path='/luckyBoxEventIntro'
          element={<LuckyBoxEventIntro />}
        />
        <Route
          exact
          path='/luckyBoxEventSelectItem'
          element={<LuckyBoxEventSelectItem />}
        />
        <Route
          exact
          path='/luckyBoxEventGetPhoneBook'
          element={<LuckyBoxEventGetPhoneBook />}
        />
        <Route
          exact
          path='/luckyBoxEventInvitation'
          element={<LuckyBoxEventInvitation />}
        />
        <Route exact path='/luckyBoxSplash' element={<LuckyBoxSplashPage />} />
        <Route
          exact
          path='/support-fund-event'
          element={<SupportFundEvent />}
        />
        <Route
          exact
          path='/support-fund-input'
          element={<SupportFundInputPage />}
        />
        <Route
          exact
          path='/alfarm-coffee-gift'
          element={<AlfarmCoffeeGift />}
        />
        <Route exact path='/alfarm-choco-gift' element={<AlfarmChocoGift />} />
        <Route exact path='/one-plus-one' element={<OnePlusOne />} />
        <Route
          exact
          path='/one-plus-one-invitation'
          element={<OnePlusOneInvitation />}
        />
        <Route exact path='/new-years-money' element={<NewYearsMoney />} />
        <Route
          exact
          path='/new-years-money-invitation'
          element={<NewYearsMoneyInvitation />}
        />
        <Route
          exact
          path='/new-years-money-input'
          element={<NewYearsMoneyInputPage />}
        />
        <Route
          exact
          path='/gganbu-chicken-event'
          element={<GganbuChickenEvent />}
        />
        <Route
          exact
          path='/gganbu-chicken-invitation'
          element={<GganbuChickenInvitation />}
        />

        {/* <a href="/tutotial" > </a> */}
        {/* <Route path="/detail/:id">
            <Detail />
          </Route> */}
        {/* <LoadingIndicator /> */}
        <Route exact path='/' element={<TestPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
