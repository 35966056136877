import React, { useEffect, useState } from 'react'
import backendApis from '../utils/backendApis'
import { useAlert } from 'react-alert'

const LuckyBoxResultModal = ({
  setShowLuckyBoxResultModal,
  token,
  randomString,
  invitorId,
  winningItemInfo,
  receivedLuckyBoxInfo,
  checkInMissionStatus,
  alfarmMissionStatus,
  reopenStatus,
}) => {
  const alert = useAlert()
  let isPurchasableStatus = false

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          zIndex: 3,
          backgroundColor: 'rgba(0,0,0,0.8)',
          width: '100%',
          height: '100%',
        }}
        // onClick={() => setModalInfo({ visible: false, data: {} })}
      >
        <button
          type='button'
          style={{
            width: 100,
            height: 100,
            backgroundColor: 'red',
            background: 'none',
            border: 'none',
            top: '6%',
            right: '1%',
            position: 'absolute',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => {
            setShowLuckyBoxResultModal(false)
          }}
        >
          <img
            style={{
              zIndex: 101,
              width: '36%',
              height: '36%',
              position: 'absolute',
            }}
            src='/images/closeIcon.png'
            alt=''
          />
        </button>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: 24,
            boxSizing: 'border-box',
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              zIndex: 4,
              // top: '24%',
              textAlign: 'center',
              paddingTop: 24,
              // paddingBottom: 18,
              borderRadius: 20,
              width: '90vw',
              height: '80vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div style={{ zIndex: 5 }}>
              <img
                src='/images/LuckyBox.png'
                alt=''
                style={{
                  height: 100,
                  width: 100,
                  marginTop: -64,
                  marginBottom: 8,
                }}
              />
            </div>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 900,
                color: 'black',
                lineHeight: 1.4,
                fontSize: 24,
              }}
            >
              축하해요!
            </div>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 900,
                color: 'black',
                lineHeight: 1.4,
                fontSize: 24,
              }}
            >
              <span style={{ color: '#EB4F47' }}>행운상품</span>을 받았어요!
            </div>
            <div
              style={{
                zIndex: 5,
                marginTop: 76,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img
                src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/73147d48-575a-4814-8e10-7ad928bff8b7.png'
                alt=''
                style={{
                  height: 156,
                  width: 156,
                  marginTop: -64,
                  marginBottom: 8,
                }}
              />
              <img
                src={`${winningItemInfo?.mainImageUri[0]}`}
                alt=''
                style={{
                  height: 100,
                  width: 100,
                  marginTop: -142,
                  marginBottom: 8,
                }}
              />
              <div
                style={{
                  fontFamily: 'GmarketSans',
                  fontWeight: 700,
                  color: 'white',
                }}
              >
                {winningItemInfo?.itemTitle}
              </div>
            </div>
            <div
              style={{
                backgroundColor: '#F5F5F5',
                width: '100%',
                height: '100%',
                marginTop: 20,
              }}
            >
              <div
                style={{
                  fontFamily: 'GmarketSans',
                  fontWeight: 700,
                  color: 'black',
                  lineHeight: 1.4,
                  marginTop: 8,
                  fontSize: 20,
                }}
              >
                <span style={{ color: '#EB4F47' }}>원하는 상품</span>이 나오지
                않았나요?
              </div>
              <div
                style={{
                  marginTop: 8,
                  fontFamily: 'GmarketSans',
                  fontWeight: 500,
                  color: 'black',
                  lineHeight: 1.4,
                }}
              >
                30초 미션을 수행하면
              </div>
              <div
                style={{
                  fontFamily: 'GmarketSans',
                  fontWeight: 500,
                  color: 'black',
                  lineHeight: 1.4,
                  marginBottom: 4,
                }}
              >
                재도전으로 상품을 변경할 기회를 드려요
              </div>
              <div
                style={{
                  fontFamily: 'GmarketSans',
                  fontWeight: 300,
                  color: 'black',
                  lineHeight: 1.4,
                  fontSize: 12,
                }}
              >
                *같은 상품이 당첨될 수도 있으며 재변경은 불가해요
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                {checkInMissionStatus !== 'clearMission' ? (
                  <>
                    <button
                      type='button'
                      style={{
                        border: 'none',
                        background: 'none',
                        zIndex: 5,
                        marginTop: 76,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginRight: -4,
                      }}
                      onClick={() => {
                        window.location.href = `#navigate.${JSON.stringify({
                          screen: 'DailyCheckInScreenB',
                          prop: { origin: 'luckyBoxEvent' },
                        })}`
                      }}
                    >
                      <img
                        src='/images/checkInMissionPreStatus.png'
                        alt=''
                        style={{
                          height: 140,
                          // width: 144,
                          marginTop: -64,
                          marginBottom: 8,
                        }}
                      />
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type='button'
                      style={{
                        border: 'none',
                        background: 'none',
                        zIndex: 5,
                        marginTop: 76,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginRight: -4,
                      }}
                      onClick={() => {}}
                    >
                      <img
                        src='/images/checkInMissionClearStatus.png'
                        alt=''
                        style={{
                          height: 140,
                          // width: 144,
                          marginTop: -64,
                          marginBottom: 8,
                        }}
                      />
                    </button>
                  </>
                )}
                {alfarmMissionStatus !== 'clearMission' ? (
                  <button
                    type='button'
                    style={{
                      border: 'none',
                      background: 'none',
                      zIndex: 5,
                      marginTop: 76,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginLeft: -4,
                    }}
                    onClick={async () => {
                      const ongoingAlfarmData =
                        await backendApis.getAlFarmUserData(token)

                      window.location.href = `#navigate.${JSON.stringify({
                        screen: 'gameTest',
                        prop: {
                          origin: 'luckyBoxEvent',
                          isAlreadyExist: !!ongoingAlfarmData?.data?._id,
                          itemType: ongoingAlfarmData?.data?.itemType,
                        },
                      })}`
                    }}
                  >
                    <img
                      src='/images/alfarmMissionPreStatus.png'
                      alt=''
                      style={{
                        height: 140,
                        // width: 144,
                        marginTop: -64,
                        marginBottom: 8,
                      }}
                    />
                  </button>
                ) : (
                  <button
                    type='button'
                    style={{
                      border: 'none',
                      background: 'none',
                      zIndex: 5,
                      marginTop: 76,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginLeft: -4,
                    }}
                  >
                    <img
                      src='/images/alfarmMissionClearStatus.png'
                      alt=''
                      style={{
                        height: 140,
                        // width: 144,
                        marginTop: -64,
                        marginBottom: 8,
                      }}
                    />
                  </button>
                )}
              </div>

              {/* 여기서 부터 회색 구간 끝 */}
            </div>
            <button
              type='button'
              style={{
                bottom: '0',
                right: '0',
                fontSize: 18,
                borderBottomLeftRadius: 20,
                borderBottomRightRadius: 20,
                height: 48,
                borderWidth: 0,
                background: '#FF8808',
                width: '100%',
                paddingTop: 20,
                paddingBottom: 40,
              }}
              onClick={() => {
                if (
                  checkInMissionStatus === 'clearMission' &&
                  alfarmMissionStatus === 'clearMission'
                ) {
                  alert.show(
                    `30초 미션을 완료하여 행운 상자를 다시 열 수 있어요!`,
                    {
                      closeCopy: '확인',
                    },
                  )
                  setShowLuckyBoxResultModal(false)
                } else if (isPurchasableStatus) {
                  window.location.href = `#toOrderMain.${JSON.stringify({
                    props: {
                      itemId: winningItemInfo?.itemId,
                      enteringComponent: 'luckyBoxEvent',
                      type: 'luckyBox',
                      invitorId,
                      eventPageMapping: 'luckyBoxEvent',
                    },
                  })}`
                } else {
                  alert.show(
                    `30초 미션을 완료하면 행운 상자를 변경할 수 있어요! 변경을 원하지 않을 경우 다시 행운상품 받기 버튼을 눌러주세요`,
                    {
                      closeCopy: '확인',
                    },
                  )
                  isPurchasableStatus = true
                }
              }}
            >
              <div
                style={{
                  color: 'white',
                  fontSize: 20,
                  fontWeight: 'bold',
                  paddingLeft: 8,
                  paddingRigtt: 8,
                }}
              >
                행운상품 받기
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default LuckyBoxResultModal
