import React, { useState } from 'react'
import commaNumber from 'comma-number'
import { useAlert } from 'react-alert'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'

const SupportFundExplainModal = ({ setShowSupportFundExplainModal }) => (
  <>
    <div
      style={{
        flex: 1,
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 3,
        backgroundColor: 'rgba(0,0,0,0.8)',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <button
        type='button'
        style={{
          width: 100,
          height: 100,
          backgroundColor: 'red',
          background: 'none',
          border: 'none',
          top: '-3.5%',
          right: '-5%',
          position: 'absolute',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          setShowSupportFundExplainModal(false)
        }}
      >
        <img
          style={{
            zIndex: 101,
            width: '36%',
            height: '36%',
            position: 'absolute',
          }}
          src='/images/closeCircleIcon.png'
          alt=''
        />
      </button>
      <div
        style={{
          backgroundColor: 'white',
          overflowY: 'scroll',
          width: '76vw',
          height: '84vh',
          borderRadius: 12,
          paddingBottom: 24,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            //   backgroundColor: 'red',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              fontFamily: 'GmarketSans',
              lineHeight: 1.3,
            }}
          >
            <div style={{ padding: 20, fontSize: 20, fontWeight: 700 }}>
              연말 지원금 이용 안내
            </div>
            <div
              style={{
                textAlign: 'left',
                //   backgroundColor: 'blue',
                width: '68vw',
              }}
            >
              <div>
                <div style={{ fontWeight: 500, paddingBottom: 6 }}>
                  1) 현금 인출
                </div>
                <div
                  style={{
                    fontSize: 14,
                  }}
                >
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 24시간 내에 5만원을 만들면 현금으로 출금할 수 있어요
                  </div>
                  <div
                    style={{
                      paddingLeft: 8,
                      marginBottom: 2,
                    }}
                  >
                    - 5만원 이상 적립되더라도 최대 5만원까지만 출금할 수 있어요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 출금은 영업일 기준 24시간 내에 진행돼요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 연말지원금 5만원은 1회만 받을 수 있어요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 출금 시에는 본인 인증이 필요해요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 출금 이력이 있는 분은 5만원을 모두 채우더라도 지원금을
                    받을 수 없어요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 다른 계정으로 시도하여도 본인인증 정보가 동일하면 지원금을
                    최대 1회만 받을 수 있어요
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{ fontWeight: 500, paddingBottom: 6, marginTop: 16 }}
                >
                  2) 5만원 채우기
                </div>
                <div
                  style={{
                    fontSize: 14,
                  }}
                >
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 친구를 초대하여 부족한 금액을 채울 수 있어요
                  </div>
                  <div
                    style={{
                      paddingLeft: 8,
                      marginBottom: 2,
                    }}
                  >
                    - 금액은 랜덤으로 지급돼요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 새친구를 초대하면 더 많은 금액을 채울 수 있어요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 친구에게 하루 3번 할인을 도와줄 수 있고, 오전 9시를
                    기준으로 다시 3번의 기회가 생겨요
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{ fontWeight: 500, paddingBottom: 6, marginTop: 16 }}
                >
                  3) 물주러 가기
                </div>
                <div
                  style={{
                    fontSize: 14,
                  }}
                >
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 한시간마다 올팜의 농작물에 물을 주고 금액을 채울 수 있어요
                  </div>
                  <div
                    style={{
                      paddingLeft: 8,
                      marginBottom: 2,
                    }}
                  >
                    - 물주기는 한시간에 한번만 진행할 수 있어요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 더블 클릭 등을 통해 한시간에 1번 초과로 수행하여 받은
                    금액은 무효화 될 수 있어요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 금액은 랜덤으로 지급돼요
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{ fontWeight: 500, paddingBottom: 6, marginTop: 16 }}
                >
                  4) 돈 뿌리기
                </div>
                <div
                  style={{
                    fontSize: 14,
                  }}
                >
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 친구에게 링크를 공유하여 최대 3번까지 돈을 줄 수 있어요
                  </div>
                  <div
                    style={{
                      paddingLeft: 8,
                      marginBottom: 2,
                    }}
                  >
                    - 금액은 랜덤으로 지급되며 새친구가 주는 돈은 금액이 더 커요
                  </div>
                  <div
                    style={{
                      paddingLeft: 8,
                      marginBottom: 2,
                    }}
                  >
                    - 친구 3명에게 돈을 준 이후에 나도 보상을 받을 수 있으며 단
                    1회만 가능해요
                  </div>
                  <div
                    style={{
                      paddingLeft: 8,
                      marginBottom: 2,
                    }}
                  >
                    - 1회 초과로 받은 보상 내역은 무효화 될 수 있어요
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{ fontWeight: 500, paddingBottom: 6, marginTop: 16 }}
                >
                  5) 그만하고 랜덤쿠폰 받기
                </div>
                <div
                  style={{
                    fontSize: 14,
                  }}
                >
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 첫 도전에 한하여 도전을 그만하고 랜덤쿠폰을 받을 수 있어요
                  </div>
                  <div
                    style={{
                      paddingLeft: 8,
                      marginBottom: 2,
                    }}
                  >
                    - 지급되는 쿠폰 금액은 랜덤으로 지급돼요
                  </div>
                  <div
                    style={{
                      paddingLeft: 8,
                      marginBottom: 2,
                    }}
                  >
                    - 지급되는 쿠폰은 내 정보 > 쿠폰함에서 확인 가능해요
                  </div>
                  <div
                    style={{
                      paddingLeft: 8,
                      marginBottom: 2,
                    }}
                  >
                    - 그만하기를 선택하면 모은 금액이 모두 사라지며 다시 바꿀 수
                    없어요
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{ fontWeight: 500, paddingBottom: 6, marginTop: 16 }}
                >
                  6) 위반사항
                </div>
                <div
                  style={{
                    fontSize: 14,
                  }}
                >
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 기술적 허점이나 허점을 이용하여 부정한 이익을 받는 경우
                  </div>
                  <div
                    style={{
                      paddingLeft: 8,
                      marginBottom: 2,
                    }}
                  >
                    - 인증되지 않은 계정, 부정한 계정 등 가입자 확인이 어려운
                    계정으로 이벤트에 참여하는 경우
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 탈퇴 후 재참여, 중복 클릭, 사기 행위, 계정 구매 등 부정한
                    방법으로 이벤트에 참여하는 경우
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{ fontWeight: 500, paddingBottom: 6, marginTop: 16 }}
                >
                  7) 유의사항
                </div>
                <div
                  style={{
                    fontSize: 14,
                  }}
                >
                  {/* <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 본 이벤트는 20세 이상의 고객만 참여가 가능해요
                  </div> */}

                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 가입한지 2시간 이내이며 올웨이즈의 타 이벤트 참여 이력이
                    없는 고객만 신규 고객으로 인정돼요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 이벤트 달성 후 72시간 내에 인출하여야 하며 시간 내에
                    인출하지 않는 경우 금액이 사라져요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 위반사항에 해당하는 경우 성공하더라도 취소될 수 있어요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 24시간 내 달성하지 못하는 경우 금액은 사라져요
                  </div>
                  <div
                    style={{
                      paddingLeft: 8,
                      marginBottom: 2,
                    }}
                  >
                    - 본 이벤트는 당사의 사정에 의해 진행 중인 고객님이 있어도
                    사전 고지 없이 변경 또는 조기종료 될 수 있어요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 문의는 내 정보 > 고객문의 > 1:1 실시간 상담하기에서 하실
                    수 있어요
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default SupportFundExplainModal
