import React, { useState } from 'react'
import backendApis from '../utils/backendApis'

const OnePlusOneSendHistoryModal = ({
  setShowOnePlusOneSendHistoryModal,
  token,
  guestInfosArray,
}) => {
  console.log(
    guestInfosArray?.filter((each) => each?.missionStatus === 'onGoing'),
    65465,
  )
  const OngoingMissionCardList = ({ data }) => (
    <>
      <div
        style={{
          width: '80vw',
          height: 80,
          borderRadius: 10,
          border: '1px solid rgba(99, 86, 91, 0.12)',
          margin: 12,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 2,
        }}
      >
        <div style={{ flex: 1 }}>
          <img
            style={{
              height: 90,
              opacity: 0.5,
            }}
            src={`${data?.mainImageUri}`}
            alt=''
          />
        </div>
        <div
          style={{
            height: 60,
            border: '1px solid rgba(64, 55, 58, 0.1)',
            marginRight: 4,
          }}
        />
        <div
          style={{
            flex: 3,
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            padding: 2,
          }}
        >
          <div style={{ fontSize: 14, fontWeight: 'bold' }}>
            {data?.mainTitle} {data?.subTitle}
          </div>
          <div style={{ fontSize: 12, marginTop: 4 }}>
            <div>
              <span style={{ backgroundColor: '#FEE952' }}>
                {data?.userName}
              </span>
              님이 연속 {data?.missionClearDaysNum}일 출석했어요
            </div>
            <div style={{ marginTop: 2 }}>
              연속 {data?.missionDaysNum - data?.missionClearDaysNum}일 더
              출석하면 상품을 드려요{' '}
              <span style={{ color: '#FF9106' }}>
                ({data?.missionClearDaysNum}/{data?.missionDaysNum})
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  const EndedMissionCardList = ({ data }) => (
    <>
      <div
        style={{
          width: '80vw',
          height: 80,
          borderRadius: 10,
          border: '1px solid rgba(99, 86, 91, 0.12)',
          margin: 12,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 2,
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            style={{
              height: 90,
              opacity: `${
                !data?.isItemReceived && data?.missionStatus === 'success'
                  ? 1
                  : 0.5
              }`,
            }}
            src={`${data?.mainImageUri}`}
            alt=''
          />
          {!(!data?.isItemReceived && data?.missionStatus === 'success') && (
            <>
              <div
                style={{
                  position: 'absolute',
                  zIndex: 3,
                }}
              >
                <img
                  alt=''
                  src={`${
                    data?.missionStatus === 'success'
                      ? '/images/missionSuccess.png'
                      : '/images/missionFail.png'
                  }`}
                  style={{ height: 40 }}
                />
              </div>
            </>
          )}
        </div>
        <div
          style={{
            height: 60,
            border: '1px solid rgba(64, 55, 58, 0.1)',
            marginRight: 4,
          }}
        />
        <div
          style={{
            flex: 3,
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            padding: 2,
          }}
        >
          <div style={{ fontSize: 14, fontWeight: 'bold' }}>
            {data?.mainTitle} {data?.subTitle}
          </div>
          <div style={{ fontSize: 12, marginTop: 4 }}>
            <div>
              <span style={{ backgroundColor: '#FEE952' }}>
                {data?.userName}
              </span>
              님이 미션에 {data?.missionStatus !== 'success' ? '실패' : '성공'}
              했어요
            </div>
            <div style={{ marginTop: 2 }}>
              {data?.missionStatus === 'success' && (
                <>
                  {data?.isItemReceived ? (
                    <>
                      <div>이미 상품을 받았어요</div>
                    </>
                  ) : (
                    <>
                      <button
                        type='button'
                        style={{
                          border: 'none',
                          background: 'none',
                          padding: 0,
                          margin: 0,
                          marginTop: 2,
                        }}
                        onClick={async () => {
                          window.location.href = `#toOrderMain.${JSON.stringify(
                            {
                              props: {
                                // dev 에서는 60edc235ffc0a087c1716690 production에서는 63b298e674f6779703d7e74d
                                itemId: data?.orderItemId,
                                enteringComponent: 'onePlusOne',
                                type: 'onePlusOne',
                                invitorId: null,
                                eventPageMapping: 'onePlusOneSender',
                              },
                            },
                          )}`
                          await backendApis.updateViralEngineInfo(
                            token,
                            'PUT',
                            {
                              viralEngineType: 'onePlusOneSender',
                              guestId: data?.userId,
                            },
                          )
                        }}
                      >
                        <div
                          style={{
                            color: '#FF4C00',
                            fontFamily: 'GmarketSans',
                            fontWeight: 'bold',
                            borderBottom: '1px solid #FF4C00',
                            fontSize: 16,
                          }}
                        >
                          상품 받으러 가기 >
                        </div>
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          zIndex: 3,
          backgroundColor: 'rgba(0,0,0,0.6)',
          width: '100%',
          height: '100%',
          fontFamily: 'GmarketSans',
        }}
      >
        <button
          type='button'
          style={{
            width: 100,
            height: 100,
            backgroundColor: 'red',
            background: 'none',
            border: 'none',
            top: '2%',
            right: '-1%',
            position: 'absolute',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => {
            setShowOnePlusOneSendHistoryModal(false)
          }}
        >
          <img
            style={{
              zIndex: 101,
              width: '36%',
              height: '36%',
              position: 'absolute',
            }}
            src='/images/closeIcon.png'
            alt=''
          />
        </button>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: 24,
            boxSizing: 'border-box',
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              overflowY: 'scroll',
              zIndex: 4,
              textAlign: 'center',
              paddingTop: 20,
              borderRadius: 20,
              border: 'none',
              width: '90vw',
              height: '72vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'absolute',
            }}
          >
            <div
              style={{
                backgroundColor: '#40373A',
                width: '90vw',
                position: 'absolute',
                height: 48,
                top: 0,
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff',
                fontWeight: 'bold',
                fontSize: 20,
              }}
            >
              <div> 내가 보낸 내역</div>
            </div>
            <div style={{ marginTop: 48 }}>
              <div
                style={{
                  fontWeight: 900,
                  fontSize: 20,
                  textAlign: 'left',
                  marginLeft: 12,
                }}
              >
                진행중인 내역(
                {
                  guestInfosArray?.filter(
                    (each) => each?.missionStatus === 'onGoing',
                  )?.length
                }
                )
              </div>
              <div
                style={{
                  fontWeight: 500,
                  fontSize: 12,
                  textAlign: 'left',
                  marginLeft: 12,
                  marginTop: 4,
                }}
              >
                상품을 받으려면 친구가 출석체크 미션을 완료해야 해요
              </div>
              {guestInfosArray
                ?.filter((each) => each?.missionStatus === 'onGoing')
                ?.map((elem) => (
                  <OngoingMissionCardList key={Math.random()} data={elem} />
                ))}
              <div
                style={{
                  fontWeight: 900,
                  fontSize: 20,
                  textAlign: 'left',
                  marginLeft: 12,
                  marginTop: 20,
                }}
              >
                종료된 내역(
                {
                  guestInfosArray?.filter(
                    (each) => each?.missionStatus !== 'onGoing',
                  )?.length
                }
                )
              </div>
              {guestInfosArray
                ?.filter((each) => each?.missionStatus !== 'onGoing')
                ?.map((elem) => (
                  <EndedMissionCardList key={Math.random()} data={elem} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OnePlusOneSendHistoryModal
