import React, { useState } from 'react'
import commaNumber from 'comma-number'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import { useAlert } from 'react-alert'

const SupportFundThrowOnboardingModal = ({
  setShowSupportFundThrowOnboardingModal,
  setShowSupportFundCloseNoticeModal,
  token,
  invitorId,
  setShowSupportFundGetMoneyModal,
  setDiscountPrice,
  userInfo,
  debounce,
}) => {
  const [isButtonDisable, setIsButtonDisable] = useState(false)
  const throwOnboardingMainButton = async () => {
    // 본인 인증 안되어 있는 경우 <- 12월 18일 이후로 부터 본인 인증
    // if (
    //   !userInfo?.selfAuthenticationInfo &&
    //   !userInfo?.selfAuthenticationInfoLogs &&
    //   new Date(userInfo?.createdAt) > new Date('2022-12-18T15:00:00')
    // ) {
    //   window.location.href = '#openAuthenticationModal.'
    //   return
    // }
    const result = await backendApis.getSupportFundInvitorTeamInfo(
      token,
      'GET',
      { invitorId },
    )
    if (result?.status === 200 && result?.data?.discountPrice) {
      setDiscountPrice(result?.data?.discountPrice)
      setShowSupportFundThrowOnboardingModal(false)
      setShowSupportFundGetMoneyModal(true)
    } else if (result?.data?.msg === 'already did 3 invite') {
      alert.show('친구가 뿌린 돈을 이미 다른 친구들이 모두 받았어요.')
      setShowSupportFundThrowOnboardingModal(false)
    } else if (result?.data?.msg === 'invitor and invitee are same') {
      alert.show('자신이 뿌린 돈을 자신이 받을 수는 없어요.')
      setShowSupportFundThrowOnboardingModal(false)
    } else if (result?.data?.msg === 'already invited') {
      alert.show('이미 친구가 뿌린 돈을 받았어요.')
      setShowSupportFundThrowOnboardingModal(false)
    } else {
      alert.show('알 수 없는 오류가 발생했어요. 다시 시도해주세요.')
      setShowSupportFundThrowOnboardingModal(false)
      setShowSupportFundCloseNoticeModal(true)
    }
  }

  const alert = useAlert()
  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          zIndex: 3,
          backgroundColor: 'rgba(0,0,0,0.8)',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {' '}
        <button
          type='button'
          style={{
            width: 100,
            height: 100,
            backgroundColor: 'red',
            background: 'none',
            border: 'none',
            top: '14%',
            right: '1%',
            position: 'absolute',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => {
            setShowSupportFundThrowOnboardingModal(false)
          }}
        >
          <img
            style={{
              zIndex: 101,
              width: '36%',
              height: '36%',
              position: 'absolute',
            }}
            src='/images/closeCircleIcon.png'
            alt=''
          />
        </button>
        <div
          style={{
            backgroundColor: 'white',
            width: '76vw',
            height: '32vh',
            borderRadius: 12,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              // backgroundColor: 'red',
            }}
          >
            <img
              alt=''
              style={{ width: 140, marginTop: -52 }}
              src='/images/SupportFundWithdrawSmallImage.png'
            />
            <div
              style={{
                textAlign: 'center',
                fontFamily: 'GmarketSans',
                fontWeight: 700,
                lineHeight: 1.3,
              }}
            >
              <div>친구가 돈을 보냈어요</div>
              <div>금액을 확인해보세요</div>
            </div>
            <div
              style={{
                textAlign: 'center',
                fontFamily: 'GmarketSans',
                fontSize: 12,
                color: '#79765F',
                marginTop: 8,
                lineHeight: 1.3,
              }}
            >
              <div>
                돈을 다 채우면 <span style={{ fontWeight: 900 }}>5만원</span>을
                받아요
              </div>
              <div>친구와 함께 연말지원금을 받아보세요!</div>
            </div>
            <div
              style={{
                backgroundColor: '#EB4F47',
                display: 'flex',
                justifyContent: 'center',
                borderRadius: 8,
                margin: 8,
                marginTop: 16,
                marginBottom: 12,
                width: '72%',
              }}
            >
              <button
                type='button'
                style={{
                  backgroundColor: '#EB4F47',
                  width: '100%',
                  height: '100%',
                  borderRadius: 8,
                  padding: 8,
                  paddingTop: 12,
                  paddingBottom: 12,
                  background: 'none',
                  border: 'none',
                  color: 'white',
                  fontFamily: 'GmarketSans',
                  fontWeight: 900,
                  fontSize: 16,
                }}
                onClick={() => {
                  if (isButtonDisable) {
                    return
                  }
                  setIsButtonDisable(true)
                  debounce(throwOnboardingMainButton, 500)
                }}
              >
                친구가 보낸 돈 받기
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SupportFundThrowOnboardingModal
