import React, { useState, useEffect } from 'react'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import Layout from '../components/layout'
import Header from '../components/header'
import Notice from '../components/Notice'
import BlackTextTimer from '../components/BlackTextTimer'
import commaNumber from 'comma-number'
import { useAlert } from 'react-alert'
import useDebounce from '../components/useDebounce'
import CoffeeGiftOldUserOnboardingModal from '../modals/CoffeeGiftOldUserOnboardingModal'
import CoffeeGiftSendNudgingModal from '../modals/CoffeeGiftSendNudgingModal'
import CoffeeGiftCloseNoticeModal from 'src/modals/CoffeeGiftCloseNoticeModal'

let isButtonDisable = false
let hidden
let visibilityChange
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

function AlfarmCoffeeGift() {
  const alert = useAlert()
  const token = useSearchParam('token')
  const isFrom = useSearchParam('isFrom')
  const alwayzInvitorId = useSearchParam('alwayzInvitorId')
  const code = useSearchParam('code')
  const [isAlreadyExist, setIsAlreadyExist] = useState()
  const [coffeeGiftReceiverInfo, setCoffeeGiftReceiverInfo] = useState()
  const [
    showCoffeeGiftOldUserOnboardingModal,
    setShowCoffeeGiftOldUserOnboardingModal,
  ] = useState(false)
  const [showCoffeeGiftSendNudgingModal, setShowCoffeeGiftSendNudgingModal] =
    useState(false)
  const [showCoffeeGiftCloseNoticeModal, setShowCoffeeGiftCloseNoticeModal] =
    useState(true)
  const [alfarmData, setAlfarmData] = useState()

  const getCoffeeGiftReceiverInfo = async () => {
    const userInfo = await backendApis.getUserBasicInfoWithAuthentication(token)
    if (isFrom) {
      const ongoingAlfarmData = await backendApis.getAlFarmUserData(token)
      if (ongoingAlfarmData?.data?._id) {
        setAlfarmData(ongoingAlfarmData?.data)
        setIsAlreadyExist(true)
        setShowCoffeeGiftSendNudgingModal(true)
      } else {
        setAlfarmData(ongoingAlfarmData?.data)
        setIsAlreadyExist(false)
        setShowCoffeeGiftSendNudgingModal(true)
      }
    }
    const result = await backendApis.getCoffeeGiftReceiverInfo(token)
    if (result?.status === 200 && result?.data?._id) {
      setCoffeeGiftReceiverInfo(result?.data)
    } else if (
      userInfo?.status !== 200 ||
      userInfo?.data?.phoneNumber === '' ||
      userInfo?.data?.userName === ''
    ) {
      window.location.href = '#openLoginModal.'
    } else {
    }
  }
  useEffect(() => {
    getCoffeeGiftReceiverInfo()
  }, [])

  useEffect(() => {
    document.addEventListener(
      visibilityChange,
      (visibility) => {
        const now = Date.now()
        if (document.visibilityState === 'visible') {
          getCoffeeGiftReceiverInfo()
        }
      },
      false,
    )
  }, [token])

  const alfarmMissionButton = async () => {
    const userInfo = await backendApis.getUserBasicInfoWithAuthentication(token)
    // 로그인 안되어 있는 경우
    if (
      userInfo?.status !== 200 ||
      userInfo?.data?.phoneNumber === '' ||
      userInfo?.data?.userName === ''
    ) {
      window.location.href = '#openLoginModal.'
      return
    }
    const ongoingAlfarmData = await backendApis.getAlFarmUserData(token)
    if (ongoingAlfarmData?.data?._id) {
      setAlfarmData(ongoingAlfarmData?.data)
      setIsAlreadyExist(true)
    } else {
      setIsAlreadyExist(false)
    }
    if (coffeeGiftReceiverInfo?._id) {
      if (coffeeGiftReceiverInfo?.alfarmMissionDone === true) {
        alert.show('이미 미션을 완료하였어요. 무료 커피를 받아보세요!')
      } else {
        if (ongoingAlfarmData?.status !== 200) {
          isButtonDisable = false
          return
        }
        window.location.href = `#navigate.${JSON.stringify({
          screen: 'gameTest',
          prop: {
            origin: 'alfarmCoffeeGift',
            isAlreadyExist: !!ongoingAlfarmData?.data?._id,
            itemType: ongoingAlfarmData?.data?.itemType,
          },
        })}`
        await backendApis.setCoffeeGiftAlfarmMission(token)
      }
    } else {
      setShowCoffeeGiftOldUserOnboardingModal(true)
    }
  }

  const coffeeReceiveButton = async () => {
    const userInfo = await backendApis.getUserBasicInfoWithAuthentication(token)
    // 로그인 안되어 있는 경우
    if (
      userInfo?.status !== 200 ||
      userInfo?.data?.phoneNumber === '' ||
      userInfo?.data?.userName === ''
    ) {
      window.location.href = '#openLoginModal.'
      return
    }
    const ongoingAlfarmData = await backendApis.getAlFarmUserData(token)
    if (ongoingAlfarmData?.data?._id) {
      setAlfarmData(ongoingAlfarmData?.data)
      setIsAlreadyExist(true)
    } else {
      setIsAlreadyExist(false)
    }
    if (coffeeGiftReceiverInfo?._id) {
      if (coffeeGiftReceiverInfo?.isItemReceived === true) {
        alert.show(
          '이미 커피 주문을 완료하였어요. 문제가 발생한 경우 고객센터로 문의를 주세요.',
        )
      } else if (coffeeGiftReceiverInfo?.alfarmMissionDone === true) {
        await backendApis.updateCoffeeGiftSenderFertSet(token, 'PUT', {
          invitorId: alwayzInvitorId,
        })
        window.location.href = `#toOrderMain.${JSON.stringify({
          props: {
            // dev 에서는 60edc235ffc0a087c1716690 production에서는 63b298e674f6779703d7e74d
            itemId: '63b298e674f6779703d7e74d',
            enteringComponent: 'coffeeGift',
            type: 'coffeeGift',
            invitorId: null,
            eventPageMapping: 'alfarmCoffeeGift',
          },
        })}`
      } else {
        if (ongoingAlfarmData?.status !== 200) {
          isButtonDisable = false
          return
        }
        window.location.href = `#navigate.${JSON.stringify({
          screen: 'gameTest',
          prop: {
            origin: 'alfarmCoffeeGift',
            isAlreadyExist: !!ongoingAlfarmData?.data?._id,
            itemType: ongoingAlfarmData?.data?.itemType,
          },
        })}`
        await backendApis.setCoffeeGiftAlfarmMission(token)
      }
    } else {
      setShowCoffeeGiftOldUserOnboardingModal(true)
    }
  }

  return (
    <>
      <Layout>
        <Header title='커피선물 이벤트' />
        <div
          style={{
            width: '100%',
            height: '100vh',
            backgroundColor: '#F2EADA',
            marginBottom: 10,
            fontFamily: 'Maplestory',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ fontSize: 32, marginTop: 68, color: '#704410' }}>
            5초 미션을 수행하면
          </div>
          <div
            style={{
              fontSize: 32,
              marginBottom: 32,
              fontWeight: 'bold',
              color: '#704410',
            }}
          >
            공짜 커피를 받을 수 있어요
          </div>
          <div
            style={{
              backgroundImage: "url('/images/coffeeGiftCenterImage.png')",
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '60%',
              height: 128,
              marginTop: 8,
            }}
          />
          <button
            type='button'
            style={{
              width: '50%',
              border: 'none',
              background: 'none',
              display: 'flex',
              justifyContent: 'center',
              marginTop: 16,
            }}
            onClick={() => {
              alfarmMissionButton()
            }}
          >
            {coffeeGiftReceiverInfo?.alfarmMissionDone === true ? (
              <>
                <div
                  style={{
                    backgroundImage:
                      "url('/images/coffeeGiftClearMission.png')",
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: 200,
                  }}
                />
              </>
            ) : (
              <>
                <div
                  style={{
                    backgroundImage: "url('/images/coffeeGiftPreMission.png')",
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: 200,
                  }}
                />
              </>
            )}
          </button>
          <div style={{ marginTop: 16, color: '#98896C' }}>
            *올웨이즈 신규 고객만 공짜 커피를 받을 수 있어요
          </div>
          <button
            type='button'
            style={{
              width: '100%',
              border: 'none',
              background: 'none',
              display: 'flex',
              justifyContent: 'center',
              marginTop: 12,
            }}
            onClick={() => {
              coffeeReceiveButton()
            }}
          >
            {coffeeGiftReceiverInfo?.alfarmMissionDone === true ? (
              <>
                <div
                  style={{
                    backgroundImage:
                      "url('/images/coffeeGiftClearMissionButton.png')",
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    width: '90%',
                    height: 120,
                  }}
                />
              </>
            ) : (
              <>
                <div
                  style={{
                    backgroundImage:
                      "url('/images/coffeeGiftPreMissionButton.png')",
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    width: '90%',
                    height: 120,
                  }}
                />
              </>
            )}
          </button>
        </div>
        {showCoffeeGiftOldUserOnboardingModal && (
          <CoffeeGiftOldUserOnboardingModal
            setShowCoffeeGiftOldUserOnboardingModal={
              setShowCoffeeGiftOldUserOnboardingModal
            }
            alfarmData={alfarmData}
            token={token}
          />
        )}
        {showCoffeeGiftCloseNoticeModal && (
          <CoffeeGiftCloseNoticeModal
            setShowCoffeeGiftCloseNoticeModal={
              setShowCoffeeGiftCloseNoticeModal
            }
            alfarmData={alfarmData}
            token={token}
          />
        )}
        {showCoffeeGiftSendNudgingModal && (
          <CoffeeGiftSendNudgingModal
            setShowCoffeeGiftSendNudgingModal={
              setShowCoffeeGiftSendNudgingModal
            }
            alfarmData={alfarmData}
            token={token}
          />
        )}
      </Layout>
    </>
  )
}
export default AlfarmCoffeeGift
