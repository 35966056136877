import React, { useState } from 'react'
import { useAlert } from 'react-alert'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'

const LuckyBoxSelectModal = ({
  modalInfo,
  setModalInfo,
  friendsList = [],
  setFriendsList = '',
  luckyItems,
  userId,
}) => {
  const { visible } = modalInfo
  if (!visible) return null
  const docId = modalInfo?.data?._id
  const receiverName = modalInfo?.data?.receiverName
  const receiverPhoneNumber = modalInfo?.data?.receiverPhoneNumber
  const alert = useAlert()
  const token = useSearchParam('token')
  const [selectedItemList, setSelectedItemList] = useState([])
  const ItemComponent = ({ itemInfo, tier }) => {
    const { _id: itemId, itemTitle, mainImageUri: itemImageUri } = itemInfo
    const selectedIds = selectedItemList?.map((each) => each?._id)
    return (
      <>
        <div
          style={{
            width: '30%',
            height: '100%',
          }}
        >
          <button
            type='button'
            style={{ background: 'none', border: 'none' }}
            onClick={() => {
              const otherTierItemList = luckyItems
                ?.filter((elem) => elem?.tier !== tier)
                ?.filter((each) =>
                  selectedItemList
                    ?.map((elem) => elem?._id)
                    ?.includes(each?._id),
                )
              setSelectedItemList(
                [...otherTierItemList, itemInfo]?.sort(
                  (a, b) => a.tier - b.tier,
                ),
              )
            }}
          >
            <div
              style={{
                width: 16,
                height: 16,
                border: '#ebebeb',
                // borderWidth: 1.5,
              }}
            >
              <div
                style={{
                  width: 16,
                  height: 16,
                  border: 'none',
                  backgroundColor: '#ebebeb',
                  borderRadius: 8,
                  marginLeft: 8,
                  marginTop: 8,
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxSizing: 'border-box',
                  padding: 4,
                }}
              >
                {selectedIds && selectedIds?.includes(itemId) && (
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: 'red',
                    }}
                  />
                )}
              </div>
            </div>
            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
                height: '70%',
                marginTop: 8,
              }}
            >
              <img
                src={itemImageUri[0]}
                alt=''
                style={{ height: 72, width: 72 }}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                fontFamily: 'GmarketSans',
                fontWeight: 300,
                fontSize: 12,
                color: 'black',
                marginTop: 4,
              }}
            >
              {itemTitle}
            </div>
          </button>
        </div>
      </>
    )
  }

  const ItemGroupComponent = ({ luckyBoxItemList, tier }) => (
    <>
      <div
        style={{
          backgroundColor: 'white',
          width: '80vw',
          height: '32vh',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 8,
          margin: 8,
          padding: 2,
        }}
      >
        <div
          style={{
            textAlign: 'center',
            borderWidth: 1.5,
            fontFamily: 'GmarketSans',
            fontWeight: 700,
            marginTop: 12,
          }}
        >
          <span style={{ color: '#E63423' }}> {tier}순위</span> 선물
        </div>
        <div
          style={{
            borderBottom: '#ebebeb solid',
            borderBottomWidth: 1.5,
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 8,
          }}
        />
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 16,
          }}
        >
          {luckyBoxItemList?.map((each) => (
            <ItemComponent itemInfo={each} tier={tier} />
          ))}
        </div>
      </div>
    </>
  )
  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          zIndex: 100,
          backgroundColor: 'rgba(0,0,0,0.8)',
          width: '100%',
          height: '100%',
        }}
      >
        <button
          type='button'
          style={{
            width: 100,
            height: 100,
            backgroundColor: 'red',
            background: 'none',
            border: 'none',
            top: '2%',
            right: '-2%',
            position: 'absolute',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => {
            setModalInfo({ visible: false, data: {} })
          }}
        >
          <img
            style={{
              zIndex: 101,
              width: '36%',
              height: '36%',
              position: 'absolute',
            }}
            src='/images/closeIcon.png'
            alt=''
          />
        </button>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: 24,
            boxSizing: 'border-box',
          }}
        >
          <div
            style={{
              backgroundColor: '#FF8808',
              zIndex: 2,
              // top: '24%',
              textAlign: 'center',
              paddingTop: 24,
              paddingBottom: 18,
              borderRadius: 18,
              width: '90vw',
              height: '72vh',
              overflowY: 'scroll',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 700,
                color: '#FFF',
                lineHeight: 1.4,
              }}
            >
              {receiverName ? `${receiverName?.slice(0, 10)}님께` : '나에게'}
              <br />
              전할 선물을 골라 보세요!
            </div>
            {[1, 2, 3, 4, 5]?.map((each) => (
              <ItemGroupComponent
                luckyBoxItemList={luckyItems?.filter((el) => el?.tier === each)}
                tier={each}
              />
            ))}
            <button
              type='button'
              style={{
                fontSize: 18,
                borderRadius: 30,
                height: 48,
                borderWidth: 0,
                background: '#FFF',
                width: '50%',
                padding: 8,
                marginTop: 16,
              }}
              onClick={async () => {
                if (selectedItemList?.length !== 5) {
                  alert.show('1~5순위 선물을 모두 하나씩 선택해 주세요!', {
                    closeCopy: '확인',
                  })
                  return
                }
                if (docId) {
                  const result = await backendApis.updateLuckyBoxSendInfo(
                    token,
                    'PUT',
                    { docId, selectedItemList },
                  )
                  if (result?.status === 200) {
                    window.location.href = `#sendLuckyBox.${JSON.stringify({
                      docId,
                      receiverName,
                      receiverPhoneNumber,
                      selectedItemList,
                    })}`
                    setFriendsList(
                      friendsList.map((each) => {
                        if (each?._id.toString() === docId.toString()) {
                          return {
                            ...each,
                            isSent: true,
                            sentAt: new Date(),
                          }
                        }
                        return each
                      }),
                    )
                    setModalInfo({ visible: false, data: {} })
                  }
                } else {
                  const result = await backendApis.updateSelfLuckyBox(
                    token,
                    'PUT',
                    { selectedItemList },
                  )
                  if (result?.status === 200) {
                    window.location.href = `/LuckyBoxEventInvitation/?token=${token}&documentId=${userId}&userId=${userId}&alwayzInvitorId=${userId}&randomString=${Math.random()
                      .toString(36)
                      .substr(2, 8)}`
                    setModalInfo({ visible: false, data: {} })
                  }
                }
              }}
            >
              <div
                style={{
                  color: '#FF8808',
                  fontSize: 18,
                  fontWeight: 'bold',
                }}
              >
                선물 보내기
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default LuckyBoxSelectModal
