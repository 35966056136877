import React, { useState, useEffect } from 'react'
import { useSearchParam } from 'react-use'
import Layout from '../components/layout'
import Header from '../components/header'
import Footer from '../components/footer'
import backendApis from '../utils/backendApis'
import LuckyBoxCloseNoticeModal from '../modals/LuckyBoxCloseNoticeModal'

function luckyBoxEventIntro() {
  const token = useSearchParam('token')
  const [randomString, setRandomString] = useState(
    Math.random().toString(36).substr(2, 8),
  )
  const [luckyBoxItemTotalList, setLuckyBoxItemTotalList] = useState([])

  const initializeLuckyBoxItemTotalListInfo = async () => {
    const result = await backendApis.getLuckyBoxItemTotalListInfo(token, 'GET')
    if (result?.status === 200) {
      setLuckyBoxItemTotalList(result?.data)
    }
  }
  const [showLuckyBoxCloseNoticeModal, setShowLuckyBoxCloseNoticeModal] =
    useState(false)

  useEffect(() => {
    initializeLuckyBoxItemTotalListInfo()
  }, [])

  const ItemComponent = ({ itemTitle, mainImageUri, tier }) => (
    <>
      <div
        style={{
          width: '30%',
          height: '100%',
        }}
      >
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            height: '70%',
            marginTop: 8,
          }}
        >
          <img src={mainImageUri} alt='' style={{ height: 72, width: 72 }} />
        </div>
        <div
          style={{
            textAlign: 'center',
            fontFamily: 'GmarketSans',
            fontWeight: 300,
            fontSize: 12,
            color: 'black',
            marginTop: 8,
          }}
        >
          {itemTitle}
        </div>
      </div>
    </>
  )

  const ItemGroupComponent = ({ luckyBoxItemList, tier }) => (
    <>
      <div
        style={{
          backgroundColor: 'white',
          width: '90vw',
          height: '20vh',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 8,
          margin: 12,
          padding: 2,
        }}
      >
        <div
          style={{
            textAlign: 'center',
            borderWidth: 1.5,
            fontFamily: 'GmarketSans',
            fontWeight: 700,
            marginTop: 8,
          }}
        >
          <span style={{ color: '#E63423' }}> {tier}순위</span> 선물
        </div>
        <div
          style={{
            borderBottom: '#ebebeb solid',
            borderBottomWidth: 1.5,
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 4,
          }}
        />
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center',
            marginTop: 4,
          }}
        >
          {luckyBoxItemList?.map((each) => (
            <ItemComponent
              itemTitle={each?.itemTitle}
              mainImageUri={each?.mainImageUri[0]}
              tier={tier}
            />
          ))}
        </div>
      </div>
    </>
  )

  return (
    <Layout>
      <Header title='행운상자 선물하기' />
      <div style={{ overflowY: 'scroll', height: '93%' }}>
        <div
          style={{
            backgroundColor: 'rgba(0,0,0,0.8)',
            padding: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button
            type='button'
            style={{
              textAlign: 'center',
              color: 'white',
              fontFamily: 'GmarketSans',
              fontWeight: 700,
              backgroundColor: '#E63323',
              padding: 8,
              paddingLeft: 16,
              paddingRight: 16,
              border: 'none',
              borderRadius: 4,
              fontSize: 16,
            }}
            onClick={() => setShowLuckyBoxCloseNoticeModal(true)}
          >
            행운상자 종료 안내 (12월 16일 종료)
          </button>
        </div>

        <div
          style={{
            display: 'flex',
            height: '188vh',
            width: '100vw',
            background:
              'linear-gradient(180deg, #f67815 0%, #f98611 3.65%, #E63323 94.79%, #e83b21 100%)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            // marginBottom: 64,
          }}
        >
          <div style={{ height: 120, marginTop: 28, marginBottom: 8 }}>
            <div
              style={{
                width: '100vw',
                height: 36,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                  paddingTop: 8,
                  paddingBottom: 8,
                  paddingLeft: 16,
                  paddingRight: 16,
                  borderRadius: 24,
                  backgroundColor: '#ED531C',
                  color: 'white',
                  fontFamily: 'GmarketSans',
                  marginBottom: 8,
                }}
              >
                꽝이 없어요!
              </div>
            </div>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 900,
                fontSize: 32,
                color: 'white',
                textAlign: 'center',
              }}
            >
              행운상자 <span style={{ fontWeight: 500 }}>골라담기</span>
            </div>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 500,
                fontSize: 14,
                color: 'white',
                textAlign: 'center',
                paddingTop: 4,
                paddingBottom: 2,
              }}
            >
              친구에게 보낼 행운 상자에 상품을 직접 담아보세요
            </div>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 500,
                fontSize: 14,
                color: 'white',
                textAlign: 'center',
              }}
            >
              선택한 상품 중 1개를
              <span
                style={{
                  fontWeight: 900,
                  marginLeft: 2,
                  paddingLeft: 2,
                  paddingRight: 2,
                  backgroundColor: '#ED531C',
                }}
              >
                100% 확률
              </span>
              로 보내드려요
            </div>
          </div>
          <img
            src='/images/LuckyBox.png'
            alt=''
            style={{ height: 144, width: 144, marginTop: 8, marginBottom: 8 }}
          />
          <button
            type='button'
            style={{
              margin: 16,
              paddingTop: 12,
              paddingBottom: 12,
              paddingLeft: 20,
              paddingRight: 20,
              borderRadius: 8,
              fontFamily: 'GmarketSans',
              fontWeight: 700,
              border: 'none',
              fontSize: 20,
              color: 'white',
              textAlign: 'center',
              backgroundColor: '#E63323',
            }}
            onClick={() => {
              window.location.href = `#uploadContacs`
            }}
          >
            행운상자 선물하러 가기
          </button>
          <div>
            <ItemGroupComponent
              luckyBoxItemList={luckyBoxItemTotalList?.filter(
                (each) => each?.tier === 1,
              )}
              tier={1}
            />
            <ItemGroupComponent
              luckyBoxItemList={luckyBoxItemTotalList?.filter(
                (each) => each?.tier === 2,
              )}
              tier={2}
            />
            <ItemGroupComponent
              luckyBoxItemList={luckyBoxItemTotalList?.filter(
                (each) => each?.tier === 3,
              )}
              tier={3}
            />
            <ItemGroupComponent
              luckyBoxItemList={luckyBoxItemTotalList?.filter(
                (each) => each?.tier === 4,
              )}
              tier={4}
            />
            <ItemGroupComponent
              luckyBoxItemList={luckyBoxItemTotalList?.filter(
                (each) => each?.tier === 5,
              )}
              tier={5}
            />
            <div
              style={{
                fontSize: 12,
                marginTop: 16,
                marginBottom: 64,
                fontFamily: 'GmarketSans',
                fontWeight: 500,
                color: 'white',
                lineHeight: 1.4,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <button
                type='button'
                style={{
                  margin: 16,
                  paddingTop: 12,
                  paddingBottom: 12,
                  paddingLeft: 20,
                  paddingRight: 20,
                  borderRadius: 8,
                  fontFamily: 'GmarketSans',
                  fontWeight: 700,
                  border: 'none',
                  fontSize: 20,
                  color: 'white',
                  textAlign: 'center',
                  backgroundColor: '#FF8808',
                }}
                onClick={() => {
                  window.location.href = `#uploadContacs`
                }}
              >
                행운상자 선물하러 가기
              </button>
              <div style={{ textAlign: 'center' }}>
                연락처를 통해 친구에게 선물하려면
              </div>
              <div style={{ textAlign: 'center' }}>
                개인정보 수집 및 이용에 동의가 필요해요
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer
        title='동의하고 행운상자 선물하기'
        backgroundColor='#FFA13D'
        onClick={() => {
          window.location.href = `#uploadContacs`
        }}
      /> */}
      {showLuckyBoxCloseNoticeModal && (
        <LuckyBoxCloseNoticeModal
          setShowLuckyBoxCloseNoticeModal={setShowLuckyBoxCloseNoticeModal}
          token={token}
        />
      )}
    </Layout>
    // <Layout>
    //   <Header title='행운상자 선물하기' />
    //   <div
    //     style={{
    //       display: 'flex',
    //       justifyContent: 'center',
    //       height: '100vh',
    //       width: '100vw',
    //       backgroundImage: "url('/images/LuckyBoxIntroBackgroundImage.png')",
    //       backgroundRepeat: 'no-repeat',
    //       backgroundSize: 'cover',
    //       backgroundPosition: 'center',
    //     }}
    //   />
    //   <Footer
    //     title='행운상자 선물하기'
    //     clickable
    //     backgroundColor='#FFA13D'
    //     onClick={() => {
    //       window.location.href = `/LuckyBoxEventSelectItem/?token=${token}&randomString=${Math.random()
    //         .toString(36)
    //         .substr(2, 8)}`
    //     }}
    //   />
    // </Layout>
  )
}
export default luckyBoxEventIntro
