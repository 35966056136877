import React, { useState, useEffect } from 'react'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import Layout from '../components/layout'
import Header from '../components/header'
import Notice from '../components/Notice'
import BlackTextTimer from '../components/BlackTextTimer'
import commaNumber from 'comma-number'
import { useAlert } from 'react-alert'
import useDebounce from '../components/useDebounce'
import SupportFundWithdrawModal from '../modals/SupportFundWithdrawModal'
import SupportFundGetMoneyModal from '../modals/SupportFundGetMoneyModal'
import SupportFundInviteOnboardingModal from '../modals/SupportFundInviteOnboardingModal'
import SupportFundThrowOnboardingModal from '../modals/SupportFundThrowOnboardingModal'
import SupportFundThrowModal from '../modals/SupportFundThrowModal'
import SupportFundStopModal from '../modals/SupportFundStopModal'
import SupportFundExplainModal from '../modals/SupportFundExplainModal'
import SupportFundEmergencyNoticeModal from '../modals/SupportFundEmergencyNoticeModal'
import SupportFundCloseNoticeModal from '../modals/SupportFundCloseNoticeModal'

let isButtonDisable = false
let isModalDisable = false
let hidden
let visibilityChange
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

const userBadge = (userType, isNewUser) => {
  if (isNewUser && userType) {
    return (
      <div
        style={{
          backgroundColor: '#EE3E3E',
          color: 'white',
          borderRadius: 4,
          fontSize: 12,
          boxSizing: 'border-box',
          padding: 4,
          fontWeight: 'bold',
          marginLeft: 12,
        }}
      >
        새로운 친구
      </div>
    )
  }

  if (userType === 2) {
    return (
      <div
        style={{
          backgroundColor: '#EE3E3E',
          // height: 20,
          color: 'white',
          borderRadius: 4,
          fontSize: 12,
          boxSizing: 'border-box',
          padding: 4,
          fontWeight: 'bold',
          marginLeft: 4,
        }}
      >
        새로운 친구
      </div>
    )
  }
  if (userType === 3) {
    return (
      <div
        style={{
          backgroundColor: '#C2185B',
          // height: 20,
          color: 'white',
          borderRadius: 4,
          fontSize: 12,
          boxSizing: 'border-box',
          padding: 4,
          fontWeight: 'bold',
          marginLeft: 4,
        }}
      >
        돌아온 친구
      </div>
    )
  }
  if (userType === 4) {
    return (
      <div
        style={{
          backgroundColor: '#09AF00',
          // height: 20,
          color: 'white',
          borderRadius: 4,
          fontSize: 12,
          boxSizing: 'border-box',
          padding: 4,
          fontWeight: 'bold',
          marginLeft: 4,
        }}
      >
        행운의 친구
      </div>
    )
  }
}

function SupportFundEvent() {
  const alert = useAlert()
  const debounce = useDebounce()
  const token = useSearchParam('token')
  const alwayzInvitorId = useSearchParam('alwayzInvitorId')
  const code = useSearchParam('code')
  const [isAlreadyExist, setIsAlreadyExist] = useState()
  const [supportFundTeamInfo, setSupportFundTeamInfo] = useState()
  const [supportFundUserInvitedInfos, setSupportFundUserInvitedInfos] =
    useState(0)
  const [showSupportFundWithdrawModal, setShowSupportFundWithdrawModal] =
    useState(false)
  const [showSupportFundGetMoneyModal, setShowSupportFundGetMoneyModal] =
    useState(false)
  const [
    showSupportFundInviteOnboardingModal,
    setShowSupportFundInviteOnboardingModal,
  ] = useState(false)
  const [
    showSupportFundThrowOnboardingModal,
    setShowSupportFundThrowOnboardingModal,
  ] = useState(false)
  const [showSupportFundThrowModal, setShowSupportFundThrowModal] =
    useState(false)
  const [showSupportFundStopModal, setShowSupportFundStopModal] =
    useState(false)
  const [showSupportFundExplainModal, setShowSupportFundExplainModal] =
    useState(false)
  const [
    showSupportFundEmergencyNoticeModal,
    setShowSupportFundEmergencyNoticeModal,
  ] = useState(false)
  const [showSupportFundCloseNoticeModal, setShowSupportFundCloseNoticeModal] =
    useState(false)
  const [discountPrice, setDiscountPrice] = useState()
  const [userInfo, setUserInfo] = useState()
  const [isAlfarmButtonDisable, setIsAlfarmButtonDisable] = useState(false)
  const [isThrowButtonDisable, setIsThrowButtonDisable] = useState(false)

  const getSupportFundTeamInfo = async () => {
    // 토큰의 user document가 가입이 안되어 있는 경우
    const userInfo = await backendApis.getUserBasicInfoWithAuthentication(token)
    // 로그인 안되어 있는 경우
    if (
      userInfo?.status !== 200 ||
      userInfo?.data?.phoneNumber === '' ||
      userInfo?.data?.userName === ''
    ) {
      window.location.href = '#openLoginModal.'
      return
    }
    setUserInfo(userInfo?.data)

    const result = await backendApis.getSupportFundTeamInfo(token, 'GET')
    if (result?.status === 200) {
      setSupportFundTeamInfo(result?.data)
      if (result?.data?.guestUserInfosArray?.length === 1) {
        // setShowSupportFundWithdrawModal(true)
      }
    } else {
      setShowSupportFundCloseNoticeModal(true)
      // const tempResult = await backendApis.createSupportFundTeam(
      //   token,
      //   'POST',
      //   {
      //     supportFundType: 'yearEndSupportFund',
      //   },
      // )
      // setSupportFundTeamInfo(tempResult?.data)
      // if (tempResult?.data?.guestUserInfosArray?.length === 1) {
      //   setShowSupportFundWithdrawModal(true)
      // }
    }

    if (
      code &&
      alwayzInvitorId !== 'undefined' &&
      alwayzInvitorId !== 'cashwark' &&
      !isModalDisable
    ) {
      const invitedInfosResult =
        await backendApis.getSupportFundUserInvitedInfos(token, 'GET')
      if (
        invitedInfosResult?.status === 200 &&
        invitedInfosResult?.data?.length > 0
      ) {
        setSupportFundUserInvitedInfos(invitedInfosResult?.data?.length)
      }
      if (code === 'supportFundThrow') {
        isModalDisable = true
        setShowSupportFundThrowOnboardingModal(true)
      } else if (code === 'supportFundInvite') {
        isModalDisable = true
        setShowSupportFundInviteOnboardingModal(true)
      } else {
      }
    }
  }

  useEffect(() => {
    getSupportFundTeamInfo()
  }, [showSupportFundGetMoneyModal])

  useEffect(() => {
    document.addEventListener(
      visibilityChange,
      (visibility) => {
        const now = Date.now()
        if (document.visibilityState === 'visible') {
          getSupportFundTeamInfo()
        }
      },
      false,
    )
  }, [token])

  const alfarmMissionButton = async () => {
    if (supportFundTeamInfo?.missionStatus?.isAlfarmStatus === 'preMission') {
      const ongoingAlfarmData = await backendApis.getAlFarmUserData(token)
      if (ongoingAlfarmData?.status !== 200) {
        isButtonDisable = false
        return
      }
      if (ongoingAlfarmData?.data?._id) {
        setIsAlreadyExist(true)
      } else {
        setIsAlreadyExist(false)
      }
      window.location.href = `#navigate.${JSON.stringify({
        screen: 'gameTest',
        prop: {
          origin: 'supportFundEvent',
          isAlreadyExist: !!ongoingAlfarmData?.data?._id,
          itemType: ongoingAlfarmData?.data?.itemType,
        },
      })}`
      await backendApis.setHourAlfarmMission(token)
    } else {
      const result = await backendApis.setHourAlfarmMission(token)
      if (result?.status === 200 && result?.data?.discountPrice) {
        setDiscountPrice(result?.data?.discountPrice)
        setShowSupportFundGetMoneyModal(true)
      } else {
        alert.show('이미 올팜 물주기 미션을 수행하였어요.')
      }
      setSupportFundTeamInfo(result?.data)
    }
  }

  const throwMissionButton = async () => {
    if (
      supportFundTeamInfo?.missionStatus?.isThrowStatus === 'preMission' &&
      supportFundTeamInfo?.receiveCount < 3
    ) {
      setShowSupportFundThrowModal(true)
    } else if (
      supportFundTeamInfo?.missionStatus?.isThrowStatus === 'preMission' &&
      supportFundTeamInfo?.receiveCount === 3
    ) {
      const result = await backendApis.setThrowMission(token)
      if (result?.status === 200 && result?.data?.discountPrice) {
        setDiscountPrice(result?.data?.discountPrice)
        setShowSupportFundGetMoneyModal(true)
        setSupportFundTeamInfo(result?.data)
      } else {
        alert.show('알 수 없는 문제가 발생했어요. 다시 시도해주세요.')
      }
    } else {
      alert.show('이미 돈 뿌리기 보상을 받았어요.')
    }
  }

  const attendantList = (attendantListData) => {
    const attendantComponent = (attendantData) => {
      function UserImageUri() {
        if (attendantData?.userName === '올팜 물주기') {
          return (
            <div
              style={{
                width: 28,
                height: 28,
                // borderRadius: 28,
                overflow: 'hidden',
              }}
            >
              <img
                alt=''
                style={{ width: '100%', height: '100%' }}
                src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/6d234cd5-3414-4e8a-890b-2bf50f935838.png'
              />
            </div>
          )
        }
        if (
          attendantData?.userName === '돈 뿌리기' ||
          attendantData?.userName === '돈 받기'
        ) {
          return (
            <div
              style={{
                width: 28,
                height: 28,
                // borderRadius: 28,
                overflow: 'hidden',
              }}
            >
              <img
                alt=''
                style={{ width: '100%', height: '100%' }}
                src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/e9f00f41-69df-4776-9a6d-fa941c2c1cf8.png'
              />
            </div>
          )
        }
        return (
          <div
            style={{
              width: 28,
              height: 28,
              borderRadius: 28,
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img alt='' src={attendantData?.userImageUri} />
          </div>
        )
      }
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
            padding: 5,
            fontSize: 18,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <UserImageUri />
            <div
              style={{
                boxSizing: 'border-box',
                paddingLeft: 12,

                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div style={{ color: '666666' }}> {attendantData?.userName}</div>
              {userBadge(attendantData?.userType, attendantData?.isNewUser)}
            </div>
          </div>
          <div
            style={{
              //   color: '#ff3e3e',
              flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
              // flex: 1,
              fontWeight: 700,
            }}
          >
            <div
              style={{
                width: 28,
                height: 28,
                marginRight: 2,
                padding: 1,
                boxSizing: 'border-box',
              }}
            />
            {attendantData?.discountPrice?.toLocaleString()}원
          </div>
        </div>
      )
    }
    return (
      <div
        style={{
          width: '100%',
          backgroundColor: '#FFFFFF',
          //   backgroundColor: 'red',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          borderRadius: 8,
          marginBottom: 16,
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            paddingTop: 12,
            paddingBottom: 12,
            fontSize: 18,
            display: 'flex',
            justifyContent: 'center',
            width: '90%',
            borderBottom: '1px solid #E5E5E5',
            marginBottom: 12,
          }}
        >
          친구목록
        </div>
        <div
          style={{
            backgroundColor: '#FFFFFF',
            // backgroundColor: 'blue',
            width: '100%',
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 8,
            paddingRight: 8,
            boxSizing: 'border-box',
          }}
        >
          {attendantListData?.map((x) => attendantComponent(x))}
        </div>
      </div>
    )
  }

  return (
    <>
      <Layout>
        <Notice
          onClick={() => {
            setShowSupportFundExplainModal(true)
          }}
        />
        <Header title='연말지원금' />
        <div style={{ height: '93%', overflowY: 'scroll' }}>
          <div
            style={{
              display: 'flex',
              //   height: '300vh',
              width: '100vw',
              background: '#F6F4E5',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                padding: 10,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <button
                type='button'
                style={{
                  textAlign: 'center',
                  color: 'white',
                  fontWeight: 700,
                  backgroundColor: '#EB4F47',
                  marginTop: 0,
                  padding: 8,
                  paddingLeft: 16,
                  paddingRight: 16,
                  border: 'none',
                  borderRadius: 4,
                  fontSize: 16,
                  fontFamily: 'GmarketSans',
                }}
                onClick={() => {
                  setShowSupportFundCloseNoticeModal(true)
                }}
              >
                연말지원금 종료 안내 (12월 31일 종료)
              </button>
            </div>
            <div
              style={{
                backgroundColor: '#4D4B3C',
                color: '#FFFFFF',
                fontFamily: 'GmarketSans',
                padding: 8,
                paddingLeft: 16,
                paddingRight: 16,
                borderRadius: 16,
                marginTop: 8,
                marginBottom: 16,
              }}
            >
              최대 1억원 지원금 혜택!
            </div>
            {supportFundTeamInfo?.withDrawalHistory === true && (
              <>
                <div
                  style={{
                    fontFamily: 'GmarketSans',
                    fontSize: 12,
                    color: '#EB4F47',
                    marginTop: -8,
                    marginBottom: 8,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      width: '80vw',
                    }}
                  >
                    *이미 출금 이력이 있어 성공하더라도 출금이 불가해요
                  </div>
                </div>
              </>
            )}

            <div
              style={{
                width: '100vw',
                display: 'flex',
                justifyContent: 'center',
                fontFamily: 'GmarketSans',
                fontSize: 20,
                color: '#4D4B3C',
                fontWeight: 900,
                marginBottom: 24,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#F2C660',
                    height: 10,
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    marginRight: 4,
                  }}
                >
                  연말지원금
                </div>
                <div>
                  <span style={{ color: '#EB4F47', marginRight: 4 }}>
                    5만원
                  </span>
                  받아가세요!
                </div>
              </div>
            </div>
            <div
              style={{
                // backgroundColor: 'red',
                width: 264,
                height: 188,
                backgroundSize: 'cover',
                backgroundImage: "url('/images/SupportFundCashImage.png')",
                marginBottom: 8,
                position: 'relative',
              }}
            >
              <div
                style={{
                  color: '#A5770B',
                  fontFamily: 'GmarketSans',
                  fontWeight: 900,
                  position: 'absolute',
                  top: 20,
                  left: 80,
                  fontSize: 12,
                }}
              >
                현재금액
              </div>
              <button
                type='button'
                style={{ background: 'none', border: 'none' }}
                onClick={() => {
                  setShowSupportFundWithdrawModal(true)
                }}
              >
                <div
                  style={{
                    color: 'white',
                    fontFamily: 'GmarketSans',
                    fontWeight: 700,
                    position: 'absolute',
                    top: 20,
                    right: 20,
                    fontSize: 12,
                  }}
                >
                  출금 >
                </div>
              </button>
              {supportFundTeamInfo ? (
                <>
                  <div
                    style={{
                      color: '#422E02',
                      fontFamily: 'GmarketSans',
                      fontWeight: 700,
                      position: 'absolute',
                      top: 44,
                      left: 80,
                      fontSize: 28,
                    }}
                  >
                    {commaNumber(
                      supportFundTeamInfo?.goalPrice -
                        supportFundTeamInfo?.remainedPrice,
                    )}
                    원
                  </div>
                  <div
                    style={{
                      color: '#422E02',
                      fontFamily: 'GmarketSans',
                      position: 'absolute',
                      top: 140,
                      left: 76,
                      fontSize: 12,
                      fontWeight: 700,
                    }}
                  >
                    남은시간{' '}
                    {supportFundTeamInfo?.createdAt &&
                    supportFundTeamInfo?.remainedPrice > 0 ? (
                      <BlackTextTimer
                        color='#EB4F47'
                        timeStamp={new Date(supportFundTeamInfo?.createdAt)}
                        timerMinutes={60 * 24}
                      />
                    ) : (
                      '00:00:00.0'
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      color: '#422E02',
                      fontFamily: 'GmarketSans',
                      fontWeight: 700,
                      position: 'absolute',
                      top: 44,
                      left: 80,
                      fontSize: 28,
                    }}
                  >
                    47,500원
                  </div>
                  <div
                    style={{
                      color: '#422E02',
                      fontFamily: 'GmarketSans',
                      position: 'absolute',
                      top: 140,
                      left: 76,
                      fontSize: 12,
                      fontWeight: 700,
                    }}
                  >
                    남은시간{' '}
                    <span style={{ color: '#EB4F47' }}>00:00:00.0</span>
                  </div>
                </>
              )}
            </div>
            <div
              style={{
                backgroundColor: '#EB4F47',
                display: 'flex',
                justifyContent: 'center',
                margin: 8,
                marginBottom: 8,
                borderRadius: 8,
                width: '64%',
              }}
              className={`${'scaleUp'}`}
            >
              <button
                type='button'
                style={{
                  backgroundColor: '#EB4F47',
                  padding: 8,
                  borderRadius: 8,
                  paddingTop: 12,
                  paddingBottom: 12,
                  width: '100%',
                  height: '100%',
                  background: 'none',
                  border: 'none',
                  color: 'white',
                  fontFamily: 'GmarketSans',
                  fontWeight: 900,
                  fontSize: 16,
                }}
                onClick={() => {
                  if (
                    supportFundTeamInfo?.remainedPrice <= 0 &&
                    !supportFundTeamInfo?.supportFundWithdrawalDone
                  ) {
                    if (supportFundTeamInfo?.withDrawalHistory === true) {
                      alert.show('이미 출금 이력이 있어 출금이 불가해요.')
                      return
                    }
                    // 출금할 때 본인인증 정보 받기
                    if (
                      !userInfo?.selfAuthenticationInfo &&
                      !userInfo?.selfAuthenticationInfoLogs
                    ) {
                      window.location.href = '#openAuthenticationModal.'
                      return
                    }
                    window.location.href = `/support-fund-input/?token=${token}&supportFundTeamId=${
                      supportFundTeamInfo?._id
                    }&randomString=${Math.random().toString(36).substr(2, 8)}`
                  } else if (
                    supportFundTeamInfo?.remainedPrice <= 0 &&
                    supportFundTeamInfo?.supportFundWithdrawalDone
                  ) {
                    alert.show(
                      '이미 계좌정보를 입력했어요. 영업일 기준 24시간 내에 입금될 예정이니 조금만 기다려주세요!',
                    )
                  } else {
                    window.location.href = `#openBottomShareSheet.${JSON.stringify(
                      {
                        code: 'supportFundInvite',
                      },
                    )}`
                  }
                }}
              >
                {supportFundTeamInfo?.remainedPrice > 0
                  ? '친구 초대하고 5만원 받기'
                  : supportFundTeamInfo?.supportFundWithdrawalDone
                  ? '출금 신청 완료'
                  : '5만원 받으러 가기'}
              </button>
            </div>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontSize: 12,
                color: '#79765F',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  width: '80vw',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#F2C660',
                    width: '8%',
                    height: 6,
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    fontWeight: 900,
                    marginRight: 4,
                  }}
                >
                  TIP
                </div>
                새친구와 같이하면 더 빨리 모을 수 있어요
              </div>
            </div>
            {/* 첫 참여이고 일정 금액에 도달 했을 때만 보여주기 */}
            {supportFundTeamInfo?.isFirstChallenge &&
              !supportFundTeamInfo?.supportFundWithdrawalDone &&
              supportFundTeamInfo?.remainedPrice <= 1500 && (
                <>
                  <div
                    style={{
                      backgroundColor: '#4D4B3C',
                      padding: 8,
                      paddingTop: 12,
                      paddingBottom: 12,
                      display: 'flex',
                      justifyContent: 'center',
                      borderRadius: 8,
                      margin: 8,
                      width: '64%',
                    }}
                  >
                    <button
                      type='button'
                      style={{
                        background: 'none',
                        border: 'none',
                        color: 'white',
                        fontFamily: 'GmarketSans',
                        fontWeight: 900,
                        fontSize: 16,
                      }}
                      onClick={() => {
                        setShowSupportFundStopModal(true)
                      }}
                    >
                      그만하고 랜덤쿠폰 받기
                    </button>
                  </div>
                </>
              )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '80vw',
                justifyContent: 'space-around',
                marginTop: 20,
              }}
            >
              <div
                style={{
                  backgroundColor: 'white',
                  width: '36vw',
                  borderRadius: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  fontFamily: 'GmarketSans',
                  color: '#666666',
                  fontSize: 12,
                }}
              >
                {supportFundTeamInfo &&
                (supportFundTeamInfo?.missionStatus?.isAlfarmStatus ===
                  'pending' ||
                  supportFundTeamInfo?.remainedPrice <= 0) ? (
                  <>
                    <button
                      type='button'
                      style={{
                        background: 'none',
                        border: 'none',
                        color: 'white',
                        fontSize: 16,
                        fontFamily: 'GmarketSans',
                        paddingTop: 12,
                      }}
                      onClick={() => {}}
                    >
                      <img
                        alt=''
                        style={{ width: 48, height: 48, marginBottom: 8 }}
                        src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/6d234cd5-3414-4e8a-890b-2bf50f935838.png'
                      />
                      <div style={{ color: '#666666', fontSize: 12 }}>
                        한시간마다 작물에
                      </div>
                      <div style={{ color: '#666666', fontSize: 12 }}>
                        물을 주고 돈을 받아요
                      </div>
                      <>
                        <div
                          style={{
                            backgroundColor: '#BEC0C2',
                            width: '36vw',
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 8,
                            marginTop: 12,
                            paddingTop: 8,
                            paddingBottom: 8,
                          }}
                        >
                          {supportFundTeamInfo?.remainedPrice > 0 ? (
                            <BlackTextTimer
                              color='ffff'
                              timeStamp={
                                new Date(
                                  new Date(
                                    supportFundTeamInfo?.missionStatus?.alfarmStatusLog
                                      ?.filter(
                                        (each) =>
                                          each?.isAlfarmStatus === 'pending',
                                      )
                                      ?.map((elem) => elem?.joinedAt)
                                      ?.sort((a, b) => a - b)
                                      .slice(-1)[0] || 0,
                                  ).getTime() +
                                    -23 * 60 * 60 * 1000,
                                )
                              }
                              timerMinutes={60 * 24}
                            />
                          ) : (
                            '완료'
                          )}
                        </div>
                      </>
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type='button'
                      style={{
                        background: 'none',
                        border: 'none',
                        color: 'white',
                        fontSize: 16,
                        fontFamily: 'GmarketSans',
                        paddingTop: 12,
                      }}
                      onClick={() => {
                        if (isAlfarmButtonDisable) {
                          return
                        }
                        setIsAlfarmButtonDisable(true)
                        debounce(alfarmMissionButton, 500)
                      }}
                    >
                      <img
                        alt=''
                        style={{ width: 48, height: 48, marginBottom: 8 }}
                        src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/6d234cd5-3414-4e8a-890b-2bf50f935838.png'
                      />
                      <div style={{ color: '#666666', fontSize: 12 }}>
                        한시간마다 작물에
                      </div>
                      <div style={{ color: '#666666', fontSize: 12 }}>
                        물을 주고 돈을 받아요
                      </div>
                      <>
                        {supportFundTeamInfo?.missionStatus?.isAlfarmStatus ===
                        'preMission' ? (
                          <div
                            style={{
                              backgroundColor: '#4D4B3C',
                              width: '36vw',
                              borderBottomLeftRadius: 8,
                              borderBottomRightRadius: 8,
                              marginTop: 12,
                              paddingTop: 8,
                              paddingBottom: 8,
                            }}
                          >
                            물주러 가기
                          </div>
                        ) : (
                          <div
                            style={{
                              backgroundColor: '#EE3E3E',
                              width: '36vw',
                              borderBottomLeftRadius: 8,
                              borderBottomRightRadius: 8,
                              marginTop: 12,
                              paddingTop: 8,
                              paddingBottom: 8,
                            }}
                          >
                            돈 받기
                          </div>
                        )}
                      </>
                    </button>
                  </>
                )}
              </div>
              <div
                style={{
                  backgroundColor: 'white',
                  width: '36vw',
                  borderRadius: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  fontFamily: 'GmarketSans',
                  color: '#666666',
                  fontSize: 12,
                }}
              >
                <button
                  type='button'
                  style={{
                    background: 'none',
                    border: 'none',
                    color: 'white',
                    fontSize: 16,
                    fontFamily: 'GmarketSans',
                    paddingTop: 12,
                  }}
                  onClick={() => {
                    if (isThrowButtonDisable) {
                      setIsThrowButtonDisable(false)
                      return
                    }
                    setIsThrowButtonDisable(true)
                    debounce(throwMissionButton, 500)
                  }}
                >
                  <img
                    alt=''
                    style={{ width: 48, height: 48, marginBottom: 8 }}
                    src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/f239ca93-3372-4b17-8d95-860e5593020f.png'
                  />
                  <div style={{ color: '#666666', fontSize: 12 }}>
                    친구 3명에게
                  </div>
                  <div style={{ color: '#666666', fontSize: 12 }}>
                    돈을 뿌릴 수 있어요
                  </div>
                  {supportFundTeamInfo?.missionStatus?.isThrowStatus ===
                    'clearMission' ||
                  supportFundTeamInfo?.remainedPrice <= 0 ? (
                    <>
                      <div
                        style={{
                          backgroundColor: '#BEC0C2',
                          width: '36vw',
                          borderBottomLeftRadius: 8,
                          borderBottomRightRadius: 8,
                          marginTop: 12,
                          paddingTop: 8,
                          paddingBottom: 8,
                        }}
                      >
                        완료 (3/3)
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          backgroundColor: '#4D4B3C',
                          width: '36vw',
                          borderBottomLeftRadius: 8,
                          borderBottomRightRadius: 8,
                          marginTop: 12,
                          paddingTop: 8,
                          paddingBottom: 8,
                        }}
                      >
                        {supportFundTeamInfo?.receiveCount > 2
                          ? '돈 받기'
                          : `돈 뿌리기 (${
                              supportFundTeamInfo?.receiveCount || 0
                            }/3)`}
                      </div>
                    </>
                  )}
                </button>
              </div>
            </div>
            <div style={{ width: '80vw', marginTop: 20, marginBottom: 20 }}>
              {attendantList(supportFundTeamInfo?.guestUserInfosArray || [])}
            </div>
          </div>
        </div>
        {showSupportFundWithdrawModal && (
          <SupportFundWithdrawModal
            setShowSupportFundWithdrawModal={setShowSupportFundWithdrawModal}
            remainedPrice={supportFundTeamInfo?.remainedPrice}
            token={token}
            supportFundTeamId={supportFundTeamInfo?._id}
          />
        )}
        {showSupportFundGetMoneyModal && (
          <SupportFundGetMoneyModal
            setShowSupportFundGetMoneyModal={setShowSupportFundGetMoneyModal}
            discountPrice={discountPrice}
          />
        )}
        {showSupportFundInviteOnboardingModal && (
          <SupportFundInviteOnboardingModal
            setShowSupportFundInviteOnboardingModal={
              setShowSupportFundInviteOnboardingModal
            }
            supportFundUserInvitedInfos={supportFundUserInvitedInfos}
            setShowSupportFundCloseNoticeModal={
              setShowSupportFundCloseNoticeModal
            }
            token={token}
            invitorId={alwayzInvitorId}
            userInfo={userInfo}
            debounce={debounce}
          />
        )}
        {showSupportFundThrowModal && (
          <SupportFundThrowModal
            setShowSupportFundThrowModal={setShowSupportFundThrowModal}
            remainedCount={3 - supportFundTeamInfo?.receiveCount}
          />
        )}
        {showSupportFundStopModal && (
          <SupportFundStopModal
            setShowSupportFundStopModal={setShowSupportFundStopModal}
            token={token}
          />
        )}
        {showSupportFundThrowOnboardingModal && (
          <SupportFundThrowOnboardingModal
            setShowSupportFundThrowOnboardingModal={
              setShowSupportFundThrowOnboardingModal
            }
            setShowSupportFundCloseNoticeModal={
              setShowSupportFundCloseNoticeModal
            }
            token={token}
            invitorId={alwayzInvitorId}
            setShowSupportFundGetMoneyModal={setShowSupportFundGetMoneyModal}
            setDiscountPrice={setDiscountPrice}
            userInfo={userInfo}
            debounce={debounce}
          />
        )}
        {showSupportFundExplainModal && (
          <SupportFundExplainModal
            setShowSupportFundExplainModal={setShowSupportFundExplainModal}
            token={token}
          />
        )}
        {showSupportFundEmergencyNoticeModal && (
          <SupportFundEmergencyNoticeModal
            setShowSupportFundEmergencyNoticeModal={
              setShowSupportFundEmergencyNoticeModal
            }
          />
        )}
        {showSupportFundCloseNoticeModal && (
          <SupportFundCloseNoticeModal
            setShowSupportFundCloseNoticeModal={
              setShowSupportFundCloseNoticeModal
            }
            token={token}
          />
        )}
      </Layout>
    </>
  )
}
export default SupportFundEvent
