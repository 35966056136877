import React, { useState, useEffect } from 'react'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import Layout from '../components/layout'
import Header from '../components/header'
import Notice from '../components/Notice'
import commaNumber from 'comma-number'
import { useAlert } from 'react-alert'
import NewYearsMoneyInitialBonusModal from '../modals/NewYearsMoneyInitialBonusModal'
import NewYearsMoneyShareModal from '../modals/NewYearsMoneyShareModal'
import NewYearsMoneyExplainModal from '../modals/NewYearsMoneyExplainModal'
import NewYearsMoneyCloseNoticeModal from '../modals/NewYearsMoneyCloseNoticeModal'

const isButtonDisable = false
let hidden
let visibilityChange
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

function NewYearsMoney() {
  const alert = useAlert()
  const token = useSearchParam('token')
  const alwayzInvitorId = useSearchParam('alwayzInvitorId')
  const itemId = useSearchParam('itemId')
  const isFrom = useSearchParam('isFrom')
  const [index, setIndex] = useState(1)
  const [userInfo, setUserInfo] = useState('')
  const [newYearsMoneyTeamInfo, setNewYearsMoneyTeamInfo] = useState('')
  const [
    showNewYearsMoneyInitialBonusModal,
    setShowNewYearsMoneyInitialBonusModal,
  ] = useState(false)
  const [showNewYearsMoneyShareModal, setShowNewYearsMoneyShareModal] =
    useState(false)
  const [showNewYearsMoneyExplainModal, setShowNewYearsMoneyExplainModal] =
    useState(false)
  const [
    showNewYearsMoneyCloseNoticeModal,
    setShowNewYearsMoneyCloseNoticeModal,
  ] = useState(true)

  const initNewYearsMoney = async () => {
    const userInfo = await backendApis.getUserBasicInfoWithAuthentication(token)
    setUserInfo(userInfo?.data)
    // invitorId 있으면 친구 초대 받는 UI 보여주고
    if (alwayzInvitorId && userInfo?.data?.phoneNumber === '') {
      // window.location.href = `/new-years-money-invitation/?token=${token}&alwayzInvitorId=${alwayzInvitorId}&randomString=${Math.random()
      //   .toString(36)
      //   .substr(2, 8)}`
      return
    }
    if (
      userInfo?.status !== 200 ||
      userInfo?.data?.phoneNumber === '' ||
      userInfo?.data?.userName === ''
    ) {
      // window.location.href = '#openLoginModal.'
      return
    }
    // 없으면 기존 로직에서 로그인 검수
    const ongoingNewYearsMoneyTeam = await backendApis.getNewYearsMoneyTeamInfo(
      token,
    )
    if (
      ongoingNewYearsMoneyTeam?.data?.isFirstChallenge &&
      ongoingNewYearsMoneyTeam?.data?.totalAmount === 3000
      // &&
      // new Date(ongoingNewYearsMoneyTeam?.data?.createdAt) >
      //   new Date(new Date().getTime() - 1000 * 60)
    ) {
      setNewYearsMoneyTeamInfo(ongoingNewYearsMoneyTeam?.data)
      setShowNewYearsMoneyInitialBonusModal(true)
      return
    }

    if (
      alwayzInvitorId &&
      alwayzInvitorId !== 'undefined' &&
      ongoingNewYearsMoneyTeam?.data
    ) {
      alert.show(
        '올웨이즈를 처음하는 고객만 친구가 보낸 링크로 참여할 수 있어요. 친구에게 공유하고 최대 11만원을 받아보세요',
        {
          closeCopy: '확인',
        },
      )
      setNewYearsMoneyTeamInfo(ongoingNewYearsMoneyTeam?.data)
      return
    }

    if (!ongoingNewYearsMoneyTeam?.data) {
      const initNewYearsMoneyTeam = await backendApis.initNewYearsMoneyTeam(
        token,
        'POST',
        {
          invitorId: alwayzInvitorId,
        },
      )
      setNewYearsMoneyTeamInfo(initNewYearsMoneyTeam?.data)
      if (initNewYearsMoneyTeam?.data?.isNewUser) {
        // window.location.href = `/new-years-money-invitation/?token=${token}&invitorId=${alwayzInvitorId}randomString=${Math.random()
        //   .toString(36)
        //   .substr(2, 8)}`
        return
      }
      if (
        alwayzInvitorId &&
        initNewYearsMoneyTeam?.data?.msg === 'invitee is under age'
      ) {
        alert.show('본 이벤트는 20세 이상 고객만 참여 가능해요', {
          closeCopy: '확인',
        })
      } else if (
        alwayzInvitorId &&
        initNewYearsMoneyTeam?.data?.msg === 'host and invitee are same'
      ) {
        alert.show('자신이 공유한 링크로 자신이 받을 수는 없어요.', {
          closeCopy: '확인',
        })
      } else if (
        alwayzInvitorId &&
        alwayzInvitorId !== 'undefined' &&
        initNewYearsMoneyTeam?.data?.msg === 'is not new user'
      ) {
        alert.show(
          '올웨이즈를 처음하는 고객만 친구가 보낸 초대를 받을 수 있어요. 친구에게 공유하고 최대 11만원을 받아보세요',
          {
            closeCopy: '확인',
          },
        )
      } else {
      }
    } else {
      setNewYearsMoneyTeamInfo(ongoingNewYearsMoneyTeam?.data)
    }
  }

  useEffect(() => {
    initNewYearsMoney()
  }, [])

  useEffect(() => {
    document.addEventListener(
      visibilityChange,
      (visibility) => {
        const now = Date.now()
        if (document.visibilityState === 'visible') {
          // initNewYearsMoney()
        }
      },
      false,
    )
  }, [token])

  const FriendsCardComponent = ({ data }) => {
    const Received = ({ count, image }) => {
      const b = 2
      return (
        <div
          style={{
            backgroundImage: "url('/images/emptyFriendCard.png')",
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: '30vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          {newYearsMoneyTeamInfo?.isFirstChallenge && count === 1 && (
            <>
              <div
                style={{
                  backgroundColor: '#FFFAC9',
                  border: '2px solid #292D36',
                  fontSize: 12,
                  textAlign: 'center',
                  padding: 4,
                  marginBottom: '32vw',
                }}
              >
                <div>첫 도전</div>
                <div style={{ fontWeight: 'bold' }}>+3,000</div>
              </div>
            </>
          )}
          {count === 5 && (
            <>
              <div
                style={{
                  backgroundColor: '#FFFAC9',
                  border: '2px solid #292D36',
                  fontSize: 12,
                  textAlign: 'center',
                  padding: 4,
                  marginBottom: '32vw',
                }}
              >
                <div>보너스</div>
                <div style={{ fontWeight: 'bold' }}>+7,000</div>
              </div>
            </>
          )}
          <div style={{ position: 'absolute' }}>
            <img
              alt=''
              src={image}
              style={{
                height: 40,
                borderRadius: 20,
                marginBottom: 32,
              }}
            />
          </div>
        </div>
      )
    }
    const Left = ({ count }) => {
      const c = 3
      return (
        <div
          style={{
            backgroundImage: `url('/images/friendCard${count}.png')`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: '30vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {newYearsMoneyTeamInfo?.isFirstChallenge && count === 1 && (
            <>
              <div
                style={{
                  backgroundColor: '#FFFAC9',
                  border: '2px solid #292D36',
                  fontSize: 12,
                  textAlign: 'center',
                  padding: 4,
                  marginBottom: '32vw',
                }}
              >
                <div>첫 도전</div>
                <div style={{ fontWeight: 'bold' }}>+3,000</div>
              </div>
            </>
          )}
          {count === 5 && (
            <>
              <div
                style={{
                  backgroundColor: '#FFFAC9',
                  border: '2px solid #292D36',
                  fontSize: 12,
                  textAlign: 'center',
                  padding: 4,
                  marginBottom: '32vw',
                }}
              >
                <div>보너스</div>
                <div style={{ fontWeight: 'bold' }}>+7,000</div>
              </div>
            </>
          )}
        </div>
      )
    }
    return (
      <>
        <div
          style={{
            width: '96%',
            height: 128,
            marginTop: 16,
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          {newYearsMoneyTeamInfo &&
            [1, 2, 3, 4, 5]
              .slice(0, data?.length)
              .map((each) => (
                <Received image={data?.[each - 1]?.mainImageUri} count={each} />
              ))}
          {[1, 2, 3, 4, 5].slice(data?.length, 5).map((each) => (
            <Left count={each} />
          ))}
        </div>
      </>
    )
  }

  const Rule = () => (
    <>
      <div
        style={{
          backgroundImage: "url('/images/NewYearsMoneyRule.png')",
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      />
    </>
  )

  const RewardStep = () => (
    <>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '90vw',
          }}
        >
          <div
            style={{
              marginTop: 16,
              marginBottom: 16,
              fontWeight: 'bold',
              textAlign: 'left',
            }}
          >
            미션별 보상 (초대한 친구 1명당 최대 2만원)
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              border: '1px solid #D9D9D9',
              width: '88vw',
              padding: 8,
              marginBottom: 8,
              paddingTop: 12,
              paddingBottom: 12,
              borderRadius: 4,
              alignItems: 'center',
            }}
          >
            <div style={{ flex: 1 }}>
              <img
                src='/images/reward1.png'
                alt=''
                style={{
                  width: 28,
                }}
              />
            </div>
            <div style={{ flex: 2, fontWeight: 'bold' }}>2,000원</div>
            <div
              style={{
                flex: 6,
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: 16,
              }}
            >
              새친구가 초대를 수락했을 때
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              border: '1px solid #D9D9D9',
              width: '88vw',
              padding: 8,
              marginBottom: 8,
              paddingTop: 12,
              paddingBottom: 12,
              borderRadius: 4,
              alignItems: 'center',
            }}
          >
            <div style={{ flex: 1 }}>
              <img
                src='/images/reward2.png'
                alt=''
                style={{
                  width: 28,
                }}
              />
            </div>
            <div style={{ flex: 2, fontWeight: 'bold' }}>2,000원</div>
            <div
              style={{
                flex: 6,
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: 16,
              }}
            >
              친구가 올팜에서 4일 연속 물주기를 했을 때
            </div>
          </div>{' '}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              border: '1px solid #D9D9D9',
              width: '88vw',
              padding: 8,
              marginBottom: 8,
              paddingTop: 12,
              paddingBottom: 12,
              borderRadius: 4,
              alignItems: 'center',
            }}
          >
            <div style={{ flex: 1 }}>
              <img
                src='/images/reward3.png'
                alt=''
                style={{
                  width: 28,
                }}
              />
            </div>
            <div style={{ flex: 2, fontWeight: 'bold' }}>2,000원</div>
            <div
              style={{
                flex: 6,
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: 16,
              }}
            >
              친구가 올팜에서 7일 연속 물주기를 했을 때{' '}
            </div>
          </div>{' '}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              border: '1px solid #D9D9D9',
              width: '88vw',
              padding: 8,
              marginBottom: 8,
              paddingTop: 12,
              paddingBottom: 12,
              borderRadius: 4,
              alignItems: 'center',
            }}
          >
            <div style={{ flex: 1 }}>
              <img
                src='/images/reward4.png'
                alt=''
                style={{
                  width: 28,
                }}
              />
            </div>
            <div style={{ flex: 2, fontWeight: 'bold' }}>4,000원</div>
            <div
              style={{
                flex: 6,
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: 16,
              }}
            >
              친구가 올팜에서 맞팜친구 3명을 맺었을 때
            </div>
          </div>{' '}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              border: '1px solid #D9D9D9',
              width: '88vw',
              padding: 8,
              marginBottom: 8,
              paddingTop: 12,
              paddingBottom: 12,
              borderRadius: 4,
              alignItems: 'center',
            }}
          >
            <div style={{ flex: 1 }}>
              <img
                src='/images/reward5.png'
                alt=''
                style={{
                  width: 28,
                }}
              />
            </div>
            <div style={{ flex: 2, fontWeight: 'bold' }}>10,000원</div>
            <div
              style={{
                flex: 6,
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: 16,
              }}
            >
              친구가 올팜에서 맞팜친구 10명을 맺었을 때
            </div>
          </div>
        </div>
      </div>
    </>
  )

  const MissionStatusCardList = ({ friendData }) => {
    const c = 3
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: 64,
                marginBottom: 8,
              }}
            >
              <img
                style={{
                  height: 40,
                  borderRadius: 20,
                  opacity: 0.5,
                }}
                src={`${friendData?.mainImageUri}`}
                alt=''
              />
              <div style={{ fontWeight: 'bold', marginLeft: 12 }}>
                {friendData?.userName}
                <span style={{ fontWeight: 'normal' }}>
                  {' '}
                  ({friendData?.missionStatus === 'fail' ? '종료' : '진행중'})
                </span>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '88vw',
              }}
            >
              {[1, 2, 3, 4, 5]
                .slice(0, friendData?.missionStep - 1)
                .map((each) => (
                  <>
                    {' '}
                    {/* success 라인 시작 */}
                    <div
                      style={{
                        backgroundColor: '#2C2F38',
                        flex: 1,
                        height: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: '#2c2f38',
                          width: 20,
                          height: 20,
                          borderRadius: 10,
                          position: 'absolute',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          alt=''
                          src='/images/missionCheckIcon.png'
                          style={{ width: 10 }}
                        />
                      </div>
                    </div>
                    {/* success 라인 끝 */}
                  </>
                ))}
              {friendData?.missionStatus === 'fail' && (
                <>
                  {' '}
                  {/* fail 라인 시작 */}
                  <div
                    style={{
                      backgroundColor: '#A33332',
                      flex: 1,
                      height: 4,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: '#A33332',
                        width: 20,
                        height: 20,
                        borderRadius: 10,
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        alt=''
                        src='/images/missionXIcon.png'
                        style={{ width: 16 }}
                      />
                    </div>
                  </div>
                  {/* fail 라인 끝 */}
                </>
              )}
              {friendData?.missionStatus === 'fail' ? (
                <>
                  {' '}
                  {[1, 2, 3, 4, 5]
                    .slice(friendData?.missionStep, 5)
                    .map((each) => (
                      <>
                        {' '}
                        {/* ongoing 라인 시작 */}
                        <div
                          style={{
                            backgroundColor: '#FFF0A4',
                            flex: 1,
                            height: 4,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        />
                        {/* ongoing 라인 끝 */}
                      </>
                    ))}
                </>
              ) : (
                <>
                  {' '}
                  {[1, 2, 3, 4, 5]
                    .slice(friendData?.missionStep - 1, 5)
                    .map((each) => (
                      <>
                        {' '}
                        {/* ongoing 라인 시작 */}
                        <div
                          style={{
                            backgroundColor: '#FFF0A4',
                            flex: 1,
                            height: 4,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        />
                        {/* ongoing 라인 끝 */}
                      </>
                    ))}
                </>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '88vw',
            textAlign: 'center',
            fontFamily: 'pretendard',
            marginTop: 12,
            marginBottom: 12,
          }}
        >
          <div style={{ flex: 1 }}>
            초대
            <br />
            수락하기
          </div>
          <div style={{ flex: 1 }}>
            4일
            <br />
            물주기
          </div>
          <div style={{ flex: 1 }}>
            7일
            <br />
            물주기
          </div>
          <div style={{ flex: 1 }}>
            맞팜
            <br />
            3명하기
          </div>
          <div style={{ flex: 1 }}>
            맞팜
            <br />
            10명하기
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Layout>
        <Notice
          onClick={() => {
            setShowNewYearsMoneyExplainModal(true)
          }}
        />
        <Header title='세뱃돈 이벤트' />
        <div
          style={{
            width: '100%',
            height: '100%',
            marginBottom: 10,
            fontFamily: 'GmarketSans',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'scroll',
            position: 'relative',
          }}
        >
          <div
            style={{
              padding: 10,
              display: 'flex',
              justifyContent: 'center',
              position: 'absolute',
              zIndex: 2,
            }}
          >
            <button
              type='button'
              style={{
                textAlign: 'center',
                color: 'black',
                fontWeight: 700,
                backgroundColor: '#FFF744',
                marginTop: 0,
                padding: 8,
                paddingLeft: 16,
                paddingRight: 16,
                border: 'none',
                borderRadius: 4,
                fontSize: 16,
                fontFamily: 'GmarketSans',
              }}
              onClick={() => {
                setShowNewYearsMoneyCloseNoticeModal(true)
              }}
            >
              세뱃돈 이벤트 종료 안내 (2월 2일 종료)
            </button>
          </div>
          <img
            src='/images/backgroundSkyImage.png'
            alt=''
            style={{
              width: '98vw',
              position: 'absolute',
            }}
          />
          <div
            style={{
              backgroundColor: '#292D36',
              height: 800,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                marginTop: 80,
                fontSize: 24,
                color: '#fff',
              }}
            >
              친구 5명을 초대하고
            </div>
            <div
              style={{
                marginTop: 4,
                fontSize: 24,
                color: '#fff',
                fontWeight: 'bold',
                marginBottom: 24,
              }}
            >
              <span
                style={{
                  color: '#FFF744',
                  borderBottom: '2px #fff744 solid',
                }}
              >
                최대 11만원
              </span>{' '}
              받아가세요
            </div>
            <FriendsCardComponent
              key={Math.random()}
              data={newYearsMoneyTeamInfo?.guestInfosArray}
            />
            <button
              type='button'
              style={{
                border: 'none',
                background: 'none',
                width: '80vw',
                marginTop: 16,
              }}
              className={`${'scaleUp'}`}
              onClick={async () => {
                const userInfoForCheck =
                  await backendApis.getUserBasicInfoWithAuthentication(token)
                if (
                  userInfoForCheck?.status !== 200 ||
                  userInfoForCheck?.data?.phoneNumber === '' ||
                  userInfoForCheck?.data?.userName === ''
                ) {
                  window.location.href = '#openLoginModal.'
                  return
                }

                const ongoingNewYearsMoneyTeam =
                  await backendApis.getNewYearsMoneyTeamInfo(token)
                setNewYearsMoneyTeamInfo(ongoingNewYearsMoneyTeam?.data)
                if (!ongoingNewYearsMoneyTeam?.data) {
                  const initNewYearsMoneyTeam =
                    await backendApis.initNewYearsMoneyTeam(token, 'POST', {
                      invitorId: alwayzInvitorId,
                    })
                  setNewYearsMoneyTeamInfo(initNewYearsMoneyTeam?.data)
                  if (initNewYearsMoneyTeam?.data?.isNewUser) {
                    window.location.href = `/new-years-money-invitation/?token=${token}&invitorId=${alwayzInvitorId}randomString=${Math.random()
                      .toString(36)
                      .substr(2, 8)}`
                    return
                  }
                  if (
                    alwayzInvitorId &&
                    initNewYearsMoneyTeam?.data?.msg === 'invitee is under age'
                  ) {
                    alert.show('본 이벤트는 20세 이상 고객만 참여 가능해요', {
                      closeCopy: '확인',
                    })
                  } else if (
                    alwayzInvitorId &&
                    initNewYearsMoneyTeam?.data?.msg ===
                      'host and invitee are same'
                  ) {
                    alert.show(
                      '자신이 공유한 링크로 자신이 받을 수는 없어요.',
                      {
                        closeCopy: '확인',
                      },
                    )
                  } else if (
                    alwayzInvitorId &&
                    alwayzInvitorId !== 'undefined' &&
                    initNewYearsMoneyTeam?.data?.msg === 'is not new user'
                  ) {
                    alert.show(
                      '올웨이즈를 처음하는 고객만 친구가 보낸 초대를 받을 수 있어요. 친구에게 공유하고 최대 11만원을 받아보세요',
                      {
                        closeCopy: '확인',
                      },
                    )
                  } else {
                  }
                }
                window.location.href = `#openBottomShareSheet.${JSON.stringify({
                  code: 'newYearsMoney',
                })}`
                setShowNewYearsMoneyShareModal(true)
              }}
            >
              <div
                style={{
                  //   backgroundColor: '#FFF844',
                  width: '100%',
                  height: 64,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontFamily: 'GmarketSans',
                  color: '#292D36',
                  fontSize: 20,
                  fontWeight: 'bold',
                  borderRadius: 20,
                  marginBottom: 56,
                }}
              >
                <img
                  style={{
                    width: '88%',
                  }}
                  src='/images/newYearsMoneyInvitorButton.png'
                  alt=''
                />
              </div>
            </button>
          </div>
          {/* <div style={{ backgroundColor: 'red', height: 50, width: '100%' }} /> */}
          <div
            style={{
              // backgroundColor: 'red',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              // marginTop: -20,
            }}
          >
            <div
              style={{
                width: '90vw',
                marginTop: 12,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* <div style={{ color: 'gray', fontSize: 16 }}>
                지금까지 받은 세뱃돈:
                <span
                  style={{
                    fontSize: 16,
                    color: 'black',
                    fontWeight: 'bold',
                    marginTop: 4,
                  }}
                >
                  {' '}
                  {commaNumber(newYearsMoneyTeamInfo?.totalAmount) || 0}원
                </span>
              </div> */}
            </div>
            <div
              style={{
                backgroundColor:
                  newYearsMoneyTeamInfo?.totalAmount >= 10000
                    ? '#292D36'
                    : '#bbbbbb',
                width: '68vw',
                padding: 8,
                paddingTop: 12,
                paddingBottom: 12,
                borderRadius: 8,
                marginTop: 20,
                marginBottom: 12,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <button
                type='button'
                style={{
                  border: 'none',
                  background: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                }}
                onClick={async () => {
                  if (newYearsMoneyTeamInfo?.totalAmount >= 10000) {
                    // 출금 페이지로 이동
                    window.location.href = `/new-years-money-input/?token=${token}&newYearsMoneyTeamId=${
                      newYearsMoneyTeamInfo?._id
                    }&randomString=${Math.random().toString(36).substr(2, 8)}`
                  } else {
                    // alert.show('1만원부터 세뱃돈 출금이 가능해요.', {
                    //   closeCopy: '확인',
                    // })
                  }
                }}
              >
                <div
                  style={{
                    color: '#fff',
                    fontWeight: 'bold',
                    fontFamily: 'GmarketSans',
                    fontSize: 20,
                  }}
                >
                  {newYearsMoneyTeamInfo?.totalAmount
                    ? `${commaNumber(newYearsMoneyTeamInfo?.totalAmount)}원`
                    : ''}{' '}
                  출금하기
                </div>
              </button>
            </div>
            <div style={{ color: 'gray', fontSize: 16, marginBottom: 4 }}>
              *출금은 1만원부터 가능해요
            </div>
          </div>
          <div
            style={{
              marginTop: 12,
              display: 'flex',
              flexDirection: 'column',
              width: '100vw',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                borderTop: '8px #F0F0F0 solid',
              }}
            >
              <div
                style={{
                  flex: 1,
                  textAlign: 'center',
                  padding: 8,
                  paddingTop: 12,
                  paddingBottom: 12,
                  borderBottom:
                    index !== 2 ? '2px #000000 solid' : '2px #F0F0F0 solid',
                }}
              >
                <button
                  type='button'
                  style={{
                    border: 'none',
                    background: 'none',
                    width: '100%',
                    height: '100%',
                    fontFamily: 'GmarketSans',
                    fontSize: 16,
                    color: index !== 2 ? '#000000' : '#717171',
                    fontWeight: index !== 2 ? 'bold' : 'normal',
                  }}
                  onClick={() => {
                    setIndex(1)
                  }}
                >
                  참여방법
                </button>
              </div>
              <div
                style={{
                  flex: 1,
                  textAlign: 'center',
                  padding: 8,
                  paddingTop: 12,
                  paddingBottom: 12,
                  borderBottom:
                    index === 2 ? '2px #000000 solid' : '2px #F0F0F0 solid',
                }}
              >
                <button
                  type='button'
                  style={{
                    border: 'none',
                    background: 'none',
                    width: '100%',
                    height: '100%',
                    fontFamily: 'GmarketSans',
                    fontSize: 16,
                    color: index !== 2 ? '#717171' : '#000000',
                    fontWeight: index === 2 ? 'bold' : 'normal',
                  }}
                  onClick={() => {
                    setIndex(2)
                  }}
                >
                  보상
                </button>
              </div>
            </div>
            <div
              style={{
                width: '100vw',
                marginBottom: 68,
                // marginTop: -20,
                // backgroundColor: 'blue',
              }}
            >
              {index !== 2 ? (
                <>
                  <Rule />
                </>
              ) : (
                <>
                  <div
                    style={{
                      width: '100vw',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontFamily: 'Pretendard',
                      marginBottom: 40,
                    }}
                  >
                    <RewardStep />
                    <div
                      style={{
                        marginTop: 12,
                        // backgroundColor: 'blue',
                        width: '88vw',
                      }}
                    >
                      <div
                        style={{
                          fontWeight: 'bold',
                          marginBottom: 4,
                        }}
                      >
                        초대한 친구
                      </div>
                      <div style={{ color: '#7D7D7D', marginBottom: 4 }}>
                        친구가 위 미션을 수행하면 돈을 받을 수 있어요
                      </div>
                      <div style={{ color: '#7D7D7D', marginBottom: 12 }}>
                        연속 물주기 성공해야 맞팜 미션을 수행할 수 있어요
                      </div>
                      <div style={{ marginBottom: 4 }}>
                        총 초대 인원 수 :{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          {' '}
                          {newYearsMoneyTeamInfo?.guestInfosArray?.length}명
                        </span>{' '}
                        / 5명
                      </div>
                      <div style={{ color: '#7D7D7D', marginBottom: 12 }}>
                        내가 초대한 친구의 미션 현황은 8시간마다 갱신돼요
                      </div>
                    </div>
                    {newYearsMoneyTeamInfo?.guestInfosArray?.map((elem) => (
                      <MissionStatusCardList
                        key={Math.random()}
                        friendData={elem}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {showNewYearsMoneyInitialBonusModal && (
          <NewYearsMoneyInitialBonusModal
            setShowNewYearsMoneyInitialBonusModal={
              setShowNewYearsMoneyInitialBonusModal
            }
            token={token}
          />
        )}

        {showNewYearsMoneyShareModal && (
          <NewYearsMoneyShareModal
            setShowNewYearsMoneyShareModal={setShowNewYearsMoneyShareModal}
          />
        )}

        {showNewYearsMoneyExplainModal && (
          <NewYearsMoneyExplainModal
            setShowNewYearsMoneyExplainModal={setShowNewYearsMoneyExplainModal}
          />
        )}
        {showNewYearsMoneyCloseNoticeModal && (
          <NewYearsMoneyCloseNoticeModal
            setShowNewYearsMoneyCloseNoticeModal={
              setShowNewYearsMoneyCloseNoticeModal
            }
            token={token}
          />
        )}
      </Layout>
    </>
  )
}
export default NewYearsMoney
