import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
  autorun,
} from 'mobx'

class UserStore {
  userInfo = {
    _id: '',
    codepushVersion: '',
    appVersion: '',
  }

  constructor() {
    makeObservable(this, {
      userInfo: observable.ref,

      setUserInfo: action,
    })
  }

  setUserInfo(userInfo) {
    this.userInfo = userInfo
  }
}

export default new UserStore()
