import React, { useState, useEffect } from 'react'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import Layout from '../components/layout'
import Header from '../components/header'
import Notice from '../components/Notice'
import BlackTextTimer from '../components/BlackTextTimer'
import commaNumber from 'comma-number'
import { useAlert } from 'react-alert'
import useDebounce from '../components/useDebounce'
import OnePlusOneSelectModal from '../modals/OnePlusOneSelectModal'
import OnePlusOneSendHistoryModal from '../modals/OnePlusOneSendHistoryModal'
import OnePlusOneExplainModal from '../modals/OnePlusOneExplainModal'
import OnePlusOneCloseNoticeModal from '../modals/OnePlusOneCloseNoticeModal'

let hidden
let visibilityChange
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

function OnePlusOneInvitation() {
  const alert = useAlert()
  const debounce = useDebounce()
  const token = useSearchParam('token')
  const isFrom = useSearchParam('isFrom')
  const alwayzInvitorId = useSearchParam('alwayzInvitorId')
  const invitorItem = useSearchParam('invitorItem')
  const code = useSearchParam('code')
  const [itemIndex, setItemIndex] = useState(1)
  const [onePlusOneItemInfo, setOnePlusOneItemInfo] = useState('')
  const [onePlusOneTeamInfo, setOnePlusOneTeamInfo] = useState('')
  const [isButtonDisable, setIsButtonDisable] = useState(false)
  const [showOnePlusOneSelectModal, setShowOnePlusOneSelectModal] =
    useState(false)
  const [showOnePlusOneSendHistoryModal, setShowOnePlusOneSendHistoryModal] =
    useState(false)
  const [showOnePlusOneExplainModal, setShowOnePlusOneExplainModal] =
    useState(false)
  const [showOnePlusOneCloseNoticeModal, setShowOnePlusOneCloseNoticeModal] =
    useState(false)

  const initOnePlusOne = async () => {
    const onePlusOneItemList = await backendApis.getOnePlusOneItemList(token)
    let result = await backendApis.getOnePlusOneTeamInfo(token)
    // const itemInfo = onePlusOneItemList?.data?.filter(
    //   (each) => each?._id === result?.data?.invitationInfo?.itemId,
    // )
    setOnePlusOneItemInfo(
      onePlusOneItemList?.data?.filter(
        (each) => each?._id === result?.data?.invitationInfo?.itemId,
      )[0],
    )

    setOnePlusOneTeamInfo(result?.data)
    if (!result?.data) {
      result = await backendApis.initOnePlusOneTeam(token, 'POST', {
        invitorId: alwayzInvitorId,
        itemId: invitorItem,
        missionDaysNum: onePlusOneItemList?.data?.filter(
          (each) => each?.itemId,
        )[0]?.missionDaysNum,
      })
    }
  }

  useEffect(() => {
    initOnePlusOne()
  }, [])

  useEffect(() => {
    document.addEventListener(
      visibilityChange,
      (visibility) => {
        const now = Date.now()
        if (document.visibilityState === 'visible') {
          initOnePlusOne()
          setIsButtonDisable(false)
        }
      },
      false,
    )
  }, [token])

  const checkInMissionButton = async () => {
    if (onePlusOneTeamInfo?.invitationInfo?.isItemReceived) {
      alert.show('이미 상품 주문을 완료했어요', {
        closeCopy: '확인',
      })
    } else if (
      !onePlusOneTeamInfo?.invitationInfo?.isItemReceived &&
      onePlusOneTeamInfo?.invitationInfo?.missionStatus === 'success'
    ) {
      window.location.href = `#toOrderMain.${JSON.stringify({
        props: {
          // dev 에서는 60edc235ffc0a087c1716690 production에서는 63b298e674f6779703d7e74d
          itemId: onePlusOneTeamInfo?.invitationInfo?.orderItemId,
          enteringComponent: 'onePlusOne',
          type: 'onePlusOne',
          invitorId: null,
          eventPageMapping: 'onePlusOne',
        },
      })}`
    } else if (
      !onePlusOneTeamInfo?.invitationInfo?.isItemReceived &&
      onePlusOneTeamInfo?.invitationInfo?.missionStatus === 'fail'
    ) {
      alert.show(
        '연속 출석체크 미션에 실패했어요. 새친구에게 공유하고 1+1 상품을 받아보세요',
        {
          closeCopy: '확인',
        },
      )
    } else if (
      onePlusOneTeamInfo?.invitationInfo?.checkInHistory?.some(
        (each) => each === new Date().toISOString().slice(0, 10),
      )
    ) {
      alert.show('오늘은 이미 출석체크를 완료했어요. 내일 다시 시도해주세요', {
        closeCopy: '확인',
      })
    } else {
      window.location.href = `#navigate.${JSON.stringify({
        screen: 'NewDailyCheckInScreen',
        prop: { origin: 'onePlusOne' },
      })}`
      const result = await backendApis.setOnePlusOneCheckInMission(token)
      setOnePlusOneTeamInfo(result?.data)
    }
  }

  return (
    <>
      <Layout>
        <Notice
          onClick={() => {
            setShowOnePlusOneExplainModal(true)
          }}
        />
        <Header title='1+1 이벤트' />
        <div
          style={{
            height: '100%',
            backgroundColor: '#F7F3E8',
            marginBottom: 10,
            fontFamily: 'GmarketSans',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'scroll',
          }}
        >
          <div
            style={{
              padding: 10,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              type='button'
              style={{
                textAlign: 'center',
                color: 'white',
                fontWeight: 700,
                backgroundColor: '#EB4F47',
                marginTop: 0,
                padding: 8,
                paddingLeft: 16,
                paddingRight: 16,
                border: 'none',
                borderRadius: 4,
                fontSize: 16,
                fontFamily: 'GmarketSans',
              }}
              onClick={() => {
                setShowOnePlusOneCloseNoticeModal(true)
              }}
            >
              무료상품 이벤트 종료 안내 (2월 2일 종료)
            </button>
          </div>
          {/* <img
            src='/images/topLineImage.png'
            alt=''
            style={{
              width: '100vw',
              border: 'none',
            }}
          /> */}
          <div
            style={{
              fontSize: 32,
              marginTop: 12,
              color: '#704410',
              fontWeight: 'bold',
            }}
          >
            친구도 나도 받는 <span style={{ color: '#FF8A00' }}>1+1</span>
          </div>
          <div
            style={{
              fontSize: 16,
              marginTop: 8,
              marginBottom: 32,
              fontWeight: 'bold',
              color: '#978143',
            }}
          >
            {onePlusOneTeamInfo &&
            onePlusOneTeamInfo?.invitationInfo?.missionClearDaysNum === 0 ? (
              <>출석체크를 하고 1+1 상품을 받아가세요</>
            ) : (
              <>
                지금까지{' '}
                {onePlusOneTeamInfo?.invitationInfo?.missionClearDaysNum || 0}일
                연속 출석체크에 성공했어요
              </>
            )}
          </div>
          <div
            style={{
              backgroundColor: '#fff',
              width: '88vw',
              height: '56vh',
              borderRadius: 20,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: 'GmarketSans',
              // overflowY: 'scroll',
              paddingTop: 8,
              paddingBottom: 16,
            }}
          >
            <div
              style={{
                height: 20,
                marginTop: 20,
                paddingBottom: 12,
                borderBottom: '1px solid rgba(64, 55, 58, 0.1)',
                fontWeight: 'bold',
              }}
            >
              {onePlusOneItemInfo?.mainTitle} {onePlusOneItemInfo?.subTitle}{' '}
              <span style={{ color: '#FF8A00' }}>1+1</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {onePlusOneTeamInfo?.invitationInfo?.missionStatus !==
                'onGoing' && (
                <>
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: 3,
                    }}
                  >
                    <img
                      alt=''
                      src={`${
                        onePlusOneTeamInfo?.invitationInfo?.missionStatus ===
                        'success'
                          ? '/images/missionSuccess.png'
                          : '/images/missionFail.png'
                      }`}
                      style={{ height: 60, marginTop: 12 }}
                    />
                  </div>
                </>
              )}
              <img
                src={`${onePlusOneItemInfo?.mainImageUri}`}
                alt=''
                style={{ height: 168, marginBottom: -12 }}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                fontSize: 12,
                color: '#9d9699',
              }}
            >
              *1+1 이벤트 페이지를 통한 출석체크만 인정돼요
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '80vw' }}
            >
              <button
                type='button'
                style={{
                  border: 'none',
                  background: 'none',
                  backgroundColor: '#FF4D00',
                  // width: '80vw',
                  height: 48,
                  marginTop: 12,
                  borderRadius: 12,
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: 16,
                  flex: 1,
                }}
                onClick={() => {
                  if (isButtonDisable) {
                    return
                  }
                  setIsButtonDisable(true)
                  debounce(checkInMissionButton, 500)
                }}
              >
                {onePlusOneTeamInfo?.invitationInfo?.missionStatus !==
                'onGoing' ? (
                  <>
                    {' '}
                    <div
                      style={{
                        fontFamily: 'GmarketSans',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {onePlusOneTeamInfo?.invitationInfo?.missionStatus ===
                      'success' ? (
                        <>
                          {onePlusOneTeamInfo?.invitationInfo?.isItemReceived
                            ? '이미 상품을 받았어요'
                            : '상품 받으러가기'}{' '}
                        </>
                      ) : (
                        <>연속 출석체크 미션에 실패했어요</>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {' '}
                    <div
                      style={{
                        fontFamily: 'GmarketSans',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        style={{ height: 24, marginRight: 4 }}
                        alt=''
                        src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/c96146d5-9953-4d27-a79b-6b57d6d34ddb.png'
                      />
                      {onePlusOneTeamInfo?.invitationInfo?.checkInHistory?.some(
                        (each) =>
                          each === new Date().toISOString().slice(0, 10),
                      ) ? (
                        <>
                          내일 다시 출석하기{' '}
                          {onePlusOneTeamInfo?.invitationInfo
                            ?.missionClearDaysNum !== 0 && (
                            <>
                              (
                              {onePlusOneTeamInfo?.invitationInfo
                                ?.missionClearDaysNum || 0}
                              일/
                              {onePlusOneTeamInfo?.invitationInfo
                                ?.missionDaysNum || 1}
                              일)
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          출석체크 하기{' '}
                          {onePlusOneTeamInfo?.invitationInfo
                            ?.missionClearDaysNum !== 0 && (
                            <>
                              (
                              {onePlusOneTeamInfo?.invitationInfo
                                ?.missionClearDaysNum || 0}
                              일/
                              {onePlusOneTeamInfo?.invitationInfo
                                ?.missionDaysNum || 1}
                              일)
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
              </button>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '80vw',
              }}
            >
              <>
                {' '}
                <button
                  type='button'
                  style={{
                    border: 'none',
                    background: 'none',
                    backgroundColor: '#40373A',
                    height: 48,
                    marginTop: 8,
                    borderRadius: 12,
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: 16,
                    flex: 1,
                  }}
                  onClick={() => {
                    window.location.href = `/one-plus-one/?token=${token}&isFrom=invitation&randomString=${Math.random()
                      .toString(36)
                      .substr(2, 8)}`
                  }}
                >
                  <div style={{ fontFamily: 'GmarketSans' }}>
                    나도 친구 데려와서 2배로 받기
                  </div>
                </button>
              </>
            </div>
          </div>
          <div
            style={{
              backgroundColor: '#fff',
              borderTopRightRadius: 20,
              borderTopLeftRadius: 20,
              marginTop: 20,
              height: 148,
              width: '100vw',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingBottom: 100,
            }}
          >
            <div
              style={{
                fontWeight: 'bold',
                fontSize: 20,
                margin: 16,
                paddingBottom: 8,
                width: '68vw',
                textAlign: 'center',
                borderBottom: '1px solid rgba(64, 55, 58, 0.1)',
              }}
            >
              참여방법
            </div>
            <div
              style={{
                fontSize: 12,
                width: '68vw',
                height: 80,
                color: '#63565B',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#F7F3E8',
                    color: '#63565B',
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 8,
                  }}
                >
                  1
                </div>{' '}
                <div>올웨이즈에 가입하여 출석체크를 해요</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#F7F3E8',
                    color: '#63565B',
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 8,
                  }}
                >
                  2
                </div>{' '}
                <div>
                  {onePlusOneItemInfo?.missionDaysNum}일 연속 출석체크 미션을
                  완료해요
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#F7F3E8',
                    color: '#63565B',
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 8,
                  }}
                >
                  3
                </div>{' '}
                <div>친구와 나 모두에게 선물을 드려요</div>
              </div>
            </div>
          </div>
        </div>
        {showOnePlusOneSendHistoryModal && (
          <OnePlusOneSendHistoryModal
            setShowOnePlusOneSendHistoryModal={
              setShowOnePlusOneSendHistoryModal
            }
            token={token}
            guestInfosArray={onePlusOneTeamInfo?.guestInfosArray}
          />
        )}
        {showOnePlusOneExplainModal && (
          <OnePlusOneExplainModal
            setShowOnePlusOneExplainModal={setShowOnePlusOneExplainModal}
          />
        )}
        {showOnePlusOneCloseNoticeModal && (
          <OnePlusOneCloseNoticeModal
            setShowOnePlusOneCloseNoticeModal={
              setShowOnePlusOneCloseNoticeModal
            }
            token={token}
          />
        )}
      </Layout>
    </>
  )
}
export default OnePlusOneInvitation
