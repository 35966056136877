import React, { useState } from 'react'

const LuckyBoxNotAllowModal = ({
  setShowLuckyBoxNotAllowModal,
  token,
  randomString,
}) => (
  <>
    <div
      style={{
        flex: 1,
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 3,
        backgroundColor: 'rgba(0,0,0,0.8)',
        width: '100%',
        height: '100%',
      }}
    >
      <button
        type='button'
        style={{
          width: 100,
          height: 100,
          backgroundColor: 'red',
          background: 'none',
          border: 'none',
          top: '33%',
          right: '1%',
          position: 'absolute',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          setShowLuckyBoxNotAllowModal(false)
          window.location.href = `/luckyBoxSplash/?token=${token}&randomString=${Math.random()
            .toString(36)
            .substr(2, 8)}`
        }}
      >
        <img
          style={{
            zIndex: 101,
            width: '36%',
            height: '36%',
            position: 'absolute',
          }}
          src='/images/closeIcon.png'
          alt=''
        />
      </button>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          height: '100%',
          padding: 24,
          boxSizing: 'border-box',
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            zIndex: 4,
            // top: '24%',
            textAlign: 'center',
            paddingTop: 20,
            // paddingBottom: 18,
            borderRadius: 20,
            width: '90vw',
            height: '26vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ zIndex: 5 }}>
            <img
              src='/images/LuckyBox.png'
              alt=''
              style={{
                height: 100,
                width: 100,
                marginTop: -64,
                // marginBottom: 28,
              }}
            />
          </div>
          <div
            style={{
              fontFamily: 'GmarketSans',
              fontWeight: 500,
              color: 'black',
              lineHeight: 1.4,
              fontSize: 16,
            }}
          >
            행운 상자를 받은 이력이 있어
          </div>
          <div
            style={{
              fontFamily: 'GmarketSans',
              fontWeight: 500,
              color: 'black',
              lineHeight: 1.4,
              //   marginTop: 8,
            }}
          >
            선물을 해야 행운상자를 받을 수 있어요
          </div>

          <div
            style={{
              fontFamily: 'GmarketSans',
              fontWeight: 900,
              fontSize: 20,
              color: 'black',
              lineHeight: 1.4,
              marginTop: 16,
              marginBottom: 28,
            }}
          >
            행운 상자를 선물하러 가볼까요?
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <button
              type='button'
              style={{
                bottom: '0',
                right: '0',
                fontSize: 18,
                borderBottomLeftRadius: 20,
                // borderBottomRightRadius: 20,
                height: 48,
                borderWidth: 0,
                background: '#8C8D8F',
                width: '100%',
                paddingTop: 20,
                paddingBottom: 40,
              }}
              onClick={() => {
                setShowLuckyBoxNotAllowModal(false)
                window.location.href = `/luckyBoxSplash/?token=${token}&randomString=${Math.random()
                  .toString(36)
                  .substr(2, 8)}`
              }}
            >
              <div
                style={{
                  color: 'white',
                  fontSize: 20,
                  fontWeight: 'bold',
                  paddingLeft: 8,
                  paddingRigtt: 8,
                }}
              >
                나중에 하기
              </div>
            </button>
            <button
              type='button'
              style={{
                bottom: '0',
                right: '0',
                fontSize: 18,
                // borderBottomLeftRadius: 20,
                borderBottomRightRadius: 20,
                height: 48,
                borderWidth: 0,
                background: '#FF8808',
                width: '100%',
                paddingTop: 20,
                paddingBottom: 40,
              }}
              onClick={() => {
                window.location.href = `/luckyBoxSplash/?token=${token}&randomString=${Math.random()
                  .toString(36)
                  .substr(2, 8)}`
              }}
            >
              <div
                style={{
                  color: 'white',
                  fontSize: 20,
                  fontWeight: 'bold',
                  paddingLeft: 8,
                  paddingRigtt: 8,
                }}
              >
                선물하러 가기
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default LuckyBoxNotAllowModal
