import React, { useState } from 'react'
import commaNumber from 'comma-number'
import { useAlert } from 'react-alert'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'

const SupportFundThrowModal = ({
  setShowSupportFundThrowModal,
  remainedCount,
}) => (
  <>
    <div
      style={{
        flex: 1,
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 3,
        backgroundColor: 'rgba(0,0,0,0.8)',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {' '}
      <button
        type='button'
        style={{
          width: 100,
          height: 100,
          backgroundColor: 'red',
          background: 'none',
          border: 'none',
          top: '14%',
          right: '1%',
          position: 'absolute',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          setShowSupportFundThrowModal(false)
        }}
      >
        <img
          style={{
            zIndex: 101,
            width: '36%',
            height: '36%',
            position: 'absolute',
          }}
          src='/images/closeCircleIcon.png'
          alt=''
        />
      </button>
      <div
        style={{
          backgroundColor: 'white',
          width: '76vw',
          height: '32vh',
          borderRadius: 12,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: 'red',
          }}
        >
          <img
            alt=''
            style={{ width: 140, marginTop: -52 }}
            src='/images/SupportFundWithdrawSmallImage.png'
          />
          <div
            style={{
              textAlign: 'center',
              fontFamily: 'GmarketSans',
              fontWeight: 700,
              lineHeight: 1.3,
            }}
          >
            <div>
              친구 3명에게 <span style={{ color: '#EB4F47' }}>공짜 돈</span>을
              뿌리면
            </div>
            <div>나도 돈을 받을 수 있어요!</div>
          </div>
          <div
            style={{
              textAlign: 'center',
              fontFamily: 'GmarketSans',
              fontSize: 12,
              color: '#79765F',
              marginTop: 8,
              lineHeight: 1.3,
            }}
          >
            <div>
              <span style={{ fontWeight: 900 }}>친구 3명에게</span> 링크를
              공유해보세요
            </div>
            <div>친구가 돈을 받으면 나도 돈을 받아요</div>
          </div>
          {remainedCount > 0 ? (
            <>
              <div
                style={{
                  backgroundColor: '#EB4F47',
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: 8,
                  margin: 8,
                  marginTop: 16,
                  marginBottom: 12,
                  width: '72%',
                }}
              >
                <button
                  type='button'
                  style={{
                    backgroundColor: '#EB4F47',
                    width: '100%',
                    height: '100%',
                    padding: 8,
                    paddingTop: 12,
                    paddingBottom: 12,
                    borderRadius: 8,
                    background: 'none',
                    border: 'none',
                    color: 'white',
                    fontFamily: 'GmarketSans',
                    fontWeight: 900,
                    fontSize: 16,
                  }}
                  onClick={() => {
                    window.location.href = `#openBottomShareSheet.${JSON.stringify(
                      {
                        code: 'supportFundThrow',
                      },
                    )}`
                  }}
                >
                  공짜 지원금 뿌리기
                </button>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  backgroundColor: '#BEC0C2',
                  padding: 8,
                  paddingTop: 12,
                  paddingBottom: 12,
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: 8,
                  margin: 8,
                  marginTop: 16,
                  marginBottom: 12,
                  width: '72%',
                }}
              >
                <button
                  type='button'
                  style={{
                    background: 'none',
                    border: 'none',
                    color: 'white',
                    fontFamily: 'GmarketSans',
                    fontWeight: 900,
                    fontSize: 16,
                  }}
                  onClick={() => {
                    window.location.href = `#openBottomShareSheet.${JSON.stringify(
                      {
                        code: 'supportFundInvite',
                      },
                    )}`
                  }}
                >
                  기회를 모두 사용했어요
                </button>
              </div>
            </>
          )}

          {remainedCount > 0 ? (
            <>
              {' '}
              <div
                style={{
                  fontSize: 12,
                  color: '#79765F',
                  fontFamily: 'GmarketSans',
                }}
              >
                <span style={{ color: '#EB4F47' }}>{remainedCount}명에게</span>{' '}
                더 뿌릴 수 있어요
              </div>
            </>
          ) : (
            <>
              {' '}
              <div
                style={{
                  fontSize: 12,
                  color: '#79765F',
                  fontFamily: 'GmarketSans',
                }}
              >
                오늘은 더 이상 돈을 뿌릴 수 없어요
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  </>
)

export default SupportFundThrowModal
