const Notice = ({ onClick }) => (
  <div>
    <button
      type='button'
      style={{
        width: 100,
        height: 100,
        backgroundColor: 'red',
        background: 'none',
        border: 'none',
        top: '-3.5%',
        right: '-5%',
        position: 'absolute',
        zIndex: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={() => {
        onClick()
      }}
    >
      <img
        style={{
          zIndex: 101,
          width: '36%',
          height: '36%',
          position: 'absolute',
        }}
        src='/images/questionMarkIcon.png'
        alt=''
      />
    </button>
  </div>
)

export default Notice
