import React, { useState } from 'react'
import commaNumber from 'comma-number'
import { useAlert } from 'react-alert'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'

const SupportFundGetMoneyModal = ({
  setShowSupportFundGetMoneyModal,
  discountPrice,
}) => (
  <>
    <div
      style={{
        flex: 1,
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 3,
        backgroundColor: 'rgba(0,0,0,0.8)',
        width: '100%',
        height: '100%',
      }}
    >
      {' '}
      <button
        type='button'
        style={{
          width: 100,
          height: 100,
          backgroundColor: 'red',
          background: 'none',
          border: 'none',
          top: '14%',
          right: '1%',
          position: 'absolute',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          setShowSupportFundGetMoneyModal(false)
        }}
      >
        <img
          style={{
            zIndex: 101,
            width: '36%',
            height: '36%',
            position: 'absolute',
          }}
          src='/images/closeCircleIcon.png'
          alt=''
        />
      </button>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100vh',
          alignItems: 'center',
          //   backgroundColor: 'red',
        }}
      >
        <div
          style={{
            fontSize: 24,
            fontFamily: 'GmarketSans',
            color: 'white',
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          <div>
            <span style={{ color: '#EB4F47' }}>{discountPrice}원</span>을
            받았어요!
          </div>
        </div>
        <div
          style={{
            // backgroundColor: 'red',
            width: 264,
            height: 188,
            backgroundSize: 'cover',
            backgroundImage: "url('/images/SupportFundWithdrawImage.png')",
            marginBottom: 12,
            marginTop: 16,
            position: 'relative',
          }}
        />
        <div
          style={{
            color: '#422E02',
            fontFamily: 'GmarketSans',
            fontWeight: 700,
            position: 'absolute',
            top: '42%',
            // left: '38%'
            justifyContent: 'center',
            fontSize: 28,
            zIndex: 102,
          }}
        >
          {discountPrice}원
        </div>
        <div
          style={{
            backgroundColor: '#BEC0C2',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: 8,
            margin: 8,
            marginBottom: 64,
            width: '64%',
          }}
        >
          <button
            type='button'
            style={{
              background: '#BEC0C2',
              width: '100%',
              height: '100%',
              borderRadius: 8,
              padding: 8,
              paddingTop: 12,
              paddingBottom: 12,
              border: 'none',
              color: 'white',
              fontFamily: 'GmarketSans',
              fontWeight: 900,
              fontSize: 16,
            }}
            onClick={() => {
              setShowSupportFundGetMoneyModal(false)
            }}
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  </>
)

export default SupportFundGetMoneyModal
