import React, { useState, useEffect } from 'react'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import Layout from '../components/layout'
import Header from '../components/header'
import Footer from '../components/footer'
import { useAlert } from 'react-alert'
import LuckyBoxSelectModal from '../modals/LuckyBoxSelectModal'
import LuckyBoxNewUserNudgingModal from '../modals/LuckyBoxNewUserNudgingModal'
import LuckyBoxCloseNoticeModal from '../modals/LuckyBoxCloseNoticeModal'
import * as Hangul from 'hangul-js'

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const matchSearchTermWithString = (name, phoneNumber, searchTerm) => {
  if (Number(searchTerm)) {
    const numberSearchResult = Hangul.search(
      phoneNumber.replace(/\s/g, '').toUpperCase(),
      searchTerm.replace(/\s/g, '').toUpperCase(),
    )
    return numberSearchResult >= 0
  }
  const nameSearchResult = Hangul.search(
    name.replace(/\s/g, '').toUpperCase(),
    searchTerm.replace(/\s/g, '').toUpperCase(),
  )
  return nameSearchResult >= 0
}

const SearchComponent = ({ searchTerm, setSearchTerm }) => (
  <>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginTop: 8,
        marginBottom: 20,
      }}
    >
      <div
        style={{
          backgroundColor: '#F5F5F5',
          width: '90%',
          borderRadius: 8,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <img
          src='/images/searchIcon.png'
          alt=''
          style={{ height: 28, width: 28, margin: 8 }}
        />
        <input
          style={{
            fontFamily: 'GmarketSans',
            border: 'none',
            backgroundColor: '#F5F5F5',
            zIndex: 101,
          }}
          name='search'
          value={searchTerm}
          placeholder='이름/전화번호로 친구 찾기'
          onChange={(e) => {
            e.preventDefault()
            setSearchTerm(e.target.value)
          }}
        />
      </div>
    </div>
  </>
)

function luckyBoxEvent() {
  const alert = useAlert()
  const token = useSearchParam('token')
  const isFrom = useSearchParam('isFrom')
  const alwayzInvitorId = useSearchParam('alwayzInvitorId')
  const documentId = useSearchParam('documentId')
  const [luckyItems, setLuckyItems] = useState([])
  const [friendsList, setFriendsList] = useState([])
  const [receiveLuckyBoxCount, setReceiveLuckyBoxCount] = useState([])
  const [modalInfo, setModalInfo] = useState({ visible: false, data: {} })
  const [searchTerm, setSearchTerm] = useState('')
  // const userId = useSearchParam('userId')
  const [userId, setUserId] = useState()
  const [tierIndex, setTierIndex] = useState(1)
  const [showLuckyBoxNewUserNudgingModal, setShowLuckyBoxNewUserNudgingModal] =
    useState(false)
  const [showLuckyBoxCloseNoticeModal, setShowLuckyBoxCloseNoticeModal] =
    useState(false)
  const contactData =
    friendsList?.filter((contact) => {
      if (searchTerm?.length > 2) {
        return matchSearchTermWithString(
          contact?.receiverName,
          contact?.receiverPhoneNumber,
          searchTerm,
        )
      }
      return true
    }) || []

  const initializeLuckyBoxTeamInfo = async () => {
    const userInfo = await backendApis.getUserBasicInfoWithAuthentication(token)
    if (userInfo?.status === 200) {
      setUserId(userInfo?.data?._id)
    }
    if (documentId) {
      window.location.href = `/LuckyBoxEventInvitation/?token=${token}&documentId=${documentId}&userId=${userId}&alwayzInvitorId=${alwayzInvitorId}&randomString=${Math.random()
        .toString(36)
        .substr(2, 8)}`
      return
    }
    if (isFrom) {
      setShowLuckyBoxNewUserNudgingModal(true)
      return
    }
    const result = await backendApis.getLuckyBoxTeamInfo(token, 'GET')
    if (!result?.data && !isFrom) {
      window.location.href = `/LuckyBoxEventIntro/?token=${token}&randomString=${Math.random()
        .toString(36)
        .substr(2, 8)}`
    } else {
      setReceiveLuckyBoxCount(
        result?.data?.receiveCount - (result?.data?.openCount || 0) * 5,
      )
      let onGoing = true
      let chunk = 0
      while (onGoing) {
        const friendsListResult = await backendApis.getFriendsList(
          token,
          'GET',
          { loadedNum: chunk },
        )
        if (
          friendsListResult?.status === 200 &&
          friendsListResult?.data?.length
        ) {
          setFriendsList((prev) => {
            const phones = prev?.map((each) => each?.receiverPhoneNumber)
            const next = friendsListResult?.data?.filter(
              (each) => !phones.includes(each?.receiverPhoneNumber),
            )
            return [...prev, ...next]
          })
          chunk += 32
          await sleep(5000)
        } else {
          onGoing = false
        }
      }
    }
  }

  useEffect(() => {
    initializeLuckyBoxTeamInfo()
    const fetchItems = async () => {
      const result = await backendApis.getLuckyBoxItemTotalListInfo(
        token,
        'GET',
      )
      if (result?.status === 200 && result?.data?.length) {
        setLuckyItems(result?.data)
      }
    }
    fetchItems()
  }, [])

  const ItemComponent = ({ itemTitle, mainImageUri }) => (
    <>
      <div
        style={{
          width: '30%',
          height: '100%',
        }}
      >
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            height: '70%',
            marginTop: 8,
          }}
        >
          <img src={mainImageUri} alt='' style={{ height: 72, width: 72 }} />
        </div>
        <div
          style={{
            textAlign: 'center',
            fontFamily: 'GmarketSans',
            fontWeight: 300,
            fontSize: 12,
            color: 'black',
            marginTop: 8,
          }}
        >
          {itemTitle}
        </div>
      </div>
    </>
  )

  const LuckyBoxComponent = ({ luckyItems }) => (
    <>
      <div
        style={{
          backgroundColor: 'white',
          width: '72vw',
          height: '28vh',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 8,
          padding: 2,
        }}
      >
        <div
          style={{
            textAlign: 'center',
            borderWidth: 1.5,
            fontFamily: 'GmarketSans',
            fontWeight: 700,
            marginTop: 12,
            marginBottom: 4,
          }}
        >
          <span style={{ color: '#E63423', paddingRight: 4 }}>
            100% 당첨되는
          </span>
          행운상자
        </div>
        <div
          style={{
            textAlign: 'center',
            borderWidth: 1.5,
            fontFamily: 'GmarketSans',
            fontWeight: 700,
          }}
        >
          <span style={{ color: '#E63423', paddingRight: 4 }}>
            {tierIndex}순위
          </span>
          상품
        </div>

        <div
          style={{
            borderBottom: '#ebebeb solid',
            borderBottomWidth: 1.5,
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 4,
            boxSizing: 'border-box',
            padding: 4,
          }}
        />
        <div
          style={{
            width: '100%',
            height: '50%',

            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {luckyItems?.map((each) => (
            <ItemComponent
              itemTitle={each?.itemTitle}
              mainImageUri={each?.mainImageUri[0]}
            />
          ))}
        </div>
      </div>
    </>
  )

  const FriendComponent = ({ data }) => {
    const { isSent, receiverName, receiverPhoneNumber } = data
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 4,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {isSent ? (
              <img
                src='/images/colorGiftBoxIcon.png'
                alt=''
                style={{ height: 40, width: 40, margin: 8 }}
              />
            ) : (
              <img
                src='/images/darkGiftBoxIcon.png'
                alt=''
                style={{ height: 40, width: 40, margin: 8 }}
              />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginLeft: 8,
              marginRight: 8,
            }}
          >
            <div style={{ fontFamily: 'GmarketSans', fontSize: 14 }}>
              {receiverName}
            </div>
            <div style={{ height: 2 }} />
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontSize: 12,
                color: '#C2C2C2',
              }}
            >
              {receiverPhoneNumber}
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {isSent ? (
              <button
                type='button'
                disabled
                style={{
                  fontFamily: 'GmarketSans',
                  color: 'white',
                  fontWeight: 700,
                  height: '60%',
                  backgroundColor: '#E63323',
                  border: 'none',
                  marginRight: 8,
                  borderRadius: 4,
                  paddingLeft: 24,
                  paddingRight: 24,
                  fontSize: 16,
                }}
              >
                선물완료
              </button>
            ) : (
              <button
                type='button'
                style={{
                  fontFamily: 'GmarketSans',
                  color: 'white',
                  fontWeight: 700,
                  height: '60%',
                  backgroundColor: '#FF8808',
                  border: 'none',
                  marginRight: 8,
                  borderRadius: 4,
                  paddingLeft: 24,
                  paddingRight: 24,
                  fontSize: 16,
                }}
                onClick={() => setModalInfo({ visible: true, data })}
              >
                선물 고르기
              </button>
            )}
          </div>
        </div>
      </>
    )
  }

  const ReceiveLuckyBoxStatusComponent = ({ receiveCount = 0 }) => {
    const Received = () => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingRight: 4,
          paddingLeft: 4,
        }}
      >
        <img
          src='/images/ReceivedLuckyBox.png'
          alt='ReceivedLuckyBox'
          style={{
            paddingTop: 4,
            paddingBottom: 4,
            width: 60,
            // boxSizing: 'border-box',
          }}
        />
        <img
          src='/images/ReceivedLuckyBoxText.png'
          alt='ReceivedLuckyBoxText'
          style={{
            paddingTop: 4,
            paddingBottom: 4,
            width: 60,
            // boxSizing: 'border-box',
          }}
        />
      </div>
    )
    const Left = ({ count }) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingRight: 4,
          paddingLeft: 4,
        }}
      >
        <img
          src='/images/LeftLuckyBox.png'
          alt='LeftLuckyBox'
          style={{
            paddingTop: 4,
            paddingBottom: 4,
            width: 60,
            // boxSizing: 'border-box',
          }}
        />
        <div
          style={{
            fontFamily: 'GmarketSans',
            fontWeight: 500,
            marginTop: 8,
            fontSize: 14,
            color: 'white',
            textAlign: 'center',
          }}
        >
          친구 {count}
        </div>
      </div>
    )
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {[1, 2, 3, 4, 5].slice(0, receiveCount).map((el) => (
          <Received />
        ))}
        {[1, 2, 3, 4, 5].slice(receiveCount, 5).map((el) => (
          <Left count={el} />
        ))}
      </div>
    )
  }

  return (
    <>
      <Layout>
        <Header title='행운상자 선물하기' />
        <div style={{ height: '93%', overflowY: 'scroll' }}>
          <div
            style={{
              backgroundColor: 'rgba(0,0,0,0.8)',
              padding: 10,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              type='button'
              style={{
                textAlign: 'center',
                color: 'white',
                fontFamily: 'GmarketSans',
                fontWeight: 700,
                backgroundColor: '#E63323',
                padding: 8,
                paddingLeft: 16,
                paddingRight: 16,
                border: 'none',
                borderRadius: 4,
                fontSize: 16,
              }}
              onClick={() => setShowLuckyBoxCloseNoticeModal(true)}
            >
              행운상자 종료 안내 (12월 16일 종료)
            </button>
          </div>{' '}
          <div
            style={{
              display: 'flex',
              // height: '300vh',
              width: '100vw',
              background:
                'linear-gradient(180deg, #f67815 0%, #f98611 3.65%,#E63323 94.79%, #e83b21 100%)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div style={{ height: 120, marginTop: 20, marginBottom: 12 }}>
              <div
                style={{
                  width: '100vw',
                  height: 36,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    textAlign: 'center',
                    paddingTop: 8,
                    paddingBottom: 8,
                    paddingLeft: 16,
                    paddingRight: 16,
                    borderRadius: 24,
                    backgroundColor: '#ED531C',
                    color: 'white',
                    fontFamily: 'GmarketSans',
                    marginBottom: 8,
                  }}
                >
                  꽝이 없어요!
                </div>
              </div>
              <div
                style={{
                  fontFamily: 'GmarketSans',
                  fontWeight: 500,
                  fontSize: 32,
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                올웨이즈 <span style={{ fontWeight: 900 }}>행운상자</span>
              </div>
              <div
                style={{
                  fontFamily: 'GmarketSans',
                  fontWeight: 500,
                  fontSize: 14,
                  color: 'white',
                  textAlign: 'center',
                  paddingTop: 4,
                  paddingBottom: 2,
                }}
              >
                행운상자를 친구에게 선물하세요.
              </div>
              <div
                style={{
                  fontFamily: 'GmarketSans',
                  fontWeight: 500,
                  fontSize: 14,
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                선택한 상품 중 1개를
                <span
                  style={{
                    fontWeight: 900,
                    marginLeft: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    backgroundColor: '#ED531C',
                  }}
                >
                  100% 확률
                </span>
                로 보내드려요.
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'center',
                alignItems: 'center',
              }}
            >
              <button
                type='button'
                style={{
                  width: 50,
                  height: 50,
                  background: 'none',
                  border: 'none',
                }}
                onClick={() => {
                  if (tierIndex < 2) {
                    setTierIndex(5)
                  } else {
                    setTierIndex(tierIndex - 1)
                  }
                }}
              >
                <img
                  src='/images/leftArrow.png'
                  alt=''
                  style={{ height: 28, width: 28 }}
                />
              </button>
              <LuckyBoxComponent
                luckyItems={luckyItems?.filter(
                  (each) => each?.tier === tierIndex,
                )}
              />
              <button
                type='button'
                style={{
                  width: 50,
                  height: 50,
                  background: 'none',
                  border: 'none',
                }}
                onClick={() => {
                  if (tierIndex < 5) {
                    setTierIndex(tierIndex + 1)
                  } else {
                    setTierIndex(1)
                  }
                }}
              >
                <img
                  src='/images/rightArrow.png'
                  alt=''
                  style={{ height: 28, width: 28 }}
                />
              </button>
            </div>
            <div style={{ height: 60, marginBottom: 0 }}>
              <div
                style={{
                  width: '100vw',
                  height: 16,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />

              <div
                style={{
                  fontFamily: 'GmarketSans',
                  fontWeight: 500,
                  fontSize: 14,
                  color: 'white',
                  textAlign: 'center',
                  paddingTop: 4,
                  paddingBottom: 2,
                }}
              >
                내가 담은 행운상자를{' '}
                <span
                  style={{
                    fontWeight: 900,
                    marginLeft: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    backgroundColor: '#ED531C',
                  }}
                >
                  친구 5명
                </span>
                이 받으면
              </div>
              <div
                style={{
                  fontFamily: 'GmarketSans',
                  fontWeight: 500,
                  fontSize: 14,
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                나도 행운상자를 1개 열 수 있어요
              </div>
            </div>
            <div style={{ marginTop: 0 }}>
              {receiveLuckyBoxCount > 4 ? (
                <>
                  <button
                    type='button'
                    style={{
                      margin: 16,
                      paddingTop: 12,
                      paddingBottom: 12,
                      paddingLeft: 20,
                      paddingRight: 20,
                      borderRadius: 8,
                      fontFamily: 'GmarketSans',
                      fontWeight: 700,
                      border: 'none',
                      fontSize: 20,
                      color: 'white',
                      textAlign: 'center',
                      backgroundColor: '#FF8808',
                    }}
                    onClick={() => {
                      setModalInfo({ visible: true, data: {} })
                    }}
                  >
                    내 행운상자 열어보기
                  </button>
                </>
              ) : (
                <>
                  <button
                    type='button'
                    style={{
                      margin: 16,
                      paddingTop: 12,
                      paddingBottom: 12,
                      paddingLeft: 20,
                      paddingRight: 20,
                      borderRadius: 8,
                      fontFamily: 'GmarketSans',
                      fontWeight: 700,
                      border: 'none',
                      fontSize: 20,
                      color: 'white',
                      textAlign: 'center',
                      backgroundColor: '#BEC0C2',
                    }}
                    onClick={() => {
                      alert.show(
                        `친구 5명이 선물을 받으면 내 행운상자를 열 수 있어요!`,
                        {
                          closeCopy: '확인',
                        },
                      )
                    }}
                  >
                    내 행운상자 열어보기
                  </button>
                </>
              )}
            </div>
            <div style={{ height: 4 }} />
            <ReceiveLuckyBoxStatusComponent
              receiveCount={receiveLuckyBoxCount}
            />
            <div
              style={{
                backgroundColor: 'white',
                // backgroundColor:'black',
                width: '95vw',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 8,
                // margin: 8,
                marginTop: 20,
                padding: 2,
                marginBottom: 24,
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                  borderWidth: 1.5,
                  fontFamily: 'GmarketSans',
                  fontWeight: 700,
                  marginTop: 20,
                }}
              >
                선물하기 가능한
                <span style={{ color: '#E63423', paddingLeft: 4 }}>
                  친구목록
                </span>
              </div>
              <SearchComponent
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
              <div
                style={{
                  width: '100%',
                  height: '64%',
                  flexDirection: 'column',
                  display: 'flex',
                  justifyContent: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  backgroundColor: '#FFF',
                }}
              >
                {contactData?.map((each) => (
                  <FriendComponent key={each?._id} data={each} />
                ))}
              </div>
            </div>
          </div>
          <div
            style={{
              width: '100%',
              backgroundColor: '#F4F6F8',
              boxSizing: 'border-box',
              padding: 16,
              paddingBottom: 56,
            }}
          >
            <div style={{ fontSize: 18, fontWeight: 500, marginBottom: 8 }}>
              유의사항
            </div>
            <div style={{ lineHeight: '22.6px' }}>
              <div style={{ fontSize: 14, color: 'gray' }}>
                · 친구목록에 있는 신규 고객에게만 선물하기가 가능해요.
              </div>
              <div style={{ fontSize: 14, color: 'gray' }}>
                · 20세 이상의 고객에게만 선물하기가 가능해요.
              </div>
              <div style={{ fontSize: 14, color: 'gray' }}>
                · 친구목록에 있더라도 이미 행운상자를 받은 이력이 있으면
                행운상자를 받을 수 없어요
              </div>
              <div
                style={{
                  fontSize: 14,
                  color: 'gray',
                }}
              >
                · 상품은 영업일 기준 20일 내외로 발송되며 지정일 배송과 출고 후
                주소 변경은 불가능해요.
              </div>
              <div
                style={{
                  fontSize: 14,
                  color: 'gray',
                }}
              >
                · 사전에 고지된 상품의 옵션은 변경이 불가능해요.
              </div>
              <div
                style={{
                  fontSize: 14,
                  color: 'gray',
                }}
              >
                · 상품은 입력하신 주소지로 배송돼요.
              </div>
              <div
                style={{
                  fontSize: 14,
                  color: 'gray',
                }}
              >
                · 5명의 친구에게 행운상자를 선물하면 나도 행운상자를 1개 열 수
                있어요.
              </div>
              <div
                style={{
                  fontSize: 14,
                  color: 'gray',
                }}
              >
                · 가입한지 2시간 이내이며 올웨이즈의 타 이벤트 참여 이력이 없는
                고객만 신규 고객으로 인정돼요.
              </div>
              <div
                style={{
                  fontSize: 14,
                  color: 'gray',
                }}
              >
                · 이벤트가 시작된 이후 가입한 고객은 반드시 본인인증을
                진행해야만 신규 고객으로 인정돼요.
              </div>
              <div
                style={{
                  fontSize: 14,
                  color: 'gray',
                }}
              >
                · 탈퇴 후 재참여한 고객은 행운상자를 선물 받을 수 없어요.
              </div>
              <div
                style={{
                  fontSize: 14,
                  color: 'gray',
                }}
              >
                · 부정한 방법을 통한 참여는 인정되지 않으며, 당첨되어도 취소될
                수 있어요.
              </div>
              <div
                style={{
                  fontSize: 14,
                  color: 'gray',
                }}
              >
                · 재고 소진 시 유사 상품이나 해당 금액으로 대체하여 제공될 수
                있어요.
              </div>
              <div
                style={{
                  fontSize: 14,
                  color: 'gray',
                }}
              >
                · 10만원 이상의 상품에 대해서는 제세공과금이 발생할 수 있어요.
              </div>
              <div
                style={{
                  fontSize: 14,
                  color: 'gray',
                }}
              >
                · 본 이벤트는 당사의 사정에 의해 진행 중인 고객님이 있어도 사전
                고지 없이 변경 또는 조기종료될 수 있어요.
              </div>
              <div
                style={{
                  fontSize: 14,
                  color: 'gray',
                }}
              >
                · 문의는 내 정보 > 고객문의 > 1:1 실시간 상담하기에서 하실 수
                있어요.
              </div>
            </div>
          </div>
        </div>
        {showLuckyBoxNewUserNudgingModal && (
          <LuckyBoxNewUserNudgingModal
            setShowLuckyBoxNewUserNudgingModal={
              setShowLuckyBoxNewUserNudgingModal
            }
            token={token}
          />
        )}
        {showLuckyBoxCloseNoticeModal && (
          <LuckyBoxCloseNoticeModal
            setShowLuckyBoxCloseNoticeModal={setShowLuckyBoxCloseNoticeModal}
            token={token}
          />
        )}
      </Layout>
      <LuckyBoxSelectModal
        modalInfo={modalInfo}
        setModalInfo={setModalInfo}
        friendsList={friendsList}
        setFriendsList={setFriendsList}
        luckyItems={luckyItems}
        userId={userId}
      />
    </>
  )
}
export default luckyBoxEvent
