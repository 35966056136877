import React, { useState, useEffect } from 'react'

const Timer = ({
  timeStamp,
  onTimeEnd,
  interval = 100,
  timerMinutes = 60 * 24,
}) => {
  const [leftTime, setLeftTime] = useState(
    timeStamp.getTime() + 1000 * 60 * timerMinutes - Date.now(),
  )
  useEffect(() => {
    let isSubscribed = true
    const leftTimeInterval = setInterval(() => {
      if (isSubscribed) {
        // if (leftTime < 100) {
        //   ItemStore.setTargetItemInfo({
        //     ...ItemStore.targetItemInfo,
        //     teamDeals: ItemStore.targetItemInfo.teamDeals.filter(
        //       (teamDeal) => teamDeal._id !== teamDealId,
        //     ),
        //   })
        //   console.log(`team deal id: ${teamDealId}`)
        // }
        if (leftTime < 2 * interval) {
          onTimeEnd()
          isSubscribed = false
          clearInterval(leftTimeInterval)
        }

        const newLeftTime =
          timeStamp.getTime() + 1000 * 60 * timerMinutes - Date.now()
        // // setLeftTime(leftTime - 100)
        setLeftTime(newLeftTime)
        // fastFunc()
        // slowFunc()
      }
    }, interval)
    // const secondsTimeInterval = setInterval(() => {
    //   if (isSubscribed) {
    //     console.log(`seconds time interval`)
    //     const newLeftTime =
    //       timeStamp.getTime() + 1000 * 60 * 60 * 24 - Date.now()
    //     setLeftTime(newLeftTime)
    //   }
    // }, 1000)
    return () => {
      isSubscribed = false
      clearInterval(leftTimeInterval)
      //   clearInterval(secondsTimeInterval)
    }
  }, [leftTime])

  const timeFormatter = (time) => {
    if (time < 0) {
      if (interval === 100) {
        return `00:00:00.0`
      }
      if (interval === 1000) {
        return '00:00:00'
      }
    }
    const days = Math.floor(time / (1000 * 60 * 60 * 24))
    const hours = Math.floor(time / (1000 * 60 * 60))
    const minutes = Math.floor((time - hours * 60 * 60 * 1000) / (1000 * 60))
    const hoursText =
      hours - days * 24 < 10 ? `0${hours - days * 24}` : hours - days * 24
    const minutesText = minutes < 10 ? `0${minutes}` : minutes
    let secondsText
    if (interval === 100) {
      const tenTimesSeconds = Math.round(
        (time - hours * 60 * 60 * 1000 - minutes * 1000 * 60) / 100,
      )
      if (tenTimesSeconds < 100) {
        if (tenTimesSeconds < 10) {
          secondsText = `00.${tenTimesSeconds.toString().slice(0, 1)}`
        } else {
          secondsText = `0${tenTimesSeconds
            .toString()
            .slice(0, 1)}.${tenTimesSeconds.toString().slice(1, 2)}`
        }
      } else {
        secondsText = `${tenTimesSeconds
          .toString()
          .slice(0, 2)}.${tenTimesSeconds.toString().slice(2, 3)}`
      }
    } else if (interval === 1000) {
      const seconds = Math.floor(
        (time - hours * 60 * 60 * 1000 - minutes * 1000 * 60) / 1000,
      )
      secondsText = seconds < 10 ? `0${seconds}` : seconds
    }

    let formattedTime = `${hoursText}:${minutesText}:${secondsText}`
    if (days > 0) {
      if (hours - days * 24 === 0) {
        formattedTime = `${days}일 1시간`
      }
      formattedTime = `${days}일 ${hours - days * 24}시간`
    }

    // const secondsText =
    //   tenTimesSeconds < 100
    //     ? `0${tenTimesSeconds.toString().slice(0, 1)}.${tenTimesSeconds
    //         .toString()
    //         .slice(1, 2)}`
    //     : `${tenTimesSeconds.toString().slice(0, 2)}.${tenTimesSeconds
    //         .toString()
    //         .slice(2, 3)}`
    // console.log(`${hoursText}:${minutesText}:${secondsText}`)
    return formattedTime
  }
  return { leftTime, formattedTime: timeFormatter(leftTime) }
}

export default Timer
