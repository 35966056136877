import React, { useState, useEffect } from 'react'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import Layout from '../components/layout'
import Header from '../components/header'
import Notice from '../components/Notice'
import BlackTextTimer from '../components/BlackTextTimer'
import commaNumber from 'comma-number'
import { useAlert } from 'react-alert'
import useDebounce from '../components/useDebounce'
import GganbuChickenSelectModal from '../modals/GganbuChickenSelectModal'
import GganbuChickenSendHistoryModal from '../modals/GganbuChickenSendHistoryModal'
import GganbuChickenExplainModal from '../modals/GganbuChickenExplainModal'
import GganbuChickenCloseNoticeModal from '../modals/GganbuChickenCloseNoticeModal'

const isButtonDisable = false
let hidden
let visibilityChange
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

function GganbuChickenEvent() {
  const alert = useAlert()
  const token = useSearchParam('token')
  const alwayzInvitorId = useSearchParam('alwayzInvitorId')
  const itemId = useSearchParam('itemId')
  const isFrom = useSearchParam('isFrom')
  const code = useSearchParam('code')
  const [itemIndex, setItemIndex] = useState(1)
  const [gganbuChickenItemList, setGganbuChickenItemList] = useState('')
  const [gganbuChickenTeamInfo, setGganbuChickenTeamInfo] = useState('')
  const [userInfo, setUserInfo] = useState('')
  const [showGganbuChickenSelectModal, setShowGganbuChickenSelectModal] =
    useState(false)
  const [
    showGganbuChickenSendHistoryModal,
    setShowGganbuChickenSendHistoryModal,
  ] = useState(false)
  const [showGganbuChickenExplainModal, setShowGganbuChickenExplainModal] =
    useState(false)
  const [
    showGganbuChickenCloseNoticeModal,
    setShowGganbuChickenCloseNoticeModal,
  ] = useState(false)
  const initGganbuChicken = async () => {
    const gganbuChickenItemList = await backendApis.getGganbuChickenItemList(
      token,
    )
    setGganbuChickenItemList(gganbuChickenItemList?.data)
    const userInfo = await backendApis.getUserBasicInfoWithAuthentication(token)
    setUserInfo(userInfo?.data)

    if (
      userInfo?.status !== 200 ||
      userInfo?.data?.phoneNumber === '' ||
      userInfo?.data?.userName === ''
    ) {
      window.location.href = '#openLoginModal.'
    } else {
      const result = await backendApis.getGganbuChickenTeamInfo(token)
      setGganbuChickenTeamInfo(result?.data)
      if (
        alwayzInvitorId &&
        alwayzInvitorId !== '60f7444cd28d5e1c65152c21' &&
        result?.data?._id
      ) {
        alert.show(
          '고객님은 신규가 아니어서 링크 참여가 불가해요. 대신에 다른 새 친구를 초대해서 1+1 치킨을 받아보세요!',
          {
            closeCopy: '확인',
          },
        )
        return
      }

      if (
        (result?.data?.invitationInfo?.missionStatus === 'onGoing' ||
          (result?.data?.invitationInfo?.missionStatus === 'success' &&
            !result?.data?.invitationInfo?.isItemReceived)) &&
        isFrom !== 'invitation'
      ) {
        window.location.href = `/gganbu-chicken-invitation/?token=${token}&randomString=${Math.random()
          .toString(36)
          .substr(2, 8)}`
        return
      }

      if (isFrom && isFrom !== 'invitation') {
        alert.show(
          '치킨 추첨 참여가 완료되었어요. 친구에게 1+1 치킨을 공유하고 한번 더 치킨을 받아보세요',
          {
            closeCopy: '확인',
          },
        )
        return
      }

      if (!result?.data?._id) {
        const initGganbuChickenInfo = await backendApis.initGganbuChickenTeam(
          token,
          'POST',
          {
            invitorId: alwayzInvitorId,
            itemId,
            orderItemId: gganbuChickenItemList?.data?.filter(
              (each) => each?._id === itemId,
            )[0]?.itemId,
            missionGganbuNum: gganbuChickenItemList?.data?.filter(
              (each) => each?._id === itemId,
            )[0]?.missionGganbuNum,
          },
        )
        if (initGganbuChickenInfo?.data?.isNewUser) {
          window.location.href = `/gganbu-chicken-invitation/?token=${token}&randomString=${Math.random()
            .toString(36)
            .substr(2, 8)}`
        }
        // else if (
        //   initGganbuChickenInfo?.data?.msg === 'invitee is under age'
        // ) {
        //   alert.show('본 이벤트는 20세 이상 고객만 참여 가능해요', {
        //     closeCopy: '확인',
        //   })
        //   setGganbuChickenTeamInfo(initGganbuChickenInfo?.data)
        // }
        else if (
          initGganbuChickenInfo?.data?.msg === 'host and invitee are same'
        ) {
          alert.show(
            '자신이 공유한 링크로 자신이 받을 수는 없어요. 친구에게 공유하고 1+1 치킨을 받아보세요',
            {
              closeCopy: '확인',
            },
          )
          setGganbuChickenTeamInfo(initGganbuChickenInfo?.data)
        } else {
          setGganbuChickenTeamInfo(initGganbuChickenInfo?.data)
        }
      }
    }
  }

  useEffect(() => {
    initGganbuChicken()
  }, [])

  useEffect(() => {
    document.addEventListener(
      visibilityChange,
      (visibility) => {
        const now = Date.now()
        if (document.visibilityState === 'visible') {
          initGganbuChicken()
        }
      },
      false,
    )
  }, [token])

  return (
    <>
      <Layout>
        <Notice
          onClick={() => {
            setShowGganbuChickenExplainModal(true)
          }}
        />
        <Header title='1+1 치킨 이벤트' />
        <div style={{ height: '93%', overflowY: 'scroll' }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              background:
                'linear-gradient(180deg, #FF9500 20%, #FFD01D 50%, #FF9500 70%, #FF9500 100%)',
              marginBottom: 10,
              fontFamily: 'GmarketSans',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              overflowY: 'scroll',
            }}
          >
            <div
              style={{
                padding: 10,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <button
                type='button'
                style={{
                  textAlign: 'center',
                  color: 'white',
                  fontWeight: 700,
                  backgroundColor: '#EB4F47',
                  marginTop: 4,
                  padding: 8,
                  paddingLeft: 16,
                  paddingRight: 16,
                  border: 'none',
                  borderRadius: 4,
                  fontSize: 16,
                  fontFamily: 'GmarketSans',
                }}
                onClick={() => {
                  setShowGganbuChickenCloseNoticeModal(true)
                }}
              >
                1+1 치킨 이벤트 종료 안내 (3월 2일 종료)
              </button>
            </div>
            <div
              style={{
                padding: 8,
                paddingLeft: 16,
                paddingRight: 16,
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: '#392500',
                color: 'white',
                fontWeight: 'bold',
                borderRadius: 50,
                marginTop: 8,
              }}
            >
              각 치킨마다 100마리씩 제공!
            </div>
            <div
              style={{
                fontSize: 32,
                marginTop: 12,
                color: '#704410',
                fontWeight: 'bold',
              }}
            >
              친구도 나도 받는 <span style={{ color: '#D00000' }}>1+1</span>
            </div>
            <div
              style={{
                fontSize: 16,
                marginTop: 4,
                marginBottom: 8,
                fontWeight: 'bold',
                color: '#905F02',
              }}
            >
              원하는 치킨을 선택하고 친구와 공유하세요!
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                // backgroundColor: 'red',
              }}
            >
              <button
                type='button'
                style={{ background: 'none', border: 'none', zIndex: 2 }}
                onClick={() => {
                  if (itemIndex < 2) {
                    setItemIndex(3)
                  } else {
                    setItemIndex(itemIndex - 1)
                  }
                }}
              >
                <div style={{ zIndex: 2 }}>
                  <img
                    src='/images/leftArrow.png'
                    alt=''
                    style={{ height: 36, width: 36 }}
                  />
                </div>
              </button>
              <div
                style={{
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  width: '68vw',
                  height: 280,
                  marginLeft: -20,
                  marginRight: -20,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    backgroundImage: "url('/images/speechBubble.png')",
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    width: 148,
                    height: 80,
                    padding: 8,
                    paddingTop: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    lineHeight: 1.3,
                  }}
                >
                  <div>
                    {
                      gganbuChickenItemList?.filter?.(
                        (each) => each?.index === itemIndex,
                      )[0]?.mainTitle
                    }
                    <div>
                      {
                        gganbuChickenItemList?.filter?.(
                          (each) => each?.index === itemIndex,
                        )[0]?.subTitle
                      }{' '}
                      <span style={{ color: '#D00000' }}>1+1</span>
                    </div>
                  </div>
                </div>
                <img
                  alt=''
                  style={{
                    marginTop: -48,
                    width: '90%',
                  }}
                  src={`${
                    gganbuChickenItemList?.filter?.(
                      (each) => each?.index === itemIndex,
                    )[0]?.mainImageUri
                  }`}
                />
              </div>
              <button
                type='button'
                style={{ background: 'none', border: 'none', zIndex: 2 }}
                onClick={() => {
                  if (itemIndex < 3) {
                    setItemIndex(itemIndex + 1)
                  } else {
                    setItemIndex(1)
                  }
                }}
              >
                <div style={{ zIndex: 2 }}>
                  <img
                    src='/images/rightArrow.png'
                    alt=''
                    style={{ height: 36, width: 36 }}
                  />
                </div>
              </button>
            </div>
            <div
              style={{
                textAlign: 'center',
                fontSize: 12,
                color: '#905F02',
                fontWeight: 'bold',
                // backgroundColor: 'black',
                height: 400,
                width: '90%',
                // paddingTop: 4,
                marginTop: -8,
                // marginBottom: 4,
              }}
            >
              {/* {gganbuChickenItemList?.filter?.(
                (each) => each?.index === itemIndex,
              )[0]?.stockNumber > 0 ? (
                <>
                  <span
                    style={{
                      color: 'white',
                      backgroundColor: '#905F02',
                      padding: 4,
                      borderRadius: 4,
                    }}
                  >
                    실시간
                  </span>{' '}
                  {
                    gganbuChickenItemList?.filter?.(
                      (each) => each?.index === itemIndex,
                    )[0]?.mainTitle
                  }{' '}
                  {
                    gganbuChickenItemList?.filter?.(
                      (each) => each?.index === itemIndex,
                    )[0]?.subTitle
                  }
                  가(이){' '}
                  <span style={{ color: '#D00000' }}>
                    {commaNumber(
                      gganbuChickenItemList?.filter?.(
                        (each) => each?.index === itemIndex,
                      )[0]?.stockNumber,
                    )}
                    마리{' '}
                  </span>{' '}
                  남아 있어요
                </>
              ) : (
                <>준비된 재고 5,000개가 모두 소진되었어요</>
              )} */}
              <span
                style={{
                  color: 'white',
                  backgroundColor: '#905F02',
                  padding: 4,
                  borderRadius: 4,
                }}
              >
                TIP
              </span>{' '}
              친구를 많이 초대할수록 받을 확률이 더 높아져요
              {/* <span style={{ color: '#D00000' }}>100마리씩</span>{' '}
              준비되어 있어요 */}
            </div>

            <div
              style={{
                backgroundColor: '#fff',
                width: '100%',
                height: 500,
                marginTop: 20,
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                fontFamily: 'GmarketSans',
                paddingBottom: 20,
              }}
            >
              <button
                type='button'
                style={{
                  border: 'none',
                  background: 'none',
                  backgroundColor: '#D00000',
                  width: '88vw',
                  height: 48,
                  marginTop: 16,
                  borderRadius: 12,
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}
                onClick={async () => {
                  const userInfoForCheck =
                    await backendApis.getUserBasicInfoWithAuthentication(token)
                  if (
                    userInfoForCheck?.status !== 200 ||
                    userInfoForCheck?.data?.phoneNumber === '' ||
                    userInfoForCheck?.data?.userName === ''
                  ) {
                    window.location.href = '#openLoginModal.'
                    return
                  }
                  if (userInfoForCheck?.data?.phoneNumber !== '') {
                    const result = await backendApis.getGganbuChickenTeamInfo(
                      token,
                    )
                    if (!result?.data?._id) {
                      const gganbuChickenItemList =
                        await backendApis.getGganbuChickenItemList(token)
                      const initGganbuChickenInfo =
                        await backendApis.initGganbuChickenTeam(token, 'POST', {
                          invitorId: alwayzInvitorId,
                          itemId,
                          orderItemId: gganbuChickenItemList?.data?.filter(
                            (each) => each?._id === itemId,
                          )[0]?.itemId,
                          missionGganbuNum: gganbuChickenItemList?.data?.filter(
                            (each) => each?._id === itemId,
                          )[0]?.missionGganbuNum,
                        })
                      if (initGganbuChickenInfo?.data?.isNewUser) {
                        // 모달 띄우기
                        window.location.href = `/gganbu-chicken-invitation/?token=${token}&randomString=${Math.random()
                          .toString(36)
                          .substr(2, 8)}`
                      } else if (
                        initGganbuChickenInfo?.data?.msg === 'is not new user'
                      ) {
                        alert.show(
                          '고객님은 신규가 아니어서 링크 참여가 불가해요. 대신에 다른 새 친구를 초대해서 1+1 치킨을 받아보세요!',
                          {
                            closeCopy: '확인',
                          },
                        )
                        setGganbuChickenTeamInfo(initGganbuChickenInfo?.data)
                      }
                      // else if (
                      //   initGganbuChickenInfo?.data?.msg ===
                      //   'invitee is under age'
                      // ) {
                      //   alert.show(
                      //     '본 이벤트는 20세 이상 고객만 참여 가능해요',
                      //     {
                      //       closeCopy: '확인',
                      //     },
                      //   )
                      //   setGganbuChickenTeamInfo(initGganbuChickenInfo?.data)
                      // }
                      else if (
                        initGganbuChickenInfo?.data?.msg ===
                        'host and invitee are same'
                      ) {
                        alert.show(
                          '자신이 공유한 링크로 자신이 받을 수는 없어요. 친구에게 공유하고 1+1 치킨을 받아보세요',
                          {
                            closeCopy: '확인',
                          },
                        )
                        setGganbuChickenTeamInfo(initGganbuChickenInfo?.data)
                      } else {
                        alert.show(
                          '알 수 없는 오류가 발생했어요. 다시 시도해주세요',
                          {
                            closeCopy: '확인',
                          },
                        )
                      }
                      return
                    }
                  }

                  if (
                    gganbuChickenItemList?.filter(
                      (each) => each?.index === itemIndex,
                    )[0]?.stockNumber < 1
                  ) {
                    alert.show(
                      '해당 치킨의 재고가 모두 소진되었어요. 다른 치킨을 선택해주세요',
                      {
                        closeCopy: '확인',
                      },
                    )
                    return
                  }
                  setShowGganbuChickenSelectModal(true)
                  window.location.href = `#openBottomShareSheet.${JSON.stringify(
                    {
                      code: `gganbuChicken${itemIndex}`,
                      shareParams: gganbuChickenItemList?.filter(
                        (each) => each?.index === itemIndex,
                      )[0]?.itemId,
                    },
                  )}`
                }}
              >
                <div style={{ fontFamily: 'GmarketSans' }}>
                  {gganbuChickenTeamInfo ? '치킨 선택하기' : '치킨 받으러 가기'}
                </div>
              </button>
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '88vw' }}
              >
                {gganbuChickenTeamInfo?.guestInfosArray?.length > 0 && (
                  <>
                    {' '}
                    <button
                      type='button'
                      style={{
                        border: 'none',
                        background: 'none',
                        backgroundColor: '#40373A',
                        height: 48,
                        marginTop: 8,
                        borderRadius: 12,
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: 16,
                        flex: 1,
                      }}
                      onClick={() => {
                        setShowGganbuChickenSendHistoryModal(true)
                      }}
                    >
                      <div style={{ fontFamily: 'GmarketSans' }}>
                        내가 보낸 목록
                      </div>
                    </button>
                  </>
                )}
                {gganbuChickenTeamInfo?.guestInfosArray?.length > 0 &&
                gganbuChickenTeamInfo?.invitationInfo?.invitorId ? (
                  <div style={{ width: '2vw' }} />
                ) : (
                  <>
                    {' '}
                    <div style={{ height: 20 }} />
                  </>
                )}
                {gganbuChickenTeamInfo?.invitationInfo?.invitorId && (
                  <>
                    {' '}
                    <button
                      type='button'
                      style={{
                        border: 'none',
                        background: 'none',
                        backgroundColor: '#40373A',
                        height: 48,
                        marginTop: 8,
                        borderRadius: 12,
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: 16,
                        flex: 1,
                      }}
                      onClick={() => {
                        window.location.href = `/gganbu-chicken-invitation/?token=${token}&randomString=${Math.random()
                          .toString(36)
                          .substr(2, 8)}`
                      }}
                    >
                      <div style={{ fontFamily: 'GmarketSans' }}>
                        내가 받은 목록
                      </div>
                    </button>
                  </>
                )}
              </div>
              <div>
                <img
                  src='/images/gganbuChickenRuleImage.png'
                  alt=''
                  style={{ width: '88vw', marginTop: 20 }}
                />
              </div>
            </div>
          </div>
        </div>
        {showGganbuChickenSelectModal && (
          <GganbuChickenSelectModal
            setShowGganbuChickenSelectModal={setShowGganbuChickenSelectModal}
            token={token}
            itemInfo={
              gganbuChickenItemList?.filter(
                (each) => each?.index === itemIndex,
              )[0]
            }
          />
        )}
        {showGganbuChickenSendHistoryModal && (
          <GganbuChickenSendHistoryModal
            setShowGganbuChickenSendHistoryModal={
              setShowGganbuChickenSendHistoryModal
            }
            token={token}
            gganbuChickenItemList={gganbuChickenItemList}
            guestInfosArray={gganbuChickenTeamInfo?.guestInfosArray}
          />
        )}
        {showGganbuChickenExplainModal && (
          <GganbuChickenExplainModal
            setShowGganbuChickenExplainModal={setShowGganbuChickenExplainModal}
          />
        )}
        {showGganbuChickenCloseNoticeModal && (
          <GganbuChickenCloseNoticeModal
            setShowGganbuChickenCloseNoticeModal={
              setShowGganbuChickenCloseNoticeModal
            }
            token={token}
          />
        )}
      </Layout>
    </>
  )
}
export default GganbuChickenEvent
