import React, { useEffect, useState } from 'react'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import LuckyBoxNewUserNudgingModal from '../modals/LuckyBoxNewUserNudgingModal'
import LuckyBoxEmergencyNoticeModal from '../modals/LuckyBoxEmergencyNoticeModal'

const LuckyBoxSplashPage = () => {
  const token = useSearchParam('token')
  const alwayzInvitorId = useSearchParam('alwayzInvitorId')
  const documentId = useSearchParam('documentId')
  // const userId = useSearchParam('userId')
  const isFrom = useSearchParam('isFrom')
  const [userId, setUserId] = useState()
  // const [showLuckyBoxNewUserNudgingModal, setShowLuckyBoxNewUserNudgingModal] =
  //   useState(false)

  //  긴급 상황에 남기는 코드

  // const [
  //   showLuckyBoxEmergencyNoticeModal,
  //   setShowLuckyBoxEmergencyNoticeModal,
  // ] = useState(false)

  // 긴급 상황에 주석처리할 코드
  const initializeLuckyBoxInfo = async () => {
    if (isFrom) {
      window.location.href = `/LuckyBoxEvent/?token=${token}&isFrom=OrderMain&randomString=${Math.random()
        .toString(36)
        .substr(2, 8)}`
      return
    }
    const userInfo = await backendApis.getUserBasicInfoWithAuthentication(token)
    if (userInfo?.result?.status === 200) {
      setUserId(userInfo?.data?._id)
    }
    // documentId를 가지고 있거나(링크를 타고 들어오거나 5명 초대 후 selectModal에서 들어옴) invitation으로 보내기
    if (documentId) {
      window.location.href = `/LuckyBoxEventInvitation/?token=${token}&documentId=${documentId}&userId=${userId}&alwayzInvitorId=${alwayzInvitorId}&randomString=${Math.random()
        .toString(36)
        .substr(2, 8)}`
      return
    }
    // lucky_box_receiver 불러오기(링크 유입 시)
    const receiverInfoResult = await backendApis.getReceivedLuckyBoxInfo(
      token,
      'GET',
      {
        documentId,
      },
    )
    // lucky_box_info 무조건 불러오기
    const ongoingLuckyBoxTeamInfoResult = await backendApis.getLuckyBoxTeamInfo(
      token,
      'GET',
    )

    // lucky_box_receiver 불러오기(배너 진입 시, documentId가 없음)
    const receiverInfoByUserIdResult =
      await backendApis.getReceivedLuckyBoxInfoByUserId(token, 'GET', {
        documentId,
      })

    // 배너로 진입하고 lucky_box_info를 생성한 경우(받을 상품이 없는 경우)
    if (
      !documentId &&
      ongoingLuckyBoxTeamInfoResult?.status === 200 &&
      ongoingLuckyBoxTeamInfoResult?.data?.openCount ===
        ongoingLuckyBoxTeamInfoResult?.data?.itemReceiveCount
    ) {
      window.location.href = `/LuckyBoxEvent/?token=${token}&randomString=${Math.random()
        .toString(36)
        .substr(2, 8)}`
      return
    }

    // 배너로 진입하였고 lucky_box_info가 없고 오픈하였지만 받지 않은 상품이 있으면 invitation으로 보내기
    if (
      (!documentId &&
        ongoingLuckyBoxTeamInfoResult?.status !== 200 &&
        receiverInfoByUserIdResult?.data?.isReceived &&
        !receiverInfoByUserIdResult?.data?.isItemReceived) ||
      ongoingLuckyBoxTeamInfoResult?.data?.openCount ===
        ongoingLuckyBoxTeamInfoResult?.data?.itemReceiveCount + 1
    ) {
      // 기존 고객이 5명 초대 후 행운 상자를 열고 상품을 아직 받지 않은 경우 invitation으로 보내기
      window.location.href = `/LuckyBoxEventInvitation/?token=${token}&documentId=${documentId}&userId=${userId}&alwayzInvitorId=${alwayzInvitorId}&randomString=${Math.random()
        .toString(36)
        .substr(2, 8)}`
      return
    }

    // 배너로 진입하여 lucky_box_info가 없고 받을 상품이 없으면 intro로 보내기
    if (
      !documentId &&
      ongoingLuckyBoxTeamInfoResult?.status !== 200 &&
      !(receiverInfoResult?.isReceived && !receiverInfoResult?.isItemReceived)
    ) {
      window.location.href = `/LuckyBoxEventIntro/?token=${token}&randomString=${Math.random()
        .toString(36)
        .substr(2, 8)}`
    }
  }

  useEffect(() => {
    setTimeout(() => {
      initializeLuckyBoxInfo()
    }, 1000)
  })

  // 긴급 상황에 남기는 코드
  // useEffect(() => {
  //   setShowLuckyBoxEmergencyNoticeModal(true)
  // }, [])

  return (
    <>
      <div style={{ height: '100vh' }}>
        <div
          style={{
            display: 'flex',
            height: '100vh',
            width: '100vw',
            background:
              'linear-gradient(180deg, #f67815 0%, #f98611 3.65%,#E63323 94.79%, #e83b21 100%)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ height: 120, marginTop: 160, marginBottom: 20 }}>
            <div
              style={{
                width: '100vw',
                height: 36,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                  paddingTop: 8,
                  paddingBottom: 8,
                  paddingLeft: 36,
                  paddingRight: 36,
                  borderRadius: 24,
                  backgroundColor: '#ED531C',
                  color: 'white',
                  fontFamily: 'GmarketSans',
                  marginBottom: 8,
                  fontSize: 24,
                }}
              >
                꽝이 없어요!
              </div>
            </div>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 500,
                fontSize: 32,
                color: 'white',
                textAlign: 'center',
                marginTop: 24,
              }}
            >
              내가 골라담은
              <div style={{ marginTop: 4 }}>
                <span style={{ fontWeight: 900 }}>행운상자</span> 선물하기
              </div>
              <div>
                <img
                  src='/images/LuckyBox.png'
                  alt='ReceivedLuckyBox'
                  style={{
                    marginTop: 40,
                    paddingTop: 4,
                    paddingBottom: 4,
                    width: 180,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {showLuckyBoxNewUserNudgingModal && (
        <LuckyBoxNewUserNudgingModal
          setShowLuckyBoxNewUserNudgingModal={
            setShowLuckyBoxNewUserNudgingModal
          }
          token={token}
        />
      )} */}
      {/* 긴급 상황에 남기는 코드 */}
      {/* {showLuckyBoxEmergencyNoticeModal && (
        <LuckyBoxEmergencyNoticeModal
          setShowLuckyBoxEmergencyNoticeModal={
            setShowLuckyBoxEmergencyNoticeModal
          }
          token={token}
        />
      )} */}
    </>
  )
}

export default LuckyBoxSplashPage
