import React, { useState } from 'react'
import commaNumber from 'comma-number'
import { useAlert } from 'react-alert'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'

let isStoppableStatus = false
const SupportFundStopModal = ({ setShowSupportFundStopModal, token }) => {
  const alert = useAlert()
  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          zIndex: 3,
          backgroundColor: 'rgba(0,0,0,0.8)',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {' '}
        <button
          type='button'
          style={{
            width: 100,
            height: 100,
            backgroundColor: 'red',
            background: 'none',
            border: 'none',
            top: '14%',
            right: '1%',
            position: 'absolute',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => {
            setShowSupportFundStopModal(false)
          }}
        >
          <img
            style={{
              zIndex: 101,
              width: '36%',
              height: '36%',
              position: 'absolute',
            }}
            src='/images/closeCircleIcon.png'
            alt=''
          />
        </button>
        <div
          style={{
            backgroundColor: 'white',
            width: '88vw',
            height: '32vh',
            borderRadius: 12,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              // backgroundColor: 'red',
            }}
          >
            <div
              style={{
                textAlign: 'center',
                fontFamily: 'GmarketSans',
                fontWeight: 700,
                lineHeight: 1.3,
                marginBottom: 8,
              }}
            >
              <div>연말지원금을 그만하고</div>
              <div>랜덤 쿠폰을 받을 수 있어요</div>
            </div>
            <div
              style={{
                textAlign: 'center',
                fontFamily: 'GmarketSans',
                fontSize: 12,
                color: '#79765F',
                marginTop: 8,
                lineHeight: 1.3,
              }}
            >
              <div>그만하면 지금까지 모은 금액이 모두 사라지며</div>
              <div>
                <span style={{ fontWeight: 900, color: '#EB4F47' }}>
                  500원부터 최대 30,000원
                </span>
                의 랜덤 쿠폰을 드려요
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                borderRadius: 8,
                margin: 8,
                marginTop: 12,
                marginBottom: 12,
                width: '95%',
              }}
            >
              <button
                type='button'
                style={{
                  backgroundColor: '#BEC0C2',
                  border: 'none',
                  color: 'white',
                  fontFamily: 'GmarketSans',
                  fontWeight: 500,
                  fontSize: 16,
                  padding: 8,
                  paddingLeft: 12,
                  paddingRight: 12,
                  margin: 4,
                  borderRadius: 8,
                }}
                onClick={async () => {
                  if (isStoppableStatus) {
                    const result = await backendApis.stopSupportFundTeam(
                      token,
                      'PUT',
                    )
                    if (result?.status === 200) {
                      setShowSupportFundStopModal(true)
                      window.location.href = `#pop.${JSON.stringify({
                        number: 2,
                      })}`
                      window.location.href = `#navigate.${JSON.stringify({
                        screen: 'CouponListScreen',
                        prop: { origin: 'SupportFundEvent' },
                      })}`
                    }
                  } else {
                    isStoppableStatus = true
                    alert.show(
                      '한번 더 그만하기를 누르면 연말지원금이 중단돼요',
                      {
                        closeCopy: '확인',
                      },
                    )
                  }
                }}
              >
                그만하기
                <span style={{ color: '#EB4F47', marginLeft: 2 }}>*</span>
              </button>
              <button
                type='button'
                style={{
                  backgroundColor: '#EB4F47',
                  border: 'none',
                  color: 'white',
                  fontFamily: 'GmarketSans',
                  fontWeight: 500,
                  fontSize: 16,
                  padding: 8,
                  paddingLeft: 12,
                  paddingRight: 12,
                  margin: 4,
                  borderRadius: 8,
                }}
                onClick={() => {
                  setShowSupportFundStopModal(false)
                }}
              >
                계속 5만원 받기
              </button>
            </div>
            <div
              style={{
                fontSize: 12,
                color: '#79765F',
                fontFamily: 'GmarketSans',
              }}
            >
              그만하기를 선택하면 다시 바꿀 수 없어요
              <span style={{ color: '#EB4F47', marginLeft: 4 }}>*</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SupportFundStopModal
