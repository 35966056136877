import React, { useState, useEffect } from 'react'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import Layout from '../components/layout'
import Header from '../components/header'
import Notice from '../components/Notice'
import commaNumber from 'comma-number'
import { useAlert } from 'react-alert'
import useDebounce from '../components/useDebounce'

const isButtonDisable = false
let hidden
let visibilityChange
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

function NewYearsMoneyInvitation() {
  const alert = useAlert()
  const debounce = useDebounce()
  // const [isButtonDisable, setIsButtonDisable] = useState(false)
  const token = useSearchParam('token')
  const alwayzInvitorId = useSearchParam('alwayzInvitorId')
  const itemId = useSearchParam('itemId')
  const isFrom = useSearchParam('isFrom')
  const [index, setIndex] = useState(1)
  const [userInfo, setUserInfo] = useState('')
  const [invitorName, setInvitorName] = useState('')
  const [newYearsMoneyTeamInfo, setNewYearsMoneyTeamInfo] = useState('')

  const initNewYearsMoney = async () => {
    const invitorInfo = await backendApis.getInvitorInfo(token, 'GET', {
      invitorId: alwayzInvitorId,
    })
    setInvitorName(invitorInfo?.data)

    const result = await backendApis.getNewYearsMoneyTeamInfo(token)
    setNewYearsMoneyTeamInfo(result?.data)
  }

  useEffect(() => {
    initNewYearsMoney()
  }, [])

  useEffect(() => {
    document.addEventListener(
      visibilityChange,
      (visibility) => {
        const now = Date.now()
        if (document.visibilityState === 'visible') {
          // initNewYearsMoney()
          // setIsButtonDisable(false)
        }
      },
      false,
    )
  }, [token])

  const invitationButton = async () => {
    const userInfoForCheck =
      await backendApis.getUserBasicInfoWithAuthentication(token)
    if (
      userInfoForCheck?.status !== 200 ||
      userInfoForCheck?.data?.phoneNumber === '' ||
      userInfoForCheck?.data?.userName === ''
    ) {
      window.location.href = '#openLoginModal.'
      return
    }
    const ongoingNewYearsMoneyTeam = await backendApis.getNewYearsMoneyTeamInfo(
      token,
    )
    if (!ongoingNewYearsMoneyTeam?.data) {
      const initNewYearsMoneyTeam = await backendApis.initNewYearsMoneyTeam(
        token,
        'POST',
        {
          invitorId: alwayzInvitorId,
        },
      )
      setNewYearsMoneyTeamInfo(initNewYearsMoneyTeam?.data)
      if (initNewYearsMoneyTeam?.data?.isNewUser) {
        alert.show(
          '성공적으로 친구의 초대를 받았어요. 나도 친구에게 공유하고 최대 11만원을 받아보세요',
          {
            closeCopy: '확인',
          },
        )
      }
      if (
        alwayzInvitorId &&
        initNewYearsMoneyTeam?.data?.msg === 'invitee is under age'
      ) {
        alert.show('본 이벤트는 20세 이상 고객만 참여 가능해요.', {
          closeCopy: '확인',
        })
      } else if (
        alwayzInvitorId &&
        initNewYearsMoneyTeam?.data?.msg === 'host and invitee are same'
      ) {
        alert.show('자신이 공유한 링크로 자신이 받을 수는 없어요.', {
          closeCopy: '확인',
        })
      } else if (
        alwayzInvitorId &&
        alwayzInvitorId !== 'undefined' &&
        initNewYearsMoneyTeam?.data?.msg === 'is not new user'
      ) {
        alert.show(
          '올웨이즈를 처음하는 고객만 친구가 보낸 초대를 받을 수 있어요. 친구에게 공유하고 최대 11만원을 받아보세요',
          {
            closeCopy: '확인',
          },
        )
      } else {
      }
    } else {
      // alert.show(
      //   '올웨이즈를 처음하는 고객만 친구가 보낸 초대를 받을 수 있어요.',
      //   {
      //     closeCopy: '확인',
      //   },
      // )
    }
    setTimeout(() => {
      window.location.href = `/new-years-money/?token=${token}&randomString=${Math.random()
        .toString(36)
        .substr(2, 8)}`
    }, 3500)
  }
  const Rule = () => (
    <>
      <div
        style={{
          backgroundImage: "url('/images/NewYearsMoneyRule.png')",
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          //   backgroundColor: 'red',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      />
    </>
  )

  const RewardStep = () => (
    <>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '90vw',
          }}
        >
          <div style={{ marginTop: 16, marginBottom: 16, fontWeight: 'bold' }}>
            미션별 보상 (초대한 친구 1명당 최대 2만원)
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              border: '1px solid #D9D9D9',
              width: '88vw',
              padding: 8,
              marginBottom: 8,
              paddingTop: 12,
              paddingBottom: 12,
              borderRadius: 4,
              alignItems: 'center',
            }}
          >
            <div style={{ flex: 1 }}>
              <img
                src='/images/reward1.png'
                alt=''
                style={{
                  width: 28,
                }}
              />
            </div>
            <div style={{ flex: 2, fontWeight: 'bold' }}>2,000원</div>
            <div
              style={{
                flex: 6,
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: 16,
              }}
            >
              친구가 초대를 수락했을 때
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              border: '1px solid #D9D9D9',
              width: '88vw',
              padding: 8,
              marginBottom: 8,
              paddingTop: 12,
              paddingBottom: 12,
              borderRadius: 4,
              alignItems: 'center',
            }}
          >
            <div style={{ flex: 1 }}>
              <img
                src='/images/reward2.png'
                alt=''
                style={{
                  width: 28,
                }}
              />
            </div>
            <div style={{ flex: 2, fontWeight: 'bold' }}>2,000원</div>
            <div
              style={{
                flex: 6,
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: 16,
              }}
            >
              친구가 올팜에서 4일 연속 물주기를 했을 때
            </div>
          </div>{' '}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              border: '1px solid #D9D9D9',
              width: '88vw',
              padding: 8,
              marginBottom: 8,
              paddingTop: 12,
              paddingBottom: 12,
              borderRadius: 4,
              alignItems: 'center',
            }}
          >
            <div style={{ flex: 1 }}>
              <img
                src='/images/reward3.png'
                alt=''
                style={{
                  width: 28,
                }}
              />
            </div>
            <div style={{ flex: 2, fontWeight: 'bold' }}>2,000원</div>
            <div
              style={{
                flex: 6,
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: 16,
              }}
            >
              친구가 올팜에서 7일 연속 물주기를 했을 때{' '}
            </div>
          </div>{' '}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              border: '1px solid #D9D9D9',
              width: '88vw',
              padding: 8,
              marginBottom: 8,
              paddingTop: 12,
              paddingBottom: 12,
              borderRadius: 4,
              alignItems: 'center',
            }}
          >
            <div style={{ flex: 1 }}>
              <img
                src='/images/reward4.png'
                alt=''
                style={{
                  width: 28,
                }}
              />
            </div>
            <div style={{ flex: 2, fontWeight: 'bold' }}>4,000원</div>
            <div
              style={{
                flex: 6,
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: 16,
              }}
            >
              친구가 올팜에서 맞팜친구 3명을 맺었을 때
            </div>
          </div>{' '}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              border: '1px solid #D9D9D9',
              width: '88vw',
              padding: 8,
              marginBottom: 8,
              paddingTop: 12,
              paddingBottom: 12,
              borderRadius: 4,
              alignItems: 'center',
            }}
          >
            <div style={{ flex: 1 }}>
              <img
                src='/images/reward5.png'
                alt=''
                style={{
                  width: 28,
                }}
              />
            </div>
            <div style={{ flex: 2, fontWeight: 'bold' }}>10,000원</div>
            <div
              style={{
                flex: 6,
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: 16,
              }}
            >
              친구가 올팜에서 맞팜친구 10명을 맺었을 때
            </div>
          </div>
        </div>
      </div>
    </>
  )

  return (
    <>
      <Layout>
        <Header title='세뱃돈 이벤트' />
        <div
          style={{
            width: '100%',
            height: '100%',
            marginBottom: 10,
            fontFamily: 'GmarketSans',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'scroll',
            position: 'relative',
          }}
        >
          <img
            src='/images/backgroundSkyImage.png'
            alt=''
            style={{
              width: '100vw',
              position: 'absolute',
            }}
          />
          <div
            style={{
              backgroundColor: '#292D36',
              height: 680,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                marginTop: 72,
                fontSize: 24,
                color: '#fff',
              }}
            >
              친구 초대하고
            </div>
            <div
              style={{
                marginTop: 4,
                fontSize: 24,
                color: '#fff',
                fontWeight: 'bold',
              }}
            >
              {' '}
              <span
                style={{
                  color: '#FFF744',
                  borderBottom: '2px #fff744 solid',
                }}
              >
                세뱃돈 11만원
              </span>{' '}
              받아가세요!
            </div>
            <img
              src='/images/newYearsMoneyImage.png'
              alt=''
              style={{
                marginTop: 20,
                height: 200,
                marginBottom: -12,
              }}
            />
            <button
              type='button'
              style={{
                border: 'none',
                background: 'none',
                width: '80vw',
              }}
              className={`${'scaleUp'}`}
              onClick={() => {
                debounce(invitationButton, 500)
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: 60,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontFamily: 'GmarketSans',
                  color: '#292D36',
                  fontSize: 20,
                  fontWeight: 'bold',
                  borderRadius: 20,
                  marginBottom: 56,
                }}
              >
                <img
                  style={{
                    width: '88%',
                  }}
                  src='/images/newYearsMoneyInviteeButton.png'
                  alt=''
                />
              </div>
            </button>
          </div>
          <div
            style={{
              // marginTop: 12,
              marginTop: 8,
              marginBottom: 8,
              display: 'flex',
              flexDirection: 'column',
              width: '100vw',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              <div
                style={{
                  flex: 1,
                  textAlign: 'center',
                  padding: 8,
                  borderBottom:
                    index !== 2 ? '2px #000000 solid' : '2px #F0F0F0 solid',
                }}
              >
                <button
                  type='button'
                  style={{
                    border: 'none',
                    background: 'none',
                    width: '100%',
                    height: '100%',
                    fontFamily: 'GmarketSans',
                    fontSize: 16,
                    color: index !== 2 ? '#000000' : '#717171',
                    fontWeight: index !== 2 ? 'bold' : 'normal',
                  }}
                  onClick={() => {
                    setIndex(1)
                  }}
                >
                  참여방법
                </button>
              </div>
              <div
                style={{
                  flex: 1,
                  textAlign: 'center',
                  padding: 8,
                  borderBottom:
                    index === 2 ? '2px #000000 solid' : '2px #F0F0F0 solid',
                }}
              >
                <button
                  type='button'
                  style={{
                    border: 'none',
                    background: 'none',
                    width: '100%',
                    height: '100%',
                    fontFamily: 'GmarketSans',
                    fontSize: 16,
                    color: index !== 2 ? '#717171' : '#000000',
                    fontWeight: index === 2 ? 'bold' : 'normal',
                  }}
                  onClick={() => {
                    setIndex(2)
                  }}
                >
                  보상
                </button>
              </div>
            </div>
            <div style={{ width: '100vw', marginBottom: 72 }}>
              {index !== 2 ? (
                <>
                  <Rule />
                </>
              ) : (
                <>
                  <div
                    style={{
                      width: '100vw',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontFamily: 'Pretendard',
                    }}
                  >
                    <RewardStep />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default NewYearsMoneyInvitation
