import React, { useState, useEffect } from 'react'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import Layout from '../components/layout'
import Header from '../components/header'
import Notice from '../components/Notice'
import BlackTextTimer from '../components/BlackTextTimer'
import commaNumber from 'comma-number'
import { useAlert } from 'react-alert'
import useDebounce from '../components/useDebounce'
import GganbuChickenSelectModal from '../modals/GganbuChickenSelectModal'
import GganbuChickenSendHistoryModal from '../modals/GganbuChickenSendHistoryModal'
import GganbuChickenExplainModal from '../modals/GganbuChickenExplainModal'
import GganbuChickenCloseNoticeModal from '../modals/GganbuChickenCloseNoticeModal'
import GganbuChickenGganbuExplainModal from '../modals/GganbuChickenGganbuExplainModal'

let hidden
let visibilityChange
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

function GganbuChickenInvitation() {
  const alert = useAlert()
  const debounce = useDebounce()
  const token = useSearchParam('token')
  const [isAlreadyExist, setIsAlreadyExist] = useState()
  // const isFrom = useSearchParam('isFrom')
  const alwayzInvitorId = useSearchParam('alwayzInvitorId')
  const invitorItem = useSearchParam('invitorItem')
  const code = useSearchParam('code')
  const [gganbuChickenItemInfo, setGganbuChickenItemInfo] = useState('')
  const [gganbuChickenTeamInfo, setGganbuChickenTeamInfo] = useState('')
  // const [isButtonDisable, setIsButtonDisable] = useState(false)
  const [
    showGganbuChickenSendHistoryModal,
    setShowGganbuChickenSendHistoryModal,
  ] = useState(false)
  const [showGganbuChickenExplainModal, setShowGganbuChickenExplainModal] =
    useState(false)
  const [
    showGganbuChickenGganbuExplainModal,
    setShowGganbuChickenGganbuExplainModal,
  ] = useState(false)
  const [
    showGganbuChickenCloseNoticeModal,
    setShowGganbuChickenCloseNoticeModal,
  ] = useState(false)

  const initGganbuChicken = async () => {
    const gganbuChickenItemList = await backendApis.getGganbuChickenItemList(
      token,
    )
    let result = await backendApis.getGganbuChickenTeamInfo(token)
    // const itemInfo = gganbuChickenItemList?.data?.filter(
    //   (each) => each?._id === result?.data?.invitationInfo?.itemId,
    // )
    setGganbuChickenItemInfo(
      gganbuChickenItemList?.data?.filter(
        (each) => each?._id === result?.data?.invitationInfo?.itemId,
      )[0],
    )
    setGganbuChickenTeamInfo(result?.data)
    if (!result?.data) {
      result = await backendApis.initGganbuChickenTeam(token, 'POST', {
        invitorId: alwayzInvitorId,
        itemId: invitorItem,
        missionGganbuNum: gganbuChickenItemList?.data?.filter(
          (each) => each?.itemId,
        )[0]?.missionGganbuNum,
      })
    }
  }

  useEffect(() => {
    initGganbuChicken()
  }, [])

  useEffect(() => {
    document.addEventListener(
      visibilityChange,
      (visibility) => {
        const now = Date.now()
        if (document.visibilityState === 'visible') {
          initGganbuChicken()
          // setIsButtonDisable(false)
        }
      },
      false,
    )
  }, [token])

  const gganbuMissionButton = async () => {
    if (gganbuChickenTeamInfo?.invitationInfo?.isItemReceived) {
      alert.show('이미 치킨 주문을 완료했어요', {
        closeCopy: '확인',
      })
    } else if (
      !gganbuChickenTeamInfo?.invitationInfo?.isItemReceived &&
      gganbuChickenTeamInfo?.invitationInfo?.missionStatus === 'success' &&
      (gganbuChickenItemInfo?.stockNumber > 0 ||
        gganbuChickenTeamInfo?.invitationInfo?.missionGganbuNum !== 10)
    ) {
      window.location.href = `#toOrderMain.${JSON.stringify({
        props: {
          itemId: gganbuChickenTeamInfo?.invitationInfo?.orderItemId,
          enteringComponent: 'gganbuChicken',
          type: 'gganbuChicken',
          invitorId: null,
          eventPageMapping: 'gganbuChicken',
        },
      })}`
    } else if (
      !gganbuChickenTeamInfo?.invitationInfo?.isItemReceived &&
      gganbuChickenTeamInfo?.invitationInfo?.missionStatus === 'success' &&
      gganbuChickenItemInfo?.stockNumber <= 0 &&
      gganbuChickenTeamInfo?.invitationInfo?.missionGganbuNum === 10
    ) {
      // alert 띄우고 굽네치킨 5천원권으로 보내기!!!
      alert.show(
        'BBQ 치킨 재고가 모두 소진되어 굽네치킨 5천원권으로 대체하여 발송해드려요. 영업일 기준 7일 이내에 발송될 예정이니 조금만 기다려주세요!',
        {
          closeCopy: '확인',
        },
      )
      setTimeout(() => {
        window.location.href = `#toOrderMain.${JSON.stringify({
          props: {
            // dev 에서는 60edc235ffc0a087c1716690 production에서는 63db4b4f0bed9e366079a856
            itemId: '63db4b4f0bed9e366079a856',
            enteringComponent: 'gganbuChicken',
            type: 'gganbuChicken',
            invitorId: null,
            eventPageMapping: 'gganbuChicken',
          },
        })}`
      }, 3500)
    } else if (
      !gganbuChickenTeamInfo?.invitationInfo?.isItemReceived &&
      gganbuChickenTeamInfo?.invitationInfo?.missionStatus === 'fail'
    ) {
      alert.show(
        '24시간 내 맞팜하기 미션에 실패했어요. 새친구에게 공유하고 1+1 치킨을 받아보세요',
        {
          closeCopy: '확인',
        },
      )
    }
    // else if (
    //   gganbuChickenTeamInfo?.invitationInfo?.checkInHistory?.some(
    //     (each) => each === new Date().toISOString().slice(0, 10),
    //   )
    // ) {
    //   alert.show('오늘은 이미 출석체크를 완료했어요. 내일 다시 시도해주세요', {
    //     closeCopy: '확인',
    //   })
    // }
    else {
      const ongoingAlfarmData = await backendApis.getAlFarmUserData(token)
      if (ongoingAlfarmData?.status !== 200) {
        return
      }
      if (ongoingAlfarmData?.data?._id) {
        setIsAlreadyExist(true)
      } else {
        setIsAlreadyExist(false)
      }
      window.location.href = `#navigate.${JSON.stringify({
        screen: 'gameTest',
        prop: {
          origin: 'gganbuChicken',
          isAlreadyExist: !!ongoingAlfarmData?.data?._id,
          itemType: ongoingAlfarmData?.data?.itemType,
        },
      })}`
      //   const result = await backendApis.setGganbuChickenGganbuMission(token)
      //   setGganbuChickenTeamInfo(result?.data)
    }
  }

  return (
    <>
      <Layout>
        <Notice
          onClick={() => {
            setShowGganbuChickenExplainModal(true)
          }}
        />
        <Header title='1+1 치킨 이벤트' />
        <div style={{ height: '93%', overflowY: 'scroll' }}>
          <div
            style={{
              height: '100%',
              background:
                'linear-gradient(180deg, #FF9500 20%, #FFD01D 50%, #FF9500 70%, #FF9500 100%)',
              marginBottom: 10,
              fontFamily: 'GmarketSans',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              // overflowY: 'scroll',
            }}
          >
            <div
              style={{
                padding: 10,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <button
                type='button'
                style={{
                  textAlign: 'center',
                  color: 'white',
                  fontWeight: 700,
                  backgroundColor: '#EB4F47',
                  marginTop: 4,
                  padding: 8,
                  paddingLeft: 16,
                  paddingRight: 16,
                  border: 'none',
                  borderRadius: 4,
                  fontSize: 16,
                  fontFamily: 'GmarketSans',
                }}
                onClick={() => {
                  setShowGganbuChickenCloseNoticeModal(true)
                }}
              >
                1+1 치킨 이벤트 종료 안내 (3월 2일 종료)
              </button>
            </div>
            {/* <img
            src='/images/topLineImage.png'
            alt=''
            style={{
              width: '100vw',
              border: 'none',
            }}
          /> */}
            <div
              style={{
                fontSize: 32,
                marginTop: 8,
                color: '#704410',
                fontWeight: 'bold',
              }}
            >
              친구도 나도 받는 <span style={{ color: '#D00000' }}>1+1</span>
            </div>
            <div
              style={{
                fontSize: 16,
                marginTop: 4,
                marginBottom: 16,
                fontWeight: 'bold',
                color: '#905F02',
              }}
            >
              {gganbuChickenTeamInfo &&
              gganbuChickenTeamInfo?.invitationInfo?.missionClearGganbuNum ===
                0 ? (
                <>맞팜을 하고 1+1 치킨을 받아가세요</>
              ) : (
                <>
                  지금까지{' '}
                  <span
                    style={{
                      color: '#fff',
                      backgroundColor: '#905F02',
                      padding: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      borderRadius: 8,
                    }}
                  >
                    {gganbuChickenTeamInfo?.invitationInfo
                      ?.missionClearGganbuNum || 0}
                    명
                  </span>
                  의 친구와 맞팜을 했어요
                </>
              )}
            </div>
            <div
              style={{
                //   backgroundColor: 'blue',
                width: '88vw',
                height: '36vh',
                borderRadius: 20,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'GmarketSans',
                // overflowY: 'scroll',
                //   paddingTop: 8,
                //   paddingBottom: 16,
              }}
            >
              {/* <div
              style={{
                height: 20,
                marginTop: 20,
                paddingBottom: 12,
                // borderBottom: '1px solid rgba(64, 55, 58, 0.1)',
                fontWeight: 'bold',
              }}
            >
              {gganbuChickenItemInfo?.mainTitle}{' '}
              {gganbuChickenItemInfo?.subTitle}{' '}
              <span style={{ color: '#D00000' }}>1+1</span>
            </div> */}
              <div
                style={{
                  backgroundImage: "url('/images/speechBubble.png')",
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  width: 148,
                  height: 80,
                  padding: 8,
                  paddingTop: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  lineHeight: 1.3,
                  marginBottom: -44,
                }}
              >
                <div>
                  {gganbuChickenItemInfo?.mainTitle}
                  <div>
                    {gganbuChickenItemInfo?.subTitle}{' '}
                    <span style={{ color: '#D00000' }}>1+1</span>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                {gganbuChickenTeamInfo?.invitationInfo?.missionStatus !==
                  'onGoing' && (
                  <>
                    <div
                      style={{
                        position: 'absolute',
                        zIndex: 3,
                      }}
                    >
                      <img
                        alt=''
                        src={`${
                          gganbuChickenTeamInfo?.invitationInfo
                            ?.missionStatus === 'success'
                            ? '/images/missionSuccess.png'
                            : '/images/missionFail.png'
                        }`}
                        style={{ height: 60, marginTop: 12 }}
                      />
                    </div>
                  </>
                )}
                <img
                  src={`${gganbuChickenItemInfo?.mainImageUri}`}
                  alt=''
                  style={{ height: 180, marginBottom: -12 }}
                />
              </div>
              {gganbuChickenTeamInfo?.invitationInfo?.missionStatus ===
                'onGoing' && (
                <>
                  <div>
                    <span
                      style={{
                        color: 'white',
                        backgroundColor: '#905F02',
                        padding: 4,
                        borderRadius: 4,
                        fontWeight: 'bold',
                      }}
                    >
                      남은시간
                    </span>{' '}
                    {gganbuChickenTeamInfo?.invitationInfo?.invitedAt ? (
                      <BlackTextTimer
                        color='#40373A'
                        timeStamp={
                          new Date(
                            gganbuChickenTeamInfo?.invitationInfo?.invitedAt,
                          )
                        }
                        timerMinutes={60 * 24}
                      />
                    ) : (
                      '00:00:00'
                    )}
                  </div>
                </>
              )}

              {/* {gganbuChickenTeamInfo?.invitationInfo?.missionGganbuNum ===
                10 && (
                <>
                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: 12,
                      color: '#905F02',
                      marginTop: 8,
                    }}
                  >
                    *준비된 재고가 모두 소진되면 <br />
                    미션을 성공하여도 굽네치킨 5천원권으로 대체돼요
                  </div>
                </>
              )} */}
            </div>

            <div
              style={{
                backgroundColor: '#fff',
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20,
                marginTop: 20,
                height: 148,
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: 260,
              }}
            >
              <div
                style={{
                  fontFamily: 'MapleStory',
                  textAlign: 'left',
                  width: '100%',
                  // backgroundColor: 'red',
                  marginBottom: 40,
                  marginTop: 16,
                  marginLeft: 80,
                  fontWeight: 'bold',
                  color: '#765C2B',
                }}
              >
                <span
                  style={{
                    color: '#765C2B',
                    backgroundColor: '#F6F0E5',
                    padding: 6,
                    borderRadius: 12,
                    fontWeight: 'bold',
                  }}
                >
                  TIP
                </span>
                <button
                  type='button'
                  style={{
                    background: 'none',
                    border: 'none',
                    fontFamily: 'MapleStory',
                    fontWeight: 'bold',
                    fontSize: 16,
                    color: '#765C2B',
                  }}
                  onClick={() => {
                    setShowGganbuChickenGganbuExplainModal(true)
                  }}
                >
                  <span style={{}}>맞팜</span>이란?
                </button>
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '80vw' }}
              >
                <button
                  type='button'
                  style={{
                    border: 'none',
                    background: 'none',
                    backgroundColor: '#FFCA28',
                    // width: '80vw',
                    height: 56,
                    marginTop: 12,
                    borderRadius: 12,
                    color: '#392500',
                    fontWeight: 'bold',
                    fontSize: 16,
                    flex: 1,
                  }}
                  //   className={`${'scaleUp'}`}
                  onClick={() => {
                    debounce(gganbuMissionButton, 500)
                  }}
                >
                  {gganbuChickenTeamInfo?.invitationInfo?.missionStatus !==
                  'onGoing' ? (
                    <>
                      {' '}
                      <div
                        style={{
                          fontFamily: 'MapleStory',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'relative',
                          fontSize: 18,
                        }}
                      >
                        <img
                          style={{
                            height: 60,
                            marginBottom: 72,
                            top: -60,
                            position: 'absolute',
                          }}
                          alt=''
                          src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/e50c7eee-bd8e-4e81-8535-d83713e5d00a.png'
                        />
                        {gganbuChickenTeamInfo?.invitationInfo
                          ?.missionStatus === 'success' ? (
                          <>
                            {gganbuChickenTeamInfo?.invitationInfo
                              ?.isItemReceived
                              ? '이미 치킨 추첨에 참여했어요'
                              : '치킨 받으러가기'}{' '}
                          </>
                        ) : (
                          <>맞팜하기 미션에 실패했어요</>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      {' '}
                      <div
                        style={{
                          fontFamily: 'MapleStory',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'relative',
                          fontSize: 18,
                        }}
                      >
                        <img
                          style={{
                            height: 60,
                            marginBottom: 72,
                            top: -60,
                            position: 'absolute',
                          }}
                          alt=''
                          src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/e50c7eee-bd8e-4e81-8535-d83713e5d00a.png'
                        />
                        맞팜하러 가기
                        {gganbuChickenTeamInfo?.invitationInfo
                          ?.missionClearGganbuNum !== 0 && (
                          <div style={{ marginLeft: 4 }}>
                            (
                            {gganbuChickenTeamInfo?.invitationInfo
                              ?.missionClearGganbuNum || 0}
                            명/
                            {gganbuChickenTeamInfo?.invitationInfo
                              ?.missionGganbuNum || 1}
                            명)
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </button>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '80vw',
                }}
              >
                <>
                  {' '}
                  <button
                    type='button'
                    style={{
                      border: 'none',
                      background: 'none',
                      backgroundColor: '#40373A',
                      height: 56,
                      marginTop: 12,
                      borderRadius: 12,
                      color: '#fff',
                      fontWeight: 'bold',
                      fontSize: 16,
                      flex: 1,
                    }}
                    onClick={() => {
                      window.location.href = `/gganbu-chicken-event/?token=${token}&isFrom=invitation&randomString=${Math.random()
                        .toString(36)
                        .substr(2, 8)}`
                    }}
                  >
                    <div style={{ fontFamily: 'GmarketSans' }}>
                      나도 친구 데려와서 2배로 받기
                    </div>
                  </button>
                </>
              </div>
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  margin: 16,
                  paddingBottom: 8,
                  width: '80vw',
                  textAlign: 'center',
                  borderBottom: '1px solid rgba(64, 55, 58, 0.1)',
                }}
              >
                참여방법
              </div>
              <div
                style={{
                  fontSize: 14,
                  width: '80vw',
                  height: 80,
                  color: '#63565B',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  marginBottom: 40,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#F7F3E8',
                      color: '#63565B',
                      width: 20,
                      height: 20,
                      borderRadius: 10,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: 8,
                    }}
                  >
                    1
                  </div>{' '}
                  <div>올웨이즈에 가입하여 올팜 맞팜을 해요</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#F7F3E8',
                      color: '#63565B',
                      width: 20,
                      height: 20,
                      borderRadius: 10,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: 8,
                    }}
                  >
                    2
                  </div>{' '}
                  <div>
                    24시간 내 {gganbuChickenItemInfo?.missionGganbuNum}명과
                    맞팜하기 미션을 완료해요
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#F7F3E8',
                      color: '#63565B',
                      width: 20,
                      height: 20,
                      borderRadius: 10,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: 8,
                    }}
                  >
                    3
                  </div>{' '}
                  <div>친구와 나 모두 치킨 이벤트에 참여가 돼요</div>
                </div>

                {gganbuChickenTeamInfo?.invitationInfo?.missionGganbuNum ===
                  10 && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          // backgroundColor: '#F7F3E8',
                          // color: '#63565B',
                          width: 20,
                          height: 20,
                          borderRadius: 10,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginRight: 8,
                        }}
                      />
                      <div style={{ color: '#D00000', lineHeight: 1.2 }}>
                        *준비된 재고가 모두 소진되면 <br /> 성공해도 굽네치킨
                        5천원권으로 대체돼요
                      </div>
                    </div>
                  </>
                )}

                {/* {gganbuChickenTeamInfo?.invitationInfo?.missionGganbuNum ===
                10 && (
                <>
                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: 12,
                      color: '#905F02',
                      marginTop: 8,
                    }}
                  >
                    *준비된 재고가 모두 소진되면 <br />
                    미션을 성공하여도 굽네치킨 5천원권으로 대체돼요
                  </div>
                </>
              )} */}
              </div>
            </div>
          </div>
        </div>
        {showGganbuChickenSendHistoryModal && (
          <GganbuChickenSendHistoryModal
            setShowGganbuChickenSendHistoryModal={
              setShowGganbuChickenSendHistoryModal
            }
            token={token}
            guestInfosArray={gganbuChickenTeamInfo?.guestInfosArray}
          />
        )}
        {showGganbuChickenExplainModal && (
          <GganbuChickenExplainModal
            setShowGganbuChickenExplainModal={setShowGganbuChickenExplainModal}
          />
        )}
        {showGganbuChickenGganbuExplainModal && (
          <GganbuChickenGganbuExplainModal
            setShowGganbuChickenGganbuExplainModal={
              setShowGganbuChickenGganbuExplainModal
            }
          />
        )}
        {showGganbuChickenCloseNoticeModal && (
          <GganbuChickenCloseNoticeModal
            setShowGganbuChickenCloseNoticeModal={
              setShowGganbuChickenCloseNoticeModal
            }
            token={token}
          />
        )}
      </Layout>
    </>
  )
}
export default GganbuChickenInvitation
