import React from 'react'
import { observer } from 'mobx-react-lite'

const NewUserNudgeModal = observer(
  ({ setShowNewUserNudgeModal, userName, onClick = () => {} }) => (
    <>
      <div
        style={{
          flex: 1,
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          zIndex: 100,
          backgroundColor: 'rgba(0,0,0,0.8)',
          width: '100%',
          height: '100%',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: 24,
            boxSizing: 'border-box',
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              zIndex: 2,
              flex: 1,
              top: '24%',
              textAlign: 'center',
              paddingTop: 24,
              paddingBottom: 18,
              borderRadius: 18,
            }}
          >
            <div
              style={{
                marginBottom: 12,
                fontSize: 18,
                fontWeight: '600',
              }}
            >
              {userName}님의 0원 상점이 열렸어요!
            </div>
            {/* <div
                style={{
                  marginBottom: 12,
                  fontSize: 36,
                  fontWeight: '600',
                }}
              >
                🥳
              </div> */}
            <div
              style={{
                marginBottom: 6,
                fontSize: 16,
              }}
            >
              새친구에게만 드리는 혜택
            </div>
            <div
              style={{
                marginBottom: 16,
                fontSize: 16,
              }}
            >
              <span
                style={{ color: '#ff3e3e', fontWeight: 700, paddingLeft: 4 }}
              >
                30초 미션
              </span>
              하고 0원 커피 받아가세요!
            </div>
            {/* <div
              style={{
                marginBottom: 12,
                fontSize: 36,
                fontWeight: '600',
                color: '#ff3e3e',
              }}
            >
              + 47,500원
            </div> */}
            <div
              style={{
                // marginBottom: 12,
                // fontSize: 36,
                fontWeight: '600',
                // backgroundColor: 'red',
                // color: '#ff3e3e',
                // height: '50vw',
              }}
            >
              <img
                alt=''
                style={{ width: '50%' }}
                src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/c4ca34f8-0772-49ad-aa0b-65f1aa1d5773_original.jpg'
              />
            </div>
            <button
              type='button'
              style={{
                bottom: '0',
                right: '0',
                fontSize: 18,
                borderRadius: 30,
                height: 48,
                borderWidth: 0,
                background: '#F09637',
                width: '90%',
                margin: 4,
              }}
              onClick={() => {
                setShowNewUserNudgeModal(false)
                onClick()
              }}
            >
              <div
                style={{
                  color: 'white',
                  fontSize: 18,
                  fontWeight: 'bold',
                }}
              >
                0원 커피 받으러 가기
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  ),
)
export default NewUserNudgeModal
