import React from 'react'
import { observer } from 'mobx-react-lite'

const ZeroWonStoreNoticeModal = observer(
  ({ setShowZeroWonStoreNoticeModal, onClick = () => {} }) => (
    <>
      <div
        style={{
          flex: 1,
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          zIndex: 100,
          backgroundColor: 'rgba(0,0,0,0.8)',
          width: '100%',
          height: '100%',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: 24,
            boxSizing: 'border-box',
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              zIndex: 2,
              flex: 1,
              top: '24%',
              textAlign: 'center',
              paddingTop: 24,
              paddingBottom: 18,
              borderRadius: 18,
            }}
          >
            <div
              style={{
                marginBottom: 12,
                fontSize: 18,
                fontWeight: '600',
              }}
            >
              0원상점 접속 불가 시간 연장 안내
            </div>
            {/* <div
                style={{
                  marginBottom: 12,
                  fontSize: 36,
                  fontWeight: '600',
                }}
              >
                🥳
              </div> */}
            <div
              style={{
                marginBottom: 16,
                fontSize: 16,
              }}
            >
              <div>안녕하세요</div>
              <div>항상 0원상점을 이용해주셔서 감사합니다.</div>
            </div>
            <div
              style={{
                marginBottom: 16,
                fontSize: 16,
              }}
            >
              <div>12월 7일(수) 발생한 장애로 인해 서비스</div>
              <div>이용에 불편을 드려 죄송합니다.</div>
            </div>
            <div
              style={{
                marginBottom: 16,
                fontSize: 16,
              }}
            >
              <div>일시적으로 트래픽 증가량이 폭주하여</div>
              <div>0원 상점의 친구 초대가 불가하였습니다.</div>
            </div>
            <div
              style={{
                marginBottom: 16,
                fontSize: 16,
              }}
            >
              <div>이로 인한 불편을 겪으신 분들의 경우</div>
              <div>고객센터에 문의를 주시면</div>
              <div>0원상점 시간 연장을 도와드리겠습니다.</div>
            </div>
            <div
              style={{
                marginBottom: 16,
                fontSize: 16,
              }}
            >
              <div>다시 한번 불편을 드려 죄송합니다.</div>
              <div>감사합니다.</div>
            </div>
            {/* <div
              style={{
                marginBottom: 12,
                fontSize: 36,
                fontWeight: '600',
                color: '#ff3e3e',
              }}
            >
              + 47,500원
            </div> */}
            <div
              style={{
                // marginBottom: 12,
                // fontSize: 36,
                fontWeight: '600',
                // backgroundColor: 'red',
                // color: '#ff3e3e',
                // height: '50vw',
              }}
            />
            <button
              type='button'
              style={{
                bottom: '0',
                right: '0',
                fontSize: 18,
                borderRadius: 30,
                height: 48,
                borderWidth: 0,
                background: '#F09637',
                width: '90%',
                margin: 4,
              }}
              onClick={() => {
                setShowZeroWonStoreNoticeModal(false)
                onClick()
              }}
            >
              <div
                style={{
                  color: 'white',
                  fontSize: 18,
                  fontWeight: 'bold',
                }}
              >
                닫기
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  ),
)
export default ZeroWonStoreNoticeModal
