import React, { useState } from 'react'

const NewYearsMoneyCloseNoticeModal = ({
  setShowNewYearsMoneyCloseNoticeModal,
}) => (
  <>
    <div
      style={{
        flex: 1,
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 3,
        backgroundColor: 'rgba(0,0,0,0.8)',
        width: '100%',
        height: '100%',
        fontFamily: 'GmarketSans',
      }}
    >
      <button
        type='button'
        style={{
          width: 100,
          height: 100,
          backgroundColor: 'red',
          background: 'none',
          border: 'none',
          top: '28%',
          right: '1%',
          position: 'absolute',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          setShowNewYearsMoneyCloseNoticeModal(false)
          window.location.href = `#navigate.${JSON.stringify({
            screen: 'MainStackAMainTabNavigator',
          })}`
        }}
      >
        <img
          style={{
            zIndex: 101,
            width: '36%',
            height: '36%',
            position: 'absolute',
          }}
          src='/images/closeIcon.png'
          alt=''
        />
      </button>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          height: '100%',
          padding: 24,
          boxSizing: 'border-box',
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            zIndex: 4,
            // top: '24%',
            textAlign: 'center',
            paddingTop: 20,
            // paddingBottom: 18,
            borderRadius: 20,
            width: '90vw',
            height: '36vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              fontWeight: 900,
              fontSize: 18,
              color: 'black',
              lineHeight: 1.4,
              marginTop: 8,
              marginBottom: 12,
              //   marginBottom: 28,
            }}
          >
            ※ 이벤트가 종료되었어요{' '}
          </div>
          <div
            style={{
              fontWeight: 500,
              color: 'black',
              lineHeight: 1.4,
              fontSize: 16,
            }}
          >
            <span style={{ color: '#EB4F47', fontWeight: 700 }}>
              2월 2일 목요일 오후 4시
            </span>
            에
          </div>
          <div
            style={{
              fontWeight: 500,
              color: 'black',
              lineHeight: 1.4,
              marginBottom: 12,
            }}
          >
            세뱃돈 이벤트가 종료되었어요.
          </div>
          <div
            style={{
              fontWeight: 500,
              color: 'black',
              lineHeight: 1.4,
            }}
          >
            그동안 보내주신 관심에 감사드리며
          </div>
          <div
            style={{
              fontWeight: 500,
              color: 'black',
              lineHeight: 1.4,
              marginBottom: 12,
            }}
          >
            더 재밌는 이벤트로 다시 찾아뵐게요
          </div>
          <div
            style={{
              fontWeight: 500,
              color: 'black',
              lineHeight: 1.4,
            }}
          >
            더 자세한 안내가 필요하신 경우
          </div>
          <div
            style={{
              fontWeight: 500,
              color: 'black',
              lineHeight: 1.4,
            }}
          >
            고객센터를 이용해주세요.
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <button
              type='button'
              style={{
                bottom: '0',
                right: '0',
                fontSize: 18,
                borderBottomLeftRadius: 20,
                borderBottomRightRadius: 20,
                height: 48,
                borderWidth: 0,
                background: '#8C8D8F',
                width: '100%',
                paddingTop: 20,
                paddingBottom: 40,
                marginTop: 28,
              }}
              onClick={() => {
                setShowNewYearsMoneyCloseNoticeModal(false)
                window.location.href = `#navigate.${JSON.stringify({
                  screen: 'MainStackAMainTabNavigator',
                })}`
              }}
            >
              <div
                style={{
                  color: 'white',
                  fontSize: 20,
                  fontWeight: 'bold',
                  paddingLeft: 8,
                  paddingRigtt: 8,
                }}
              >
                닫기
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default NewYearsMoneyCloseNoticeModal
