import React, { useState, useEffect } from 'react'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import Layout from '../components/layout'
import Header from '../components/header'
import Notice from '../components/Notice'
import BlackTextTimer from '../components/BlackTextTimer'
import commaNumber from 'comma-number'
import { useAlert } from 'react-alert'
import useDebounce from '../components/useDebounce'
import OnePlusOneSelectModal from '../modals/OnePlusOneSelectModal'
import OnePlusOneSendHistoryModal from '../modals/OnePlusOneSendHistoryModal'
import OnePlusOneExplainModal from '../modals/OnePlusOneExplainModal'
import OnePlusOneCloseNoticeModal from '../modals/OnePlusOneCloseNoticeModal'

const isButtonDisable = false
let hidden
let visibilityChange
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

function OnePlusOne() {
  const alert = useAlert()
  const token = useSearchParam('token')
  const alwayzInvitorId = useSearchParam('alwayzInvitorId')
  const itemId = useSearchParam('itemId')
  const isFrom = useSearchParam('isFrom')
  const code = useSearchParam('code')
  const [itemIndex, setItemIndex] = useState(1)
  const [onePlusOneItemList, setOnePlusOneItemList] = useState('')
  const [onePlusOneTeamInfo, setOnePlusOneTeamInfo] = useState('')
  const [userInfo, setUserInfo] = useState('')
  const [showOnePlusOneSelectModal, setShowOnePlusOneSelectModal] =
    useState(false)
  const [showOnePlusOneSendHistoryModal, setShowOnePlusOneSendHistoryModal] =
    useState(false)
  const [showOnePlusOneExplainModal, setShowOnePlusOneExplainModal] =
    useState(false)
  const [showOnePlusOneCloseNoticeModal, setShowOnePlusOneCloseNoticeModal] =
    useState(true)
  const initOnePlusOne = async () => {
    const onePlusOneItemList = await backendApis.getOnePlusOneItemList(token)
    setOnePlusOneItemList(onePlusOneItemList?.data)
    const userInfo = await backendApis.getUserBasicInfoWithAuthentication(token)
    setUserInfo(userInfo?.data)

    if (
      userInfo?.status !== 200 ||
      userInfo?.data?.phoneNumber === '' ||
      userInfo?.data?.userName === ''
    ) {
      window.location.href = '#openLoginModal.'
    } else {
      const result = await backendApis.getOnePlusOneTeamInfo(token)
      setOnePlusOneTeamInfo(result?.data)
      if (alwayzInvitorId && result?.data) {
        alert.show(
          '올웨이즈를 처음하는 고객만 친구가 보낸 링크로 참여할 수 있어요. 무료 상품 링크를 공유하고 1+1 상품을 받아보세요',
          {
            closeCopy: '확인',
          },
        )
        return
      }

      if (
        (result?.data?.invitationInfo?.missionStatus === 'onGoing' ||
          (result?.data?.invitationInfo?.missionStatus === 'success' &&
            !result?.data?.invitationInfo?.isItemReceived)) &&
        isFrom !== 'invitation'
      ) {
        // window.location.href = `/one-plus-one-invitation/?token=${token}&randomString=${Math.random()
        //   .toString(36)
        //   .substr(2, 8)}`
        return
      }

      if (isFrom && isFrom !== 'invitation') {
        alert.show(
          '상품 주문이 완료되었어요. 친구에게 1+1 상품을 공유하고 한번 더 상품을 받아보세요',
          {
            closeCopy: '확인',
          },
        )
        return
      }

      if (!result?.data) {
        const initOnePlusOneInfo = await backendApis.initOnePlusOneTeam(
          token,
          'POST',
          {
            invitorId: alwayzInvitorId,
            itemId,
            orderItemId: onePlusOneItemList?.data?.filter(
              (each) => each?._id === itemId,
            )[0]?.itemId,
            missionDaysNum: onePlusOneItemList?.data?.filter(
              (each) => each?._id === itemId,
            )[0]?.missionDaysNum,
          },
        )
        if (initOnePlusOneInfo?.data?.isNewUser) {
          // window.location.href = `/one-plus-one-invitation/?token=${token}&randomString=${Math.random()
          //   .toString(36)
          //   .substr(2, 8)}`
        }
        // else if (initOnePlusOneInfo?.data?.msg === 'is not new user') {
        //   alert.show(
        //     '올웨이즈를 처음하는 고객만 친구가 보낸 상품을 받을 수 있어요. 친구에게 공유하고 1+1 상품을 받아보세요',
        //     {
        //       closeCopy: '확인',
        //     },
        //   )
        // }
        else if (initOnePlusOneInfo?.data?.msg === 'invitee is under age') {
          alert.show('본 이벤트는 20세 이상 고객만 참여 가능해요', {
            closeCopy: '확인',
          })
          setOnePlusOneTeamInfo(initOnePlusOneInfo?.data)
        } else if (
          initOnePlusOneInfo?.data?.msg === 'host and invitee are same'
        ) {
          alert.show(
            '자신이 공유한 링크로 자신이 받을 수는 없어요. 친구에게 공유하고 1+1 상품을 받아보세요',
            {
              closeCopy: '확인',
            },
          )
          setOnePlusOneTeamInfo(initOnePlusOneInfo?.data)
        } else {
          setOnePlusOneTeamInfo(initOnePlusOneInfo?.data)
          // alert.show('알 수 없는 오류가 발생했어요. 다시 시도해주세요', {
          //   closeCopy: '확인',
          // })
        }
      }
    }
  }

  useEffect(() => {
    initOnePlusOne()
  }, [])

  useEffect(() => {
    document.addEventListener(
      visibilityChange,
      (visibility) => {
        const now = Date.now()
        if (document.visibilityState === 'visible') {
          initOnePlusOne()
        }
      },
      false,
    )
  }, [token])

  return (
    <>
      <Layout>
        <Notice
          onClick={() => {
            setShowOnePlusOneExplainModal(true)
          }}
        />
        <Header title='1+1 이벤트' />
        <div
          style={{
            width: '100%',
            height: '100vh',
            backgroundColor: '#F7F3E8',
            marginBottom: 10,
            fontFamily: 'GmarketSans',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              padding: 10,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              type='button'
              style={{
                textAlign: 'center',
                color: 'white',
                fontWeight: 700,
                backgroundColor: '#EB4F47',
                marginTop: 0,
                padding: 8,
                paddingLeft: 16,
                paddingRight: 16,
                border: 'none',
                borderRadius: 4,
                fontSize: 16,
                fontFamily: 'GmarketSans',
              }}
              onClick={() => {
                setShowOnePlusOneCloseNoticeModal(true)
              }}
            >
              무료상품 이벤트 종료 안내 (2월 2일 종료)
            </button>
          </div>
          {/* <img
            src='/images/topLineImage.png'
            alt=''
            style={{
              width: '100vw',
              border: 'none',
            }}
          /> */}
          <div
            style={{
              fontSize: 32,
              marginTop: 12,
              color: '#704410',
              fontWeight: 'bold',
            }}
          >
            친구도 나도 받는 <span style={{ color: '#FF8A00' }}>1+1</span>
          </div>
          <div
            style={{
              fontSize: 16,
              marginTop: 8,
              marginBottom: 32,
              fontWeight: 'bold',
              color: '#978143',
            }}
          >
            무료 상품을 선택하고 친구와 공유하세요
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              //   backgroundColor: 'red',
            }}
          >
            <div
              style={{
                backgroundImage: "url('/images/yellowCircle.png')",
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '68vw',
                height: 280,
                marginLeft: -20,
                marginRight: -20,
                position: 'absolute',
                left: -200,
              }}
            />
            <button
              type='button'
              style={{ background: 'none', border: 'none', zIndex: 2 }}
              onClick={() => {
                if (itemIndex < 2) {
                  setItemIndex(4)
                } else {
                  setItemIndex(itemIndex - 1)
                }
              }}
            >
              <div style={{ zIndex: 2 }}>
                <img
                  src='/images/leftArrow.png'
                  alt=''
                  style={{ height: 52, width: 52 }}
                />
              </div>
            </button>
            <div
              style={{
                backgroundImage: "url('/images/yellowCircle.png')",
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '68vw',
                height: 280,
                marginLeft: -20,
                marginRight: -20,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  backgroundImage: "url('/images/speechBubble.png')",
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  width: 148,
                  height: 80,
                  padding: 8,
                  paddingTop: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div>
                  {
                    onePlusOneItemList?.filter?.(
                      (each) => each?.index === itemIndex,
                    )[0]?.mainTitle
                  }
                  <div>
                    {
                      onePlusOneItemList?.filter?.(
                        (each) => each?.index === itemIndex,
                      )[0]?.subTitle
                    }{' '}
                    <span style={{ color: '#FF8A00' }}>1+1</span>
                  </div>
                </div>
              </div>
              <img
                alt=''
                style={{
                  marginTop: -68,
                  width: '100%',
                }}
                src={`${
                  onePlusOneItemList?.filter?.(
                    (each) => each?.index === itemIndex,
                  )[0]?.mainImageUri
                }`}
              />
            </div>
            <button
              type='button'
              style={{ background: 'none', border: 'none', zIndex: 2 }}
              onClick={() => {
                if (itemIndex < 4) {
                  setItemIndex(itemIndex + 1)
                } else {
                  setItemIndex(1)
                }
              }}
              className={`${'scaleUp'}`}
            >
              <div style={{ zIndex: 2 }}>
                <img
                  src='/images/rightArrow.png'
                  alt=''
                  style={{ height: 52, width: 52 }}
                />
              </div>
            </button>
            <div
              style={{
                backgroundImage: "url('/images/yellowCircle.png')",
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '68vw',
                height: 280,
                marginLeft: -20,
                marginRight: -20,
                position: 'absolute',
                right: -200,
              }}
            />
          </div>
          <div
            style={{
              backgroundColor: '#fff',
              width: '100%',
              height: 500,
              marginTop: -40,
              borderTopRightRadius: 20,
              borderTopLeftRadius: 20,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              fontFamily: 'GmarketSans',
              overflowY: 'scroll',
            }}
          >
            <div
              style={{
                marginTop: 12,
                textAlign: 'center',
                fontSize: 12,
                color: '#9d9699',
              }}
            >
              *각 상품은 한정수량으로 진행되어 조기 품절될 수 있어요
            </div>
            <button
              type='button'
              style={{
                border: 'none',
                background: 'none',
                backgroundColor: '#FF4D00',
                width: '88vw',
                height: 48,
                marginTop: 8,
                borderRadius: 12,
                color: '#fff',
                fontWeight: 'bold',
                fontSize: 16,
              }}
              onClick={async () => {
                const userInfoForCheck =
                  await backendApis.getUserBasicInfoWithAuthentication(token)
                if (
                  userInfoForCheck?.status !== 200 ||
                  userInfoForCheck?.data?.phoneNumber === '' ||
                  userInfoForCheck?.data?.userName === ''
                ) {
                  window.location.href = '#openLoginModal.'
                  return
                }
                if (userInfoForCheck?.data?.phoneNumber !== '') {
                  const result = await backendApis.getOnePlusOneTeamInfo(token)
                  if (!result?.data) {
                    const onePlusOneItemList =
                      await backendApis.getOnePlusOneItemList(token)
                    const initOnePlusOneInfo =
                      await backendApis.initOnePlusOneTeam(token, 'POST', {
                        invitorId: alwayzInvitorId,
                        itemId,
                        orderItemId: onePlusOneItemList?.data?.filter(
                          (each) => each?._id === itemId,
                        )[0]?.itemId,
                        missionDaysNum: onePlusOneItemList?.data?.filter(
                          (each) => each?._id === itemId,
                        )[0]?.missionDaysNum,
                      })
                    if (initOnePlusOneInfo?.data?.isNewUser) {
                      // 모달 띄우기
                      window.location.href = `/one-plus-one-invitation/?token=${token}&randomString=${Math.random()
                        .toString(36)
                        .substr(2, 8)}`
                    } else if (
                      initOnePlusOneInfo?.data?.msg === 'is not new user'
                    ) {
                      alert.show(
                        '올웨이즈를 처음하는 고객만 친구가 보낸 상품을 받을 수 있어요. 친구에게 공유하고 1+1 상품을 받아보세요',
                        {
                          closeCopy: '확인',
                        },
                      )
                      setOnePlusOneTeamInfo(initOnePlusOneInfo?.data)
                    } else if (
                      initOnePlusOneInfo?.data?.msg === 'invitee is under age'
                    ) {
                      alert.show('본 이벤트는 20세 이상 고객만 참여 가능해요', {
                        closeCopy: '확인',
                      })
                      setOnePlusOneTeamInfo(initOnePlusOneInfo?.data)
                    } else if (
                      initOnePlusOneInfo?.data?.msg ===
                      'host and invitee are same'
                    ) {
                      alert.show(
                        '자신이 공유한 링크로 자신이 받을 수는 없어요. 친구에게 공유하고 1+1 상품을 받아보세요',
                        {
                          closeCopy: '확인',
                        },
                      )
                      setOnePlusOneTeamInfo(initOnePlusOneInfo?.data)
                    } else {
                      alert.show(
                        '알 수 없는 오류가 발생했어요. 다시 시도해주세요',
                        {
                          closeCopy: '확인',
                        },
                      )
                    }
                    return
                  }
                }

                if (
                  onePlusOneItemList?.filter(
                    (each) => each?.index === itemIndex,
                  )[0]?.stockNumber < 1
                ) {
                  alert.show(
                    '해당 상품의 재고가 모두 소진되었어요. 다른 상품을 선택해주세요',
                    {
                      closeCopy: '확인',
                    },
                  )
                  return
                }
                setShowOnePlusOneSelectModal(true)
                window.location.href = `#openBottomShareSheet.${JSON.stringify({
                  code: `onePlusOne${itemIndex}`,
                  shareParams: onePlusOneItemList?.filter(
                    (each) => each?.index === itemIndex,
                  )[0]?.itemId,
                })}`
              }}
            >
              <div style={{ fontFamily: 'GmarketSans' }}>
                {onePlusOneTeamInfo ? '상품 선택하기' : '상품 받으러 가기'}
              </div>
            </button>
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '88vw' }}
            >
              {onePlusOneTeamInfo?.guestInfosArray?.length > 0 && (
                <>
                  {' '}
                  <button
                    type='button'
                    style={{
                      border: 'none',
                      background: 'none',
                      backgroundColor: '#40373A',
                      height: 48,
                      marginTop: 8,
                      borderRadius: 12,
                      color: '#fff',
                      fontWeight: 'bold',
                      fontSize: 16,
                      flex: 1,
                    }}
                    onClick={() => {
                      setShowOnePlusOneSendHistoryModal(true)
                    }}
                  >
                    <div style={{ fontFamily: 'GmarketSans' }}>
                      내가 보낸 목록
                    </div>
                  </button>
                </>
              )}
              {onePlusOneTeamInfo?.guestInfosArray?.length > 0 &&
              onePlusOneTeamInfo?.invitationInfo?.invitorId ? (
                <div style={{ width: '2vw' }} />
              ) : (
                <>
                  {' '}
                  <div style={{ height: 20 }} />
                </>
              )}
              {onePlusOneTeamInfo?.invitationInfo?.invitorId && (
                <>
                  {' '}
                  <button
                    type='button'
                    style={{
                      border: 'none',
                      background: 'none',
                      backgroundColor: '#40373A',
                      height: 48,
                      marginTop: 8,
                      borderRadius: 12,
                      color: '#fff',
                      fontWeight: 'bold',
                      fontSize: 16,
                      flex: 1,
                    }}
                    onClick={() => {
                      window.location.href = `/one-plus-one-invitation/?token=${token}&randomString=${Math.random()
                        .toString(36)
                        .substr(2, 8)}`
                    }}
                  >
                    <div style={{ fontFamily: 'GmarketSans' }}>
                      내가 받은 목록
                    </div>
                  </button>
                </>
              )}
            </div>
            <div>
              <img
                src='/images/onePlusOneRuleImage.png'
                alt=''
                style={{ width: '88vw', marginTop: 20 }}
              />
            </div>
          </div>
        </div>
        {showOnePlusOneSelectModal && (
          <OnePlusOneSelectModal
            setShowOnePlusOneSelectModal={setShowOnePlusOneSelectModal}
            token={token}
            itemInfo={
              onePlusOneItemList?.filter((each) => each?.index === itemIndex)[0]
            }
          />
        )}
        {showOnePlusOneSendHistoryModal && (
          <OnePlusOneSendHistoryModal
            setShowOnePlusOneSendHistoryModal={
              setShowOnePlusOneSendHistoryModal
            }
            token={token}
            guestInfosArray={onePlusOneTeamInfo?.guestInfosArray}
          />
        )}
        {showOnePlusOneExplainModal && (
          <OnePlusOneExplainModal
            setShowOnePlusOneExplainModal={setShowOnePlusOneExplainModal}
          />
        )}
        {showOnePlusOneCloseNoticeModal && (
          <OnePlusOneCloseNoticeModal
            setShowOnePlusOneCloseNoticeModal={
              setShowOnePlusOneCloseNoticeModal
            }
            token={token}
          />
        )}
      </Layout>
    </>
  )
}
export default OnePlusOne
