import React from 'react'
import Timer from './Timer'

const BlackTextTimer = ({
  timeStamp,
  onTimeEnd,
  interval = 100,
  timerMinutes = 60 * 24,
  color = 'black',
}) => (
  <span
    style={{
      color,
      fontWeight: 'bold',
    }}
  >
    {Timer({ timeStamp, onTimeEnd, interval, timerMinutes }).formattedTime}
  </span>
)

export default BlackTextTimer
