import React, { useState, useEffect } from 'react'
import backendApis from '../utils/backendApis'
import Layout from '../components/layout'
import Header from '../components/header'
import { useSearchParam } from 'react-use'
import commaNumber from 'comma-number'
import appjson from '../../package.json'
import { useAlert } from 'react-alert'
import ZeroWonStoreSelectConfirmModal from '../components/ZeroWonStoreSelectConfirmModal'
import ZeroWonStoreCloseNoticeModal from '../modals/ZeroWonStoreCloseNoticeModal'

const subTitle = (joinCount) => {
  if (joinCount) {
    return `${joinCount}명 참여완료`
  }
  return ''
}

const successSubTitle = (successCount) => {
  if (successCount) {
    return `${successCount}명이 0원에 받아갔어요!`
  }
  return ''
}

const adviceSubTitle = (price) => {
  if (price < 3000) {
    return (
      <>
        <div
          style={{
            backgroundColor: '#09AF00',
            color: 'white',
            borderRadius: 4,
            fontSize: 12,
            display: 'flex',
            fontWeight: 'bold',
            justifyContent: 'center',
            boxSizing: 'border-box',
            marginLeft: 20,
            width: 128,
            padding: 2,
            marginTop: 4,
          }}
        >
          1명 초대하면 바로 성공!
        </div>
      </>
    )
  }
  if (price < 15000) {
    return (
      <>
        <div
          style={{
            backgroundColor: '#F09637',
            color: 'white',
            borderRadius: 4,
            fontSize: 12,
            display: 'flex',
            fontWeight: 'bold',
            justifyContent: 'center',
            boxSizing: 'border-box',
            marginLeft: 20,
            width: 80,
            padding: 2,
            marginTop: 4,
          }}
        >
          받기 쉬워요!
        </div>
      </>
    )
  }
  if (price > 400000) {
    return (
      <>
        <div
          style={{
            backgroundColor: '#ff3e3e',
            color: 'white',
            borderRadius: 4,
            fontSize: 12,
            display: 'flex',
            fontWeight: 'bold',
            justifyContent: 'center',
            boxSizing: 'border-box',
            marginLeft: 20,
            width: 120,
            padding: 2,
            marginTop: 4,
          }}
        >
          쉽지 않은 상품이에요
        </div>
      </>
    )
  }
  return ''
}

function ZeroWonStorePage() {
  const alert = useAlert()
  // const [isLoading, setIsLoading] = useState(true);
  const [zeroWonStoreItemList, setZeroWonStoreItemList] = useState()
  const [
    zeroWonStoreCurrentSuccessReference,
    setZeroWonStoreCurrentSuccessReference,
  ] = useState()
  const [ongoingZeroWonStoreTeamInfo, setOngoingZeroWonStoreTeamInfo] =
    useState()
  const token = useSearchParam('token')
  const [randomString, setRandomString] = useState(
    Math.random().toString(36).substr(2, 8),
  )
  const [isNewUser, setIsNewUser] = useState(false)
  const [itemId, setItemId] = useState()
  const [
    showZeroWonStoreCloseNoticeModal,
    setShowZeroWonStoreCloseNoticeModal,
  ] = useState(false)
  const [showSelectConfirmModal, setShowSelectConfirmModal] = useState(false)
  const initializeZeroWonStore = async () => {
    const ongoingZeroWonStoreTeamInfo =
      await backendApis.getZeroWonStoreTeamInfo(token)
    if (
      ongoingZeroWonStoreTeamInfo?.status === 200 &&
      ongoingZeroWonStoreTeamInfo?.data
    ) {
      window.location.href = `/zeroWonStore/zeroWonStoreItemPage/?token=${token}&randomString=${Math.random()
        .toString(36)
        .substr(2, 8)}`
      return
    }
    setOngoingZeroWonStoreTeamInfo(ongoingZeroWonStoreTeamInfo?.data)
    const result = await backendApis.getZeroWonStoreItemList(token, 'GET')
    if (result?.status === 200) {
      setZeroWonStoreItemList(result?.data)
    } else {
      alert.show(
        `상품을 불러오는 과정에서 오류가 발생하였어요. 다시 시도해 주세요`,
      )
    }
    const currentSuccessResult =
      await backendApis.getZeroWonStoreCurrentSuccessReference(token, 'GET')
    if (currentSuccessResult?.status === 200) {
      setZeroWonStoreCurrentSuccessReference(currentSuccessResult?.data)
    } else {
      alert.show(
        `상품을 불러오는 과정에서 오류가 발생하였어요. 다시 시도해 주세요`,
      )
    }
  }

  const checkIsNewUser = async () => {
    const isNewUser = await backendApis.checkIsNewUser(token)

    if (isNewUser?.status === 200 && isNewUser?.data?.isNewUser) {
      setIsNewUser(isNewUser?.data?.isNewUser)
    }
  }

  useEffect(() => {
    initializeZeroWonStore()
    checkIsNewUser()
  }, [])

  const Swiper = () => (
    <>
      {zeroWonStoreCurrentSuccessReference && (
        <div
          style={{
            // paddingLeft: 18,
            paddingTop: 16,
            paddingBottom: 12,
            backgroundColor: 'black',
            marginTop: -8,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: 48,
              height: 48,
              marginRight: 2,
              // padding: 1,
              // boxSizing: 'border-box',
            }}
          >
            <img
              alt=''
              style={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                borderRadius: 4,
              }}
              src={zeroWonStoreCurrentSuccessReference?.itemInfo?.mainImageUri}
            />
          </div>
          <div
            style={{
              fontSize: 15,
              fontWeight: 700,
              color: 'white',
              width: '70%',
              paddingLeft: 12,
              // padding: 3,
              // boxSizing: 'border-box',
            }}
          >
            '{zeroWonStoreCurrentSuccessReference?.hostUserInfo?.userName}
            '님이 [{zeroWonStoreCurrentSuccessReference?.itemInfo?.itemTitle}]을
            0원에 받았어요
          </div>
        </div>
      )}
    </>
  )

  return (
    <Layout>
      <Header title='0원 상점' />
      <div style={{ overflowY: 'scroll', height: '93%' }}>
        <img
          alt=''
          style={{
            width: '100vw',
            height: '35.2vw',
            backgroundColor: 'gray',
            marginBottom: -4,
          }}
          src={`../images/ZeroWonStoreMainBanner.png?v=${randomString}`}
        />
        <div
          style={{
            backgroundColor: 'rgba(0,0,0,0.8)',
            padding: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button
            type='button'
            style={{
              textAlign: 'center',
              color: 'white',
              fontWeight: 700,
              backgroundColor: '#EE3E3E',
              marginTop: 0,
              padding: 8,
              paddingLeft: 16,
              paddingRight: 16,
              border: 'none',
              borderRadius: 4,
              fontSize: 16,
            }}
            onClick={() => setShowZeroWonStoreCloseNoticeModal(true)}
          >
            0원상점 종료 안내 (12월 16일 종료)
          </button>
        </div>
        {/* <Swiper /> */}
        {/* <div
          style={{
            paddingLeft: 18,
            paddingTop: 16,
            paddingBottom: 12,
            backgroundColor: '#F4F6F8',
            marginTop: -8,
          }}
        >
          <div
            style={{
              fontWeight: 'bold',
              color: '#ff3e3e',
              fontSize: 18,
              marginBottom: 8,
            }}
          >
            0원 상점 성공하는 TIP
          </div>
          <div
            style={{
              fontSize: 16,
              color: 'black',

              marginBottom: 4,
            }}
          >
            1. 보너스 할인 찬스를 찾아보세요
          </div>
          <div
            style={{
              fontSize: 16,
              color: 'black',

              marginBottom: 4,
            }}
          >
            2. 랜덤 쿠폰을 뿌리면 상품 할인을 받을 수 있어요
          </div>
          <div
            style={{
              fontSize: 15,
              color: '#ff3e3e',
              marginLeft: 20,
              marginBottom: 4,
            }}
          >
            새 친구에게는 무조건 2만원 쿠폰팩을 드려요
          </div>
        </div> */}
        {zeroWonStoreItemList &&
          zeroWonStoreItemList?.map((each, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: 150,
                width: '100vw',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  height: '80%',
                  width: '30vw',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 8,
                  boxSizing: 'border-box',
                  marginLeft: 15,
                }}
              >
                <img
                  src={each?.mainImageUri}
                  alt=''
                  style={{ borderRadius: 8, height: 135, width: 135 }}
                />
              </div>
              <div style={{ width: '70vw', flex: 1 }}>
                <div
                  style={{
                    justifyContent: 'flex-start',
                    display: 'flex',
                    boxSizing: 'border-box',
                    paddingTop: 20,
                    paddingBottom: 4,
                    paddingLeft: 20,
                    paddingRight: 20,
                    fontSize: 16,
                    // fontWeight: 'bold',
                  }}
                >
                  {each?.itemTitle}
                </div>
                <div
                  style={{
                    justifyContent: 'flex-start',
                    display: 'flex',
                    boxSizing: 'border-box',
                    paddingLeft: 20,
                    paddingRight: 20,
                    fontSize: 14,
                    color: '#ff3e3e',
                    fontWeight: '600',
                  }}
                >
                  {each?.successCount
                    ? successSubTitle(each?.successCount)
                    : subTitle(each?.joinCount)}
                </div>
                {adviceSubTitle(each?.price)}

                <div
                  style={{
                    height: 70,
                    justifyContent: 'flex-end',
                    display: 'flex',
                    boxSizing: 'border-box',
                    padding: 15,
                  }}
                >
                  <button
                    onClick={async () => {
                      if (ongoingZeroWonStoreTeamInfo?._id) {
                        alert.show(
                          `지난 0원 상점을 열고나서 24시간 이후에 새로운 0원 상점을 열 수 있어요.`,
                          {
                            closeCopy: '확인',
                          },
                        )
                        return
                      }
                      setItemId(each?._id)
                      setShowSelectConfirmModal(true)
                      // 주소지 등록으로 보내는 플로우
                      // const result = await backendApis.getEventPageMappingUrl(
                      //   token,
                      //   'GET',
                      //   { eventPageName: 'zeroWonStore' },
                      // )
                      // window.location.href = `#push.${JSON.stringify({
                      //   screen: 'WebEventPage',
                      //   prop: {
                      //     eventPageMapping: {
                      //       redirectUrl: `${result.data?.redirectUrl}/addressInfo/?token=${token}&itemId=${each?._id}&itemTitle=${each?.itemTitle}&mainImageUri=${each?.mainImageUri}&randomString=${randomString}`,
                      //     },
                      //   },
                      // })}`
                    }}
                    style={{
                      backgroundColor: '#EE3E3E',
                      color: 'white',
                      borderWidth: 0,
                      borderRadius: 20,
                      paddingLeft: 24,
                      paddingRight: 24,
                      height: '80%',
                      fontSize: 14,
                      fontWeight: 'bold',
                    }}
                  >
                    {isNewUser
                      ? `${commaNumber(each?.price / 20)}원 더 받고 시작하기`
                      : `선택하기`}
                  </button>
                </div>
              </div>
            </div>
          ))}
        <div
          style={{
            width: '100%',
            backgroundColor: '#F4F6F8',
            boxSizing: 'border-box',
            padding: 16,
          }}
        >
          <div style={{ fontSize: 18, fontWeight: 500, marginBottom: 8 }}>
            유의사항
          </div>
          <div style={{ lineHeight: '22.6px' }}>
            <div style={{ fontSize: 14, color: 'gray' }}>
              · 한번에 하나의 상품만 진행할 수 있어요.
            </div>
            <div style={{ fontSize: 14, color: 'gray' }}>
              · 각 미션은 1회만 참여 가능하며 2회 이상 참여를 한 경우 성공하여도
              취소될 수 있어요.
            </div>
            <div style={{ fontSize: 14, color: 'gray' }}>
              · 성공한 상품은 재도전이 안돼요.
            </div>
            <div style={{ fontSize: 14, color: 'gray' }}>
              · 20세 이상의 고객만 참여가 가능해요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 지난 0원 상점을 열고나서 24시간 이후에 새로운 0원 상점을 열 수
              있어요.
            </div>
            <div style={{ fontSize: 14, color: 'gray' }}>
              · 성공하였을 경우 24시간 동안만 상품 주문이 가능해요.
            </div>
            <div style={{ fontSize: 14, color: 'gray' }}>
              · 성공한 이후 24시간이 지나면 상품 주문을 할 수 없어요.
            </div>
            <div style={{ fontSize: 14, color: 'gray' }}>
              · 일반 상품 구매를 통해 추가할인을 받은 후, 일반 상품을 의도적으로
              취소하면 0원 상품 수령에 제한이 있을 수 있어요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 경품은 영업일 기준 20일 내외로 발송되며 지정일 배송과 출고 후
              주소 변경은 불가능해요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 사전에 고지된 상품의 옵션은 변경이 불가능해요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 제한 시간이 종료되면 이전 기록은 인정되지 않으며 처음부터 다시
              시작 해야해요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 상품은 입력하신 주소지로 배송돼요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 친구를 한명 초대할 때 마다 가격이 할인돼요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 친구 초대를 통한 금액은 이벤트참여시기, 이벤트달성정도 등 다양한
              요소를 고려하여 랜덤하게 지급돼요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 가입한지 2시간 이내이며 올웨이즈의 타 이벤트 참여 이력이 없는
              고객만 신규 고객으로 인정돼요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 이벤트가 시작된 이후 가입한 고객은 반드시 본인인증을 진행해야만
              신규 고객으로 인정돼요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 기존 친구를 초대해도 랜덤으로 큰 할인 금액이 주어져요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 탈퇴 후 재참여하시면 새친구 보상을 드리지 않으며, 회원탈퇴할
              경우 24시간 동안 친구에게 가격 할인을 제공할 수 없어요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 친구에게 하루 3번 할인을 도와줄 수 있고, 오전 9시를 기준으로
              다시 3번의 기회가 생겨요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 부정한 방법을 통한 참여는 인정되지 않으며, 성공하여도 취소될 수
              있어요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 재고 소진 시 유사 상품이나 해당 금액으로 대체하여 제공될 수
              있어요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 10만원 이상의 상품에 대해서는 제세공과금이 발생할 수 있어요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 본 이벤트는 당사의 사정에 의해 진행 중인 고객님이 있어도 사전
              고지 없이 변경 또는 조기종료될 수 있어요.
            </div>
            <div
              style={{
                fontSize: 14,
                color: 'gray',
              }}
            >
              · 문의는 내 정보 > 고객문의 > 1:1 실시간 상담하기에서 하실 수
              있어요.
            </div>
          </div>
        </div>
      </div>
      {showSelectConfirmModal && (
        <ZeroWonStoreSelectConfirmModal
          setShowSelectConfirmModal={setShowSelectConfirmModal}
          token={token}
          itemId={itemId}
          randomString={randomString}
          onClick={() => {
            // pageScroll()
          }}
        />
      )}
      {showZeroWonStoreCloseNoticeModal && (
        <ZeroWonStoreCloseNoticeModal
          setShowZeroWonStoreCloseNoticeModal={
            setShowZeroWonStoreCloseNoticeModal
          }
          token={token}
        />
      )}
    </Layout>
  )
}

export default ZeroWonStorePage
