const Haeder = ({ title }) => (
  <section
    style={{
      position: 'relative',
      zIndex: 1,
      width: '100vw',
      height: '48px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      overflow: 'hidden',
    }}
  >
    <div
      style={{
        float: 'left',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
        width: '48px',
        height: '42px',
        boxSizing: 'border-box',
        padding: '10px',
      }}
    >
      <button
        type='button'
        style={{
          backgroundColor: 'transparent',
          height: '100%',
          width: '100%',
          padding: 0,
          borderWidth: 0,
        }}
        onClick={() => {
          window.location.href = '#goBack'
        }}
      >
        <img
          src='/images/back.png'
          alt=''
          style={{
            height: '100%',
            width: '100%',
          }}
        />
      </button>
    </div>
    <div style={{ width: '100%', textAlign: 'center' }}>{title}</div>
    <div
      style={{
        float: 'left',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
        width: '48px',
        height: '48px',
        boxSizing: 'border-box',
        padding: '4px',
      }}
    />
  </section>
)

export default Haeder
