import React, { useState, useEffect } from 'react'
import { useSearchParam } from 'react-use'
import Layout from '../components/layout'
import Header from '../components/header'
import Footer from '../components/footer'
import { useAlert } from 'react-alert'

import backendApis from '../utils/backendApis'

function luckyBoxEventSelectItem() {
  const alert = useAlert()
  const token = useSearchParam('token')
  const [randomString, setRandomString] = useState(
    Math.random().toString(36).substr(2, 8),
  )
  const [backgroundColor, setBackgroundColor] = useState('#BEC0C2')
  const [luckyBoxItemTotalList, setLuckyBoxItemTotalList] = useState([])
  const [selectedItemList, setSelectedItemList] = useState([])
  //   const selectedItemList = []

  const initializeLuckyBoxItemTotalListInfo = async () => {
    const result = await backendApis.getLuckyBoxItemTotalListInfo(token, 'GET')
    if (result?.status === 200) {
      setLuckyBoxItemTotalList(result?.data)
    }
  }

  useEffect(() => {
    initializeLuckyBoxItemTotalListInfo()
  }, [])

  const ItemComponent = ({ itemId, itemTitle, mainImageUri, tier }) => (
    <>
      <div
        style={{
          width: '30%',
          height: '100%',
        }}
      >
        <button
          type='button'
          style={{ background: 'none', border: 'none' }}
          onClick={() => {
            const otherTierItemList = luckyBoxItemTotalList
              ?.filter((elem) => elem?.tier !== tier)
              ?.filter((each) => selectedItemList?.includes(each?._id))
              ?.map((item) => item?._id)
            setSelectedItemList([...otherTierItemList, itemId])
            if (selectedItemList?.length >= 4) {
              setBackgroundColor('#FFA13D')
            }
          }}
        >
          <div
            style={{
              width: 16,
              height: 16,
              border: '#ebebeb',
              // borderWidth: 1.5,
            }}
          >
            <div
              style={{
                width: 16,
                height: 16,
                border: 'none',
                backgroundColor: '#ebebeb',
                borderRadius: 8,
                marginLeft: 8,
                marginTop: 8,
                justifyContent: 'center',
                alignItems: 'center',
                boxSizing: 'border-box',
                padding: 4,
              }}
              // onClick={() => {
              //   const otherTierItemList = luckyBoxItemTotalList
              //     ?.filter((elem) => elem?.tier !== tier)
              //     ?.filter((each) => selectedItemList?.includes(each?._id))
              //     ?.map((item) => item?._id)
              //   setSelectedItemList([...otherTierItemList, itemId])
              // }}
            >
              {selectedItemList && selectedItemList?.includes(itemId) && (
                <div
                  style={{
                    width: 8,
                    height: 8,
                    borderRadius: 4,
                    backgroundColor: 'red',
                  }}
                />
              )}
            </div>
          </div>
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              height: '70%',
              marginTop: 8,
            }}
          >
            <img src={mainImageUri} alt='' style={{ height: 72, width: 72 }} />
          </div>
          <div
            style={{
              textAlign: 'center',
              fontFamily: 'GmarketSans',
              fontWeight: 300,
              fontSize: 12,
              color: 'black',
            }}
          >
            {itemTitle}
          </div>
        </button>
      </div>
    </>
  )

  const ItemGroupComponent = ({ luckyBoxItemList, tier }) => (
    <>
      <div
        style={{
          backgroundColor: 'white',
          width: '80vw',
          height: '22vh',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 8,
          margin: 8,
          padding: 2,
        }}
      >
        <div
          style={{
            textAlign: 'center',
            borderWidth: 1.5,
            fontFamily: 'GmarketSans',
            fontWeight: 700,
            marginTop: 8,
          }}
        >
          <span style={{ color: '#E63423' }}> {tier}순위</span> 선물
        </div>
        <div
          style={{
            borderBottom: '#ebebeb solid',
            borderBottomWidth: 1.5,
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 4,
          }}
        />
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {luckyBoxItemList?.map((each) => (
            <ItemComponent
              itemId={each?._id}
              itemTitle={each?.itemTitle}
              mainImageUri={each?.mainImageUri[0]}
              tier={tier}
            />
          ))}
        </div>
      </div>
    </>
  )

  return (
    <Layout>
      <Header title='행운상자 선물하기' />
      <div style={{ overflowY: 'scroll', height: '93%' }}>
        <div
          style={{
            display: 'flex',
            height: '150vh',
            width: '100vw',
            background:
              'linear-gradient(180deg, #f67815 0%, #f98611 3.65%, #E63323 94.79%, #e83b21 100%)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ height: 120, marginTop: 28, marginBottom: -28 }}>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 900,
                fontSize: 32,
                color: 'white',
                textAlign: 'center',
              }}
            >
              행운상자 <span style={{ fontWeight: 500 }}>골라담기</span>
            </div>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 500,
                fontSize: 14,
                color: 'white',
                textAlign: 'center',
                paddingTop: 4,
                paddingBottom: 2,
              }}
            >
              친구에게 보낼 행운 상자에 상품을 직접 담아보세요
            </div>
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 500,
                fontSize: 14,
                color: 'white',
                textAlign: 'center',
              }}
            >
              선택한 상품 중 1개를
              <span
                style={{
                  fontWeight: 900,
                  marginLeft: 2,
                  paddingLeft: 2,
                  paddingRight: 2,
                  backgroundColor: '#ED531C',
                }}
              >
                100% 확률
              </span>
              로 보내드려요
            </div>
          </div>
          <div>
            <ItemGroupComponent
              luckyBoxItemList={luckyBoxItemTotalList?.filter(
                (each) => each?.tier === 1,
              )}
              tier={1}
            />
            <ItemGroupComponent
              luckyBoxItemList={luckyBoxItemTotalList?.filter(
                (each) => each?.tier === 2,
              )}
              tier={2}
            />
            <ItemGroupComponent
              luckyBoxItemList={luckyBoxItemTotalList?.filter(
                (each) => each?.tier === 3,
              )}
              tier={3}
            />
            <ItemGroupComponent
              luckyBoxItemList={luckyBoxItemTotalList?.filter(
                (each) => each?.tier === 4,
              )}
              tier={4}
            />
            <ItemGroupComponent
              luckyBoxItemList={luckyBoxItemTotalList?.filter(
                (each) => each?.tier === 5,
              )}
              tier={5}
            />
          </div>
        </div>
      </div>
      <Footer
        title='상품 선택 완료'
        // clickable
        backgroundColor={`${backgroundColor}`}
        onClick={async () => {
          if (selectedItemList?.length > 4) {
            const result = await backendApis.createLuckyBoxTeamInfo(
              token,
              'POST',
              { itemIds: selectedItemList },
            )
            console.log(77, selectedItemList)
            if (result?.status === 200) {
              window.location.href = `/luckyBoxEventGetPhoneBook/?token=${token}&randomString=${Math.random()
                .toString(36)
                .substr(2, 8)}`
            }
          } else {
            alert.show('5개의 상품을 선택해야 행운상자를 선물할 수 있어요', {
              closeCopy: '확인',
            })
          }
        }}
      />
    </Layout>
  )
}
export default luckyBoxEventSelectItem
