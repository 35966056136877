import React, { useState } from 'react'
import commaNumber from 'comma-number'
import { useAlert } from 'react-alert'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'

const SupportFundInviteOnboardingModal = ({
  setShowSupportFundInviteOnboardingModal,
  setShowSupportFundCloseNoticeModal,
  supportFundUserInvitedInfos,
  token,
  invitorId,
  userInfo,
  debounce,
}) => {
  const alert = useAlert()
  const [isButtonDisable, setIsButtonDisable] = useState(false)
  const inviteOnboardingMainButton = async () => {
    // 본인 인증 안되어 있는 경우 <- 12월 18일 이후로 부터 본인 인증
    // if (
    //   !userInfo?.selfAuthenticationInfo &&
    //   !userInfo?.selfAuthenticationInfoLogs &&
    //   new Date(userInfo?.createdAt) > new Date('2022-12-18T15:00:00')
    // ) {
    //   window.location.href = '#openAuthenticationModal.'
    //   return
    // }
    const result = await backendApis.updateSupportFundTeamInfo(token, 'PUT', {
      invitorId,
    })
    if (result?.status === 200 && result?.data?.discountPrice) {
      alert.show('친구를 도와줬어요.')
      setShowSupportFundInviteOnboardingModal(false)
      setShowSupportFundCloseNoticeModal(true)
    } else if (result?.data?.msg === 'time expired') {
      alert.show('이미 만료된 연말지원금 링크예요.')
      setShowSupportFundInviteOnboardingModal(false)
    } else if (result?.data?.msg === 'already did 3 invite') {
      alert.show('참여 횟수를 다 소진했어요. 24시간 후에 다시 시도해 주세요.')
      setShowSupportFundInviteOnboardingModal(false)
    } else if (result?.data?.msg === 'invitee is under age') {
      alert.show('본 이벤트는 20세 이상 고객만 참여 가능해요.')
      setShowSupportFundInviteOnboardingModal(false)
    } else if (result?.data?.msg === 'left service in 24 hours') {
      alert.show('24시간 이내 탈퇴한 이력이 있는 고객은 참여 할 수 없어요.')
    } else if (result?.data?.msg === 'host and invitee are same') {
      alert.show('자신이 공유한 링크로 자신이 받을 수는 없어요.')
      setShowSupportFundInviteOnboardingModal(false)
    } else if (result?.data?.msg === 'already invited') {
      alert.show('이미 참여한 내역이 있어요.')
      setShowSupportFundInviteOnboardingModal(false)
    } else {
      alert.show('알 수 없는 오류가 발생했어요. 다시 시도해주세요.')
      setShowSupportFundInviteOnboardingModal(false)
      setShowSupportFundCloseNoticeModal(true)
    }
  }

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          zIndex: 3,
          backgroundColor: 'rgba(0,0,0,0.8)',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {' '}
        <button
          type='button'
          style={{
            width: 100,
            height: 100,
            backgroundColor: 'red',
            background: 'none',
            border: 'none',
            top: '14%',
            right: '1%',
            position: 'absolute',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => {
            setShowSupportFundInviteOnboardingModal(false)
          }}
        >
          <img
            style={{
              zIndex: 101,
              width: '36%',
              height: '36%',
              position: 'absolute',
            }}
            src='/images/closeCircleIcon.png'
            alt=''
          />
        </button>
        <div
          style={{
            backgroundColor: 'white',
            width: '76vw',
            height: '28vh',
            borderRadius: 12,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              // backgroundColor: 'red',
            }}
          >
            <img
              alt=''
              style={{ width: 140, marginTop: -52 }}
              src='/images/SupportFundWithdrawSmallImage.png'
            />
            <div
              style={{
                textAlign: 'center',
                fontFamily: 'GmarketSans',
                fontWeight: 700,
                lineHeight: 1.3,
              }}
            >
              <div>
                친구가 <span style={{ color: '#EB4F47' }}>5만원</span>을
              </div>
              <div>채울 수 있게 도와주세요!</div>
            </div>

            {supportFundUserInvitedInfos > 2 ? (
              <>
                <div
                  style={{
                    backgroundColor: '#BEC0C2',
                    padding: 8,
                    paddingTop: 12,
                    paddingBottom: 12,
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: 8,
                    margin: 8,
                    marginTop: 16,
                    marginBottom: 12,
                    width: '72%',
                  }}
                >
                  <button
                    type='button'
                    style={{
                      background: 'none',
                      border: 'none',
                      color: 'white',
                      fontFamily: 'GmarketSans',
                      fontWeight: 900,
                      fontSize: 14,
                    }}
                    onClick={() => {}}
                  >
                    참여 기회를 모두 사용했어요
                  </button>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    backgroundColor: '#EB4F47',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: 8,
                    margin: 8,
                    marginTop: 16,
                    marginBottom: 12,
                    width: '72%',
                  }}
                >
                  <button
                    type='button'
                    style={{
                      backgroundColor: '#EB4F47',
                      width: '100%',
                      height: '100%',
                      borderRadius: 8,
                      padding: 8,
                      paddingTop: 12,
                      paddingBottom: 12,
                      background: 'none',
                      border: 'none',
                      color: 'white',
                      fontFamily: 'GmarketSans',
                      fontWeight: 900,
                      fontSize: 16,
                    }}
                    onClick={() => {
                      if (isButtonDisable) {
                        return
                      }
                      setIsButtonDisable(true)
                      debounce(inviteOnboardingMainButton, 500)
                    }}
                  >
                    친구 도와주기
                  </button>
                </div>
              </>
            )}

            {supportFundUserInvitedInfos > 2 ? (
              <>
                <div
                  style={{
                    fontSize: 12,
                    color: '#79765F',
                    fontFamily: 'GmarketSans',
                  }}
                >
                  오늘 참여 기회를 모두 사용했어요
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    fontSize: 12,
                    color: '#79765F',
                    fontFamily: 'GmarketSans',
                  }}
                >
                  오늘{' '}
                  <span style={{ color: '#EB4F47' }}>
                    {3 - supportFundUserInvitedInfos}번
                  </span>{' '}
                  더 도와줄 수 있어요
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SupportFundInviteOnboardingModal
