import React from 'react'
import { render } from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import AlertMUITemplate from 'react-alert-template-mui'

const container = document.getElementById('root')

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.MIDDLE,
  // // you can also just use 'scale'
  // transition: transitions.SCALE,
}

const Root = () => (
  <AlertProvider template={AlertMUITemplate} {...options}>
    <App />
  </AlertProvider>
)

render(<Root />, container)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
