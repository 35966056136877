import React, { useState, useEffect } from 'react'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import Layout from '../components/layout'
import Header from '../components/header'
import BlackTextTimer from '../components/BlackTextTimer'
import commaNumber from 'comma-number'
import { useAlert } from 'react-alert'
import useDebounce from '../components/useDebounce'

function NewYearsMoneyInputPage() {
  const alert = useAlert()
  const debounce = useDebounce()
  const token = useSearchParam('token')
  //   const supportFundTeamId = useSearchParam('supportFundTeamId')
  const newYearsMoneyTeamId = useSearchParam('newYearsMoneyTeamId')
  const [bankAccountInfo, setBankAccountInfo] = useState({})
  const [receivingAccountNumber, setReceivingAccountNumber] = useState('')
  const [receiverName, setReceiverName] = useState('')
  const [bankList, setBankList] = useState([])
  const [isButtonDisable, setIsButtonDisable] = useState(false)

  useEffect(() => {
    const initializeBankList = async () => {
      const result = await backendApis.getBankList(token, 'GET')
      if (result?.status === 200) {
        setBankList(result?.data)
      }
    }
    initializeBankList()
  }, [])

  const inputButton = async () => {
    if (bankAccountInfo.bankCode === undefined) {
      alert.show(`은행을 선택해주세요`, {
        closeCopy: '확인',
      })
      return
    }
    if (receivingAccountNumber === '') {
      alert.show(`계좌번호를 입력해주세요`, {
        closeCopy: '확인',
      })
      return
    }
    if (receiverName === '') {
      alert.show(`예금주 명을 입력해주세요`, {
        closeCopy: '확인',
      })
    }
    const result = await backendApis.requestNewYearsMoneyWithdraw(
      token,
      'POST',
      {
        bankCode: bankAccountInfo?.bankCode,
        bankName: bankAccountInfo?.bankName,
        bankAccountNumber: receivingAccountNumber,
        newYearsMoneyTeamId,
      },
    )
    if (result?.status === 2000) {
      alert.show(
        '계좌정보 입력이 완료됐어요. 영업일 기준 24시간 내에 입금될 예정이니 조금만 기다려주세요!',
      )
      setTimeout(() => {
        window.location.href = `/new-years-money/?token=${token}&randomString=${Math.random()
          .toString(36)
          .substr(2, 8)}`
      }, 3500)
    } else if (result?.msg === 'work in progress') {
      alert.show(
        '계좌정보 입력이 완료됐어요. 영업일 기준 24시간 내에 입금될 예정이니 조금만 기다려주세요!',
      )
    } else if (result?.msg === 'invalid bank account') {
      alert.show(
        '돈을 보내려는 계좌번호를 다시 확인해주세요. 문제가 계속되면 고객센터로 문의해주세요.',
      )
    } else if (
      result?.msg === 'error code' ||
      result?.msg === 'invalid transfer amount'
    ) {
      alert.show(
        '알 수 없는 오류가 발생했어요. 문제가 계속되면 고객센터로 문의해주세요.',
      )
    } else if (result?.msg === 'pending but no tid') {
      alert.show('알 수 없는 오류가 발생했어요. 잠시 후에 다시 시도해주세요.')
    } else if (result?.msg === 'failed to transfer') {
      alert.show('알 수 없는 오류가 발생했어요. 잠시 후에 다시 시도해주세요')
    } else {
      alert.show(
        `알 수 없는 오류가 발생했어요. 내 정보 > 1:1 실시간 상담하기를 통해 고객센터로 문의해주세요.`,
        {
          closeCopy: '확인',
        },
      )
    }
  }

  return (
    <>
      <Layout>
        <Header title='세뱃돈 받을 계좌정보 입력하기' />
        <div
          style={{
            height: '93%',
            fontFamily: 'GmarketSans',
          }}
        >
          <div
            style={{
              width: '100vw',
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                width: '84vw',
                marginTop: 48,
                fontWeight: 700,
              }}
            >
              <div>
                은행을 선택해주세요
                <span style={{ color: '#EB4F47', marginLeft: 4 }}>*</span>
              </div>
              <div>
                <select
                  style={{
                    width: '100%',
                    border: '2px solid #F7F7F7',
                    height: 40,
                    borderRadius: 5,
                    fontSize: 16,
                    fontFamily: 'GmarketSans',
                    marginTop: 12,
                    marginBottom: 12,
                    backgroundColor: 'white',
                  }}
                  placeholder='  입금 은행'
                  value={bankAccountInfo.bankCode || ''}
                  onChange={(e) => {
                    const correspondingBankInfo = bankList.find(
                      (bankInfo) => bankInfo.bankCode === e.target.value,
                    )
                    if (correspondingBankInfo) {
                      setBankAccountInfo({
                        bankCode: correspondingBankInfo.bankCode,
                        bankName: correspondingBankInfo.bankName,
                      })
                    } else {
                      setBankAccountInfo({})
                    }
                  }}
                >
                  <option value=''>입금 은행</option>
                  {bankList.map((bankInfo) => (
                    <option key={bankInfo.bankCode} value={bankInfo.bankCode}>
                      {bankInfo.bankName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div
              style={{
                width: '84vw',
                marginTop: 20,
                fontWeight: 700,
              }}
            >
              <div>
                계좌번호
                <span style={{ color: '#EB4F47', marginLeft: 4 }}>*</span>
              </div>
              <div>
                <input
                  placeholder='  계좌번호를 - 없이 입력해주세요'
                  onChange={(e) => {
                    setReceivingAccountNumber(e.target.value)
                  }}
                  value={receivingAccountNumber || ''}
                  style={{
                    width: '98%',
                    border: '2px solid #F7F7F7',
                    height: 40,
                    borderRadius: 5,
                    fontSize: 16,
                    fontFamily: 'GmarketSans',
                    marginTop: 12,
                    marginBottom: 12,
                    backgroundColor: 'none',
                  }}
                />
              </div>
            </div>
            <div style={{ width: '84vw', marginTop: 20, fontWeight: 700 }}>
              <div>
                예금주 명
                <span style={{ color: '#EB4F47', marginLeft: 4 }}>*</span>
              </div>
              <div>
                <input
                  placeholder='  예금주 명을 정확히 입력해주세요'
                  onChange={(e) => {
                    setReceiverName(e.target.value)
                  }}
                  value={receiverName || ''}
                  style={{
                    width: '98%',
                    border: '2px solid #F7F7F7',
                    height: 40,
                    borderRadius: 5,
                    fontSize: 16,
                    fontFamily: 'GmarketSans',
                    marginTop: 12,
                    marginBottom: 12,
                    backgroundColor: 'none',
                  }}
                />
              </div>
            </div>

            <div
              style={{
                backgroundColor: '#2B2E37',
                padding: 8,
                paddingTop: 12,
                paddingBottom: 12,
                display: 'flex',
                justifyContent: 'center',
                borderRadius: 8,
                margin: 8,
                marginTop: 32,
                marginBottom: 8,
                width: '84vw',
              }}
            >
              <button
                type='button'
                style={{
                  background: 'none',
                  border: 'none',
                  color: 'white',
                  fontFamily: 'GmarketSans',
                  fontWeight: 900,
                  fontSize: 16,
                }}
                onClick={() => {
                  if (isButtonDisable) {
                    return
                  }
                  setIsButtonDisable(true)
                  debounce(inputButton, 500)
                }}
              >
                지금 세뱃돈 출금하기
              </button>
            </div>
            <div
              style={{
                width: '84vw',
                fontSize: 16,
                marginTop: 12,
                color: '#bbbbbb',
              }}
            >
              *출금을 하면 그동안 쌓인 세뱃돈이 모두 사라지고 초대한 친구 내역이
              초기화돼요
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default NewYearsMoneyInputPage
