import React, { useState } from 'react'
import commaNumber from 'comma-number'
import { useAlert } from 'react-alert'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'

const OnePlusOneExplainModal = ({ setShowOnePlusOneExplainModal }) => (
  <>
    <div
      style={{
        flex: 1,
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 3,
        backgroundColor: 'rgba(0,0,0,0.8)',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <button
        type='button'
        style={{
          width: 100,
          height: 100,
          backgroundColor: 'red',
          background: 'none',
          border: 'none',
          top: '-3.5%',
          right: '-5%',
          position: 'absolute',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          setShowOnePlusOneExplainModal(false)
        }}
      >
        <img
          style={{
            zIndex: 101,
            width: '36%',
            height: '36%',
            position: 'absolute',
          }}
          src='/images/closeCircleIcon.png'
          alt=''
        />
      </button>
      <div
        style={{
          backgroundColor: 'white',
          overflowY: 'scroll',
          width: '76vw',
          height: '84vh',
          borderRadius: 12,
          paddingBottom: 24,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            //   backgroundColor: 'red',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              fontFamily: 'GmarketSans',
              lineHeight: 1.3,
            }}
          >
            <div style={{ padding: 20, fontSize: 20, fontWeight: 700 }}>
              1+1 이벤트 이용 안내
            </div>
            <div
              style={{
                textAlign: 'left',
                //   backgroundColor: 'blue',
                width: '68vw',
              }}
            >
              <div>
                <div style={{ fontWeight: 500, paddingBottom: 6 }}>
                  1) 상품 보내기
                </div>
                <div
                  style={{
                    fontSize: 14,
                  }}
                >
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 원하는 상품 1개를 선택하여 친구에게 보낼 수 있어요
                  </div>
                  <div
                    style={{
                      paddingLeft: 8,
                      marginBottom: 2,
                    }}
                  >
                    - 품절된 상품은 보내기가 불가능해요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 올웨이즈에 처음 가입하는 친구에게만 상품을 보낼 수 있어요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 20세 이상의 고객에게만 상품을 보낼 수 있어요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 상품 보내기는 제한 없이 가능해요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 내가 보낸 링크로 가입한 친구가 연속 출석체크 미션을
                    성공하면 친구와 나 모두 내가 선택한 상품을 받아요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 연속 출석체크를 해야 하는 일수는 상품마다 달라요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 내가 보낸 링크로 친구가 이벤트에 참여한 이후에는 상품
                    변경이 불가능해요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 친구마다 다른 상품을 보낼 수 있어요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 기프티콘 상품은 영업일 기준 5일 이내 주문 내역의
                    휴대폰번호(모바일)로 발송해드려요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 부정한 방법으로 이벤트를 이용하시는 경우 경품이 지급되지
                    않을 수 있어요
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{ fontWeight: 500, paddingBottom: 6, marginTop: 16 }}
                >
                  2) 상품 받기
                </div>
                <div
                  style={{
                    fontSize: 14,
                  }}
                >
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 올웨이즈에 처음 가입한 친구만 상품을 받을 수 있어요
                  </div>
                  <div
                    style={{
                      paddingLeft: 8,
                      marginBottom: 2,
                    }}
                  >
                    - 20세 이상의 고객만 상품을 받을 수 있어요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 정해진 상품의 재고가 소진되면 친구가 보낸 상품이 아닌 다른
                    상품으로 미션이 시작될 수 있어요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 연속 출석체크 미션을 성공해야 상품을 받을 수 있어요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 친구가 보낸 링크로 참여하면 즉시 연속 출석체크 미션이
                    시작돼요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 1+1 이벤트 페이지를 통해 출석체크를 했을 때만 인정돼요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 매일 오전 9시를 기준으로 다시 출석체크 기회가 생겨요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 정해진 출석체크 미션 일수를 채우기 전에 미션을 달성한 경우
                    무효화 될 수 있어요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 연속 출석체크 미션에 참여 가능한 기회는 1번으로 제한돼요
                  </div>
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 연속 출석체크 미션에 실패한 이후에는 재도전이 불가해요
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{ fontWeight: 500, paddingBottom: 6, marginTop: 16 }}
                >
                  3) 위반사항
                </div>
                <div
                  style={{
                    fontSize: 14,
                  }}
                >
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 기술적 허점이나 허점을 이용하여 부정한 이익을 받는 경우
                  </div>
                  <div
                    style={{
                      paddingLeft: 8,
                      marginBottom: 2,
                    }}
                  >
                    - 인증되지 않은 계정, 부정한 계정 등 가입자 확인이 어려운
                    계정으로 이벤트에 참여하는 경우
                  </div>
                  <div
                    style={{
                      paddingLeft: 8,
                      marginBottom: 2,
                    }}
                  >
                    - 탈퇴 후 재참여, 중복 클릭, 사기 행위, 계정 구매 등 부정한
                    방법으로 이벤트에 참여하는 경우
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{ fontWeight: 500, paddingBottom: 6, marginTop: 16 }}
                >
                  4) 유의사항
                </div>
                <div
                  style={{
                    fontSize: 14,
                  }}
                >
                  <div style={{ paddingLeft: 8, marginBottom: 2 }}>
                    - 가입한지 2시간 이내이며 올웨이즈의 타 이벤트 참여 이력이
                    없는 고객만 신규 고객으로 인정돼요{' '}
                  </div>
                  <div
                    style={{
                      paddingLeft: 8,
                      marginBottom: 2,
                    }}
                  >
                    - 위반사항에 해당하는 경우 성공하더라도 취소될 수 있어요
                  </div>
                  <div
                    style={{
                      paddingLeft: 8,
                      marginBottom: 2,
                    }}
                  >
                    - 본 이벤트는 당사의 사정에 의해 진행 중인 고객님이 있어도
                    사전 고지 없이 변경 또는 조기종료 될 수 있어요{' '}
                  </div>
                  <div
                    style={{
                      paddingLeft: 8,
                      marginBottom: 2,
                    }}
                  >
                    - 문의는 내 정보 > 고객문의 > 1:1 실시간 상담하기에서 하실
                    수 있어요
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default OnePlusOneExplainModal
