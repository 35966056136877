import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
  autorun,
} from 'mobx'

class AuthStore {
  token = ''

  constructor() {
    makeObservable(this, {
      token: observable.ref,

      setToken: action,
    })
  }

  setToken(token) {
    this.token = token
  }
}

export default new AuthStore()
