import React, { useEffect, useState } from 'react'

const LuckyBoxOrderModal = ({
  setShowLuckyBoxOrderModal,
  token,
  randomString,
  invitorId = userId,
  userId,
  winningItemInfo,
}) => (
  <>
    <div
      style={{
        flex: 1,
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 3,
        backgroundColor: 'rgba(0,0,0,0.8)',
        width: '100%',
        height: '100%',
      }}
    >
      <button
        type='button'
        style={{
          width: 100,
          height: 100,
          backgroundColor: 'red',
          background: 'none',
          border: 'none',
          top: '6%',
          right: '1%',
          position: 'absolute',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          setShowLuckyBoxOrderModal(false)
        }}
      >
        <img
          style={{
            zIndex: 101,
            width: '36%',
            height: '36%',
            position: 'absolute',
          }}
          src='/images/closeIcon.png'
          alt=''
        />
      </button>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          height: '100%',
          padding: 24,
          boxSizing: 'border-box',
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            zIndex: 4,
            // top: '24%',
            textAlign: 'center',
            paddingTop: 24,
            // paddingBottom: 18,
            borderRadius: 20,
            width: '90vw',
            height: '44vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ zIndex: 5 }}>
            <img
              src='/images/LuckyBox.png'
              alt=''
              style={{
                height: 100,
                width: 100,
                marginTop: -64,
                marginBottom: 8,
              }}
            />
          </div>
          <div
            style={{
              fontFamily: 'GmarketSans',
              fontWeight: 900,
              color: 'black',
              lineHeight: 1.4,
              fontSize: 24,
            }}
          >
            축하해요!
          </div>
          <div
            style={{
              fontFamily: 'GmarketSans',
              fontWeight: 900,
              color: 'black',
              lineHeight: 1.4,
              fontSize: 24,
            }}
          >
            <span style={{ color: '#EB4F47' }}>행운상품</span>을 받았어요!
          </div>
          <div
            style={{
              zIndex: 5,
              marginTop: 76,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/73147d48-575a-4814-8e10-7ad928bff8b7.png'
              alt=''
              style={{
                height: 156,
                width: 156,
                marginTop: -64,
                marginBottom: 8,
              }}
            />
            <img
              src={`${winningItemInfo?.mainImageUri[0]}`}
              alt=''
              style={{
                height: 100,
                width: 100,
                marginTop: -142,
                marginBottom: 8,
              }}
            />
            <div
              style={{
                fontFamily: 'GmarketSans',
                fontWeight: 700,
                color: 'white',
              }}
            >
              {winningItemInfo?.itemTitle}
            </div>
          </div>

          <button
            type='button'
            style={{
              marginTop: 32,
              bottom: '0',
              right: '0',
              fontSize: 18,
              borderBottomLeftRadius: 20,
              borderBottomRightRadius: 20,
              height: 48,
              borderWidth: 0,
              background: '#FF8808',
              width: '100%',
              paddingTop: 20,
              paddingBottom: 40,
            }}
            onClick={() => {
              window.location.href = `#toOrderMain.${JSON.stringify({
                props: {
                  itemId: winningItemInfo?.itemId,
                  enteringComponent: 'luckyBoxEvent',
                  type: 'luckyBox',
                  invitorId,
                  eventPageMapping: 'luckyBoxEvent',
                },
              })}`
            }}
          >
            <div
              style={{
                color: 'white',
                fontSize: 20,
                fontWeight: 'bold',
                paddingLeft: 8,
                paddingRigtt: 8,
              }}
            >
              행운상품 받으러 가기
            </div>
          </button>
        </div>
      </div>
    </div>
  </>
)

export default LuckyBoxOrderModal
