import React, { useState } from 'react'
import backendApis from '../utils/backendApis'

const OnePlusOneSelectModal = ({
  setShowOnePlusOneSelectModal,
  token,
  itemInfo,
}) => (
  <>
    <div
      style={{
        flex: 1,
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 3,
        backgroundColor: 'rgba(0,0,0,0.6)',
        width: '100%',
        height: '100%',
        fontFamily: 'GmarketSans',
      }}
    >
      <button
        type='button'
        style={{
          width: 100,
          height: 100,
          backgroundColor: 'red',
          background: 'none',
          border: 'none',
          top: '-2%',
          right: '-1%',
          position: 'absolute',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          setShowOnePlusOneSelectModal(false)
        }}
      >
        <img
          style={{
            zIndex: 101,
            width: '36%',
            height: '36%',
            position: 'absolute',
          }}
          src='/images/closeIcon.png'
          alt=''
        />
      </button>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          marginTop: 44,
          height: '100%',
          padding: 24,
          boxSizing: 'border-box',
        }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            zIndex: 4,
            textAlign: 'center',
            paddingTop: 20,
            borderRadius: 20,
            width: '90vw',
            height: '48vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              fontWeight: 900,
              fontSize: 18,
              color: '#593306',
              lineHeight: 1.4,
              marginTop: 8,
              marginBottom: 12,
              paddingBottom: 12,
              borderBottom: '1px solid rgba(64, 55, 58, 0.1)',
            }}
          >
            {itemInfo?.mainTitle} {itemInfo?.subTitle}{' '}
            <span style={{ color: '#FF9106' }}>1+1</span>
          </div>
          <div style={{ marginTop: -16 }}>
            <img
              src={`${itemInfo?.mainImageUri}`}
              alt=''
              style={{ height: 156 }}
            />
          </div>
          <div
            style={{
              fontSize: 16,
              width: '80vw',
              height: 108,
              color: '#63565B',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              paddingLeft: 12,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  backgroundColor: '#F7F3E8',
                  color: '#63565b',
                  width: 24,
                  height: 24,
                  borderRadius: 12,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: 8,
                }}
              >
                1
              </div>{' '}
              <div style={{ textAlign: 'left' }}>
                무료 상품을 친구에게 공유하세요
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  backgroundColor: '#F7F3E8',
                  color: '#63565b',
                  width: 24,
                  height: 24,
                  borderRadius: 12,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: 8,
                }}
              >
                2
              </div>{' '}
              <div style={{ textAlign: 'left' }}>
                내가 보낸 링크로 친구가 가입하고, <br />
                친구가{' '}
                {itemInfo?.missionDaysNum > 1 &&
                  `${itemInfo?.missionDaysNum}일 연속`}{' '}
                출석체크를 해요
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  backgroundColor: '#F7F3E8',
                  color: '#63565b',
                  width: 24,
                  height: 24,
                  borderRadius: 12,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: 8,
                }}
              >
                3
              </div>{' '}
              <div style={{ textAlign: 'left' }}>
                친구와 나 모두에게 선물을 드려요
              </div>
            </div>
          </div>
          <div
            style={{
              textAlign: 'center',
              fontSize: 12,
              color: '#9d9699',
              margin: 8,
              marginTop: 8,
            }}
          >
            *본 이벤트는 20세 이상의 고객만 참여 가능해요
          </div>
        </div>
      </div>
    </div>
  </>
)

export default OnePlusOneSelectModal
