import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import AuthStore from '../stores/AuthStore'
import UserStore from '../stores/UserStore'
import backendApis from '../utils/backendApis'

const TestPage = observer(() => {

  return (
    <div>
      <div>Codepush Version: {UserStore.userInfo?.codepushVersion}</div>
      <div>App Version: {UserStore.userInfo?.appVersion}</div>
      <div>Token: {AuthStore.token}</div>
    </div>
  )
})

export default TestPage
