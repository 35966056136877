import React, { useState } from 'react'
import commaNumber from 'comma-number'
import { useAlert } from 'react-alert'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'

const SupportFundWithdrawModal = ({
  setShowSupportFundWithdrawModal,
  remainedPrice,
  token,
  supportFundTeamId,
}) => (
  <>
    <div
      style={{
        flex: 1,
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 3,
        backgroundColor: 'rgba(0,0,0,0.8)',
        width: '100%',
        height: '100%',
      }}
    >
      {' '}
      <button
        type='button'
        style={{
          width: 100,
          height: 100,
          backgroundColor: 'red',
          background: 'none',
          border: 'none',
          top: '14%',
          right: '1%',
          position: 'absolute',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          setShowSupportFundWithdrawModal(false)
        }}
      >
        <img
          style={{
            zIndex: 101,
            width: '36%',
            height: '36%',
            position: 'absolute',
          }}
          src='/images/closeCircleIcon.png'
          alt=''
        />
      </button>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100vh',
          alignItems: 'center',
          //   backgroundColor: 'red',
        }}
      >
        <div
          style={{
            fontSize: 24,
            fontFamily: 'GmarketSans',
            color: 'white',
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          <div>
            <span style={{ color: '#EB4F47' }}>5만원</span>을 모으면
          </div>
          <div>현금으로 출금 가능해요!</div>
        </div>
        <div
          style={{
            // backgroundColor: 'red',
            width: 264,
            height: 188,
            backgroundSize: 'cover',
            backgroundImage: "url('/images/SupportFundWithdrawImage.png')",
            marginBottom: 12,
            marginTop: 16,
            position: 'relative',
          }}
        />
        <div
          style={{
            color: '#422E02',
            fontFamily: 'GmarketSans',
            fontWeight: 700,
            position: 'absolute',
            top: '42%',
            // left: '38%'
            justifyContent: 'center',
            fontSize: 28,
            zIndex: 102,
          }}
        >
          50,000
        </div>
        <div
          style={{
            backgroundColor: '#CFCFC1',
            top: '53%',
            height: 6,
            borderRadius: 3,
            width: '54%',
            position: 'absolute',
            padding: 0,
            margin: 0,
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <div
            style={{
              backgroundColor: '#EB4F47',
              height: 6,
              borderRadius: 3,
              width: `${((50000 - remainedPrice) / 50000) * 100}%`,
              position: 'absolute',
              padding: 0,
              margin: 0,
              border: 'none',
              boxShadow: 'none',
            }}
          />
        </div>
        <div
          style={{
            color: '#434929',
            fontFamily: 'GmarketSans',
            fontWeight: 900,
            position: 'absolute',
            top: '55%',
            // left: '36%',
            justifyContent: 'center',
            fontSize: 12,
          }}
        >
          <span style={{ color: '#EB4F47' }}>
            {commaNumber(remainedPrice)}원
          </span>{' '}
          남았어요
        </div>
        <div
          style={{
            backgroundColor: '#EB4F47',
            padding: 8,
            paddingTop: 12,
            paddingBottom: 12,
            display: 'flex',
            justifyContent: 'center',
            borderRadius: 8,
            margin: 8,
            marginBottom: 64,
            width: '64%',
          }}
        >
          <button
            type='button'
            style={{
              background: 'none',
              border: 'none',
              color: 'white',
              fontFamily: 'GmarketSans',
              fontWeight: 900,
              fontSize: 16,
            }}
            onClick={() => {
              if (remainedPrice <= 0) {
                window.location.href = `/support-fund-input/?token=${token}&supportFundTeamId=${supportFundTeamId}&randomString=${Math.random()
                  .toString(36)
                  .substr(2, 8)}`
              } else {
                window.location.href = `#openBottomShareSheet.${JSON.stringify({
                  code: 'supportFundInvite',
                })}`
              }
            }}
          >
            {remainedPrice > 0 ? '친구 초대해서 채우기' : '5만원 받으러 가기'}
          </button>
        </div>
      </div>
    </div>
  </>
)

export default SupportFundWithdrawModal
